import { connect } from "react-redux";
import {
  NotificationSettingsDTO,
  SettingsInitialStateDTO,
} from "../service/types";
import { RootState, TypedDispatch } from "../../../../redux/store";
import Notifications from "../components/Notifications/Notifications";
import {
  getNotificationSettings,
  updateNotificationSettings,
} from "../service/settingsSlice";

interface DispatchProps {
  GetNotificationSettings(id: string): void;
  UpdateNotificationSettings(data: NotificationSettingsDTO): void;
}

const mapStateToProps = (state: RootState): SettingsInitialStateDTO => {
  return {
    notifySettings: state.appSettings.notifySettings,
    invoiceSettings: state.appSettings.invoiceSettings,
    rolesList: state.appSettings.rolesList,
    roleDomainStructure: state.appSettings.roleDomainStructure,
    roleById: state.appSettings.roleById,
    studentIdData: state.appSettings.studentIdData,
    success: state.appSettings.success,
    error: state.appSettings.error,
    loading: state.appSettings.loading,
    notifySettingLoading: state.appSettings.notifySettingLoading,
    notifySettingUpdateLoading: state.appSettings.notifySettingUpdateLoading,
    notifySettingUpdateSuccess: state.appSettings.notifySettingUpdateSuccess,
    notifySettingUpdateError: state.appSettings.notifySettingUpdateError,
    invoiceSettingLoading: state.appSettings.invoiceSettingLoading,
    invoiceSettingCreateLoading: state.appSettings.invoiceSettingCreateLoading,
    invoiceSettingCreateSuccess: state.appSettings.invoiceSettingCreateSuccess,
    invoiceSettingCreateError: state.appSettings.invoiceSettingCreateError,
    invoiceSettingUpdateLoading: state.appSettings.invoiceSettingUpdateLoading,
    invoiceSettingUpdateSuccess: state.appSettings.invoiceSettingUpdateSuccess,
    invoiceSettingUpdateError: state.appSettings.invoiceSettingUpdateError,
    createCustomRoleError: state.appSettings.createCustomRoleError,
    createCustomRoleSuccess: state.appSettings.createCustomRoleSuccess,
    createCustomRoleLoading: state.appSettings.createCustomRoleLoading,
    rolesListLoading: state.appSettings.rolesListLoading,
    roleDomainStructureLoading: state.appSettings.roleDomainStructureLoading,
    updateCustomRoleError: state.appSettings.updateCustomRoleError,
    updateCustomRoleSuccess: state.appSettings.updateCustomRoleSuccess,
    updateCustomRoleLoading: state.appSettings.updateCustomRoleLoading,
    roleByIdLoading: state.appSettings.roleByIdLoading,
    deleteCustomRoleError: state.appSettings.deleteCustomRoleError,
    deleteCustomRoleSuccess: state.appSettings.deleteCustomRoleSuccess,
    deleteCustomRoleLoading: state.appSettings.deleteCustomRoleLoading,
    createStudentIdError: state.appSettings.createStudentIdError,
    createStudentIdSuccess: state.appSettings.createStudentIdSuccess,
    createStudentIdLoading: state.appSettings.createStudentIdLoading,
    updateStudentIdError: state.appSettings.updateStudentIdError,
    updateStudentIdSuccess: state.appSettings.updateStudentIdSuccess,
    updateStudentIdLoading: state.appSettings.updateStudentIdLoading,
    StudentIdDataLoading: state.appSettings.StudentIdDataLoading,
  };
};

const mapDispatchToProps = (dispatch: TypedDispatch): DispatchProps => {
  return {
    GetNotificationSettings: (id: string) => {
      dispatch(getNotificationSettings(id));
    },
    UpdateNotificationSettings: (data: NotificationSettingsDTO) => {
      dispatch(updateNotificationSettings(data));
    },
  };
};

export interface NotificationSettingsProps
  extends SettingsInitialStateDTO,
    DispatchProps {}
export const NotificationSettingsComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(Notifications);
