import React, { useState } from "react";
import {
	Box,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Dialog,
	Button,
	Tooltip,
} from '@mui/material';
import styles from './ViewInvoice.module.css';
import { useSelector } from 'react-redux';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import moment from 'moment';
import TruncatedText from '../TruncatedText/TruncatedText';

function ViewInvoice(props: any) {
	const {
		invoiceSettings,
		UpdateStudentInvoiceSettings,
		blurrEffect,
		showInvoice,
		setShowInvoice,
		paymentDetails,
		enteredAmount,
		leadById,
		finalPrice,
		totalPaidAmount,
		selectedCurrency,
	} = props;
	const instituteFromRedux = useSelector(
		(state: any) => state.institute.instituteDetails
	);
	console.log('leadById', leadById);
	const branchFromRedux = useSelector((state: any) => state.branch.branchList);
	console.log('branchFromRedux', branchFromRedux);

	const leadBranch = branchFromRedux?.find(
		(branch: any) => branch?.id === leadById?.branchId
	);
	console.log('leadBranch', leadBranch);

	const handleCloseInvoice = () => {
		setShowInvoice(false);
	};
console.log('selectedCurrency', selectedCurrency);
const rate = parseFloat((enteredAmount / 1.18).toFixed(2));
const sgst = parseFloat((rate * 0.09).toFixed(2));
const cgst = parseFloat((rate * 0.09).toFixed(2));
const igst = parseFloat((rate * 0.18).toFixed(2));
const total = parseFloat((rate + sgst + cgst).toFixed(2));
const adjustment = Math.abs(total - enteredAmount).toFixed(2);

const isIGSTApplicable = instituteFromRedux?.state !== leadBranch?.state;

function convertNumberToWords(number: any) {
	const ones = [
		'',
		'one',
		'two',
		'three',
		'four',
		'five',
		'six',
		'seven',
		'eight',
		'nine',
	];
	const teens = [
		'ten',
		'eleven',
		'twelve',
		'thirteen',
		'fourteen',
		'fifteen',
		'sixteen',
		'seventeen',
		'eighteen',
		'nineteen',
	];
	const tens = [
		'',
		'',
		'twenty',
		'thirty',
		'forty',
		'fifty',
		'sixty',
		'seventy',
		'eighty',
		'ninety',
	];
	const thousands = ['', 'thousand', 'million', 'billion', 'trillion'];

	// Function to convert a number less than 1000 into words
	function convertLessThanOneThousand(num: any) {
		let words = '';

		const hundreds = Math.floor(num / 100);
		const tensUnits = num % 100;

		if (hundreds > 0) {
			words += ones[hundreds] + ' hundred';
			if (tensUnits > 0) {
				words += ' and ';
			}
		}

		if (tensUnits < 10) {
			words += ones[tensUnits];
		} else if (tensUnits < 20) {
			words += teens[tensUnits - 10];
		} else {
			const tensDigit = Math.floor(tensUnits / 10);
			const onesDigit = tensUnits % 10;
			words += tens[tensDigit];
			if (onesDigit > 0) {
				words += ' ' + ones[onesDigit];
			}
		}

		return words.trim();
	}

	// Function to convert a number into words
	function convertToWords(num: any) {
		if (num === 0) {
			return 'zero';
		}

		let words = '';
		let groups = [];

		// Splitting number into groups of three digits
		while (num > 0) {
			groups.push(num % 1000);
			num = Math.floor(num / 1000);
		}

		// Convert each group into words
		groups.forEach((group, index) => {
			if (group === 0) {
				return;
			}

			let groupWords = convertLessThanOneThousand(group);

			if (groupWords.length > 0 && index < thousands.length) {
				groupWords += ' ' + thousands[index];
			}

			words = groupWords + ' ' + words;
		});

		return words.trim();
	}

	// Splitting the number into integer and fractional parts
	const [integerPart, fractionalPart] = number.toString().split('.');

	/* let result = 'Indian Rupees ' + convertToWords(parseInt(integerPart)); */
	let result = selectedCurrency
		? selectedCurrency + convertToWords(parseInt(integerPart))
		: 'Indian Rupees' + convertToWords(parseInt(integerPart));
	if (fractionalPart) {
		result +=
			' and ' + convertToWords(parseInt(fractionalPart)) + ' paisa only';
	} else {
		result += ' only';
	}

	return result;
}

	const grandTotalInWords = convertNumberToWords(enteredAmount);

	return (
		<Dialog
			open={showInvoice}
			onClose={handleCloseInvoice}
			sx={{
				zIndex: '9999999',
				minWidth: '550px',
				'& .MuiDialog-paper': {
					width: '100%',
					maxWidth: '595px',
					padding: '32px',
					left: 0,
					background: '#FFFFFF',
					overflow: 'hidden',
					borderRadius: 'unset',
					boxShadow: 'unset',
					height: '842px',
				},
			}}>
			{/* <div
        style={{
          position: "absolute",
          height: "100%",
          width: " 100%",
          background: "rgb(255 255 255 / 59%)",
          left: "0px",
          top: "0px",
          display: blurrEffect ? "block" : "none",
        }}
      ></div> */}
			<Box>
				<div
					style={{
						display: 'flex',
						justifyContent: 'flex-end',
						marginTop: '-20px',
						marginRight: '-20px',
					}}>
					<Button
						sx={{
							color: '#667085',
							padding: '0px',
							display: 'flex',
							width: 'fit-content',
							minWidth: '0px',
						}}
						onClick={() => handleCloseInvoice()}>
						<CloseOutlinedIcon />
					</Button>
				</div>
				<Box className={styles.receiptHead}>Tax invoice</Box>
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'flex-end',
					}}>
					<Box>
						<div className={styles.instituteLogo}>
							{instituteFromRedux?.logo && (
								<img src={instituteFromRedux?.logo} alt='logo' width='87px' />
							)}
						</div>

						<Box className={styles.instituteName}>
							{instituteFromRedux?.instituteName}
						</Box>
						<Box className={styles.instituteAddress}>
							{instituteFromRedux?.buildingNumber}, {instituteFromRedux?.city},{' '}
							{instituteFromRedux?.state} {instituteFromRedux?.pin}
							<br /> GSTIN : {instituteFromRedux.studentTaxInvoiceGSTNumber}
						</Box>
					</Box>
					<Box
						sx={{
							isplay: 'flex',
							flexDirection: 'column',
							justifyContent: 'flex-end',
						}}>
						<Box sx={{ display: 'flex' }}>
							<div className={styles.receiptDetailsLabel}>
								Invoice Number :{' '}
							</div>
							<div className={styles.receiptDetailsValue}> -</div>
						</Box>

						<Box sx={{ display: 'flex' }}>
							<div className={styles.receiptDetailsLabel}>Invoice Date : </div>
							<div className={styles.receiptDetailsValue}>
								{''}
								{moment().format('D MMMM YYYY')}
							</div>
						</Box>
					</Box>
				</Box>
				<Box sx={{ marginTop: '16px', marginBottom: '18px' }}>
					<div className={styles.billTo}>Bill To :</div>
					<Box sx={{ display: 'flex', gap: '4px' }}>
						<div className={styles.studentName}>Student name :</div>
						<div className={styles.studentName}>
							{leadById?.studentFirstName + ' ' + leadById?.studentLastName}
						</div>
					</Box>
					<Box sx={{ display: 'flex', gap: '4px' }}>
						<div className={styles.paymentSource}>Payment Source :</div>
						<div className={styles.paymentSource}>
							{paymentDetails?.paymentSource}
						</div>
					</Box>
				</Box>
				<Box sx={{ display: 'flex' }}>
					<div className={styles.supply}>Place of supply : </div>
					<div className={styles.supplyPlace}>{leadBranch?.state}</div>
				</Box>
				<TableContainer>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell
									sx={{
										color: '#142C44',
										fontSize: '11px',
										fontWeight: '500',
										textTransform: 'capitalize',
										maxWidth: '50px',
										padding: '10px 15px 8px 0',
										borderColor: '#000',
									}}
									width='50px'>
									#
								</TableCell>
								<TableCell
									sx={{
										color: '#142C44',
										fontSize: '11px',
										fontWeight: '500',
										textTransform: 'capitalize',
										padding: '10px  0px 8px 0',
										borderColor: '#000',
									}}>
									Item & Description
								</TableCell>
								<TableCell
									sx={{
										color: '#142C44',
										fontSize: '11px',
										fontWeight: '500',
										textTransform: 'capitalize',
										padding: '10px  0px 8px 0',
										textAlign: 'right',
										borderColor: '#000',
									}}>
									HSN/SAC
								</TableCell>
								<TableCell
									sx={{
										color: '#142C44',
										fontSize: '11px',
										fontWeight: '500',
										textTransform: 'capitalize',
										padding: '10px  0px 8px 0',
										textAlign: 'right',
										borderColor: '#000',
										// minWidth: "30px",
									}}>
									Qty
								</TableCell>
								<TableCell
									sx={{
										color: '#142C44',
										fontSize: '11px',
										fontWeight: '500',
										textTransform: 'capitalize',
										padding: '10px  0px 8px 0',
										textAlign: 'right',
										borderColor: '#000',
									}}>
									Rate
								</TableCell>

								{!isIGSTApplicable && (
									<>
										<TableCell
											sx={{
												color: '#142C44',
												fontSize: '11px',
												fontWeight: '500',
												textTransform: 'capitalize',
												padding: '10px  0px 8px 0',
												textAlign: 'right',
												borderColor: '#000',
											}}>
											SGST
										</TableCell>
										<TableCell
											sx={{
												color: '#142C44',
												fontSize: '11px',
												fontWeight: '500',
												textTransform: 'capitalize',
												padding: '10px  0px 8px 0',
												textAlign: 'right',
												borderColor: '#000',
											}}>
											CGST
										</TableCell>
									</>
								)}

								{isIGSTApplicable && (
									<TableCell
										sx={{
											color: '#142C44',
											fontSize: '11px',
											fontWeight: '500',
											textTransform: 'capitalize',
											padding: '10px  0px 8px 0',
											textAlign: 'right',
											borderColor: '#000',
										}}>
										IGST
									</TableCell>
								)}

								<TableCell
									sx={{
										color: '#142C44',
										fontSize: '11px',
										fontWeight: '500',
										textTransform: 'capitalize',
										padding: '10px  0px 8px 0',
										textAlign: 'right',
										borderColor: '#000',
									}}>
									Amount
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							<TableRow style={{ verticalAlign: 'baseline' }}>
								<TableCell
									sx={{
										color: '#667085',
										fontSize: '11px',
										fontWeight: '400',
										textTransform: 'capitalize',
										padding: '10px 0 16px 0px',
									}}>
									1
								</TableCell>
								<TableCell
									sx={{
										color: '#667085',
										fontSize: '11px',
										fontWeight: '400',
										textTransform: 'capitalize',
										padding: '10px 0 16px 0px',
										wordBreak: 'break-word',
										overflowWrap: 'break-word',
										maxWidth: '180px',
									}}>
									{leadById?.courseName}

									<div style={{ fontSize: '10px' }}>
										{paymentDetails?.description
											? `${'(' + paymentDetails.description + ')'}`
											: ''}
									</div>
								</TableCell>
								<TableCell
									sx={{
										color: '#667085',
										fontSize: '11px',
										fontWeight: '400',
										textTransform: 'capitalize',
										textAlign: 'right',
										padding: '10px 0 16px 5px',
									}}>
									999299
								</TableCell>
								<TableCell
									sx={{
										color: '#667085',
										fontSize: '11px',
										fontWeight: '400',
										textTransform: 'capitalize',
										textAlign: 'right',
										padding: '10px 0 16px 5px',
									}}>
									1
								</TableCell>
								<TableCell
									sx={{
										color: '#667085',
										fontSize: '11px',
										fontWeight: '400',
										textTransform: 'capitalize',
										textAlign: 'right',
										padding: '10px 0 16px 5px',
									}}>
									{rate}
								</TableCell>
								{!isIGSTApplicable && (
									<>
										<TableCell
											sx={{
												color: '#667085',
												fontSize: '11px',
												fontWeight: '400',
												textTransform: 'capitalize',
												textAlign: 'right',
												padding: '10px 0 16px 5px',
											}}>
											{sgst}
											<br />
											9%
										</TableCell>
										<TableCell
											sx={{
												color: '#667085',
												fontSize: '11px',
												fontWeight: '400',
												textTransform: 'capitalize',
												textAlign: 'right',
												padding: '10px 0 16px 5px',
											}}>
											{cgst}
											<br />
											9%
										</TableCell>
									</>
								)}
								{isIGSTApplicable && (
									<TableCell
										sx={{
											color: '#667085',
											fontSize: '11px',
											fontWeight: '400',
											textTransform: 'capitalize',
											textAlign: 'right',
											padding: '10px 0 16px 5px',
										}}>
										{igst}
										<br />
										18%
									</TableCell>
								)}

								<TableCell
									sx={{
										color: '#667085',
										fontSize: '11px',
										fontWeight: '400',
										textTransform: 'capitalize',
										textAlign: 'right',
										padding: '10px 0 16px 5px',
									}}>
									{enteredAmount}
								</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				</TableContainer>
				<Box className={styles.amountDetailsWrapper}>
					<Box sx={{ display: 'flex', flexDirection: 'column' }}>
						<Box className={styles.amountDetails}>
							<Box
								sx={{
									display: 'flex',
									justifyContent: 'space-between',
									gap: '5px',
								}}>
								<div className={styles.amountDetailsLabel}>Sub Total</div>
								<div className={styles.amountDetailsValue}>{rate}</div>
							</Box>

							<Box
								sx={{
									display: 'flex',
									justifyContent: 'space-between',
									gap: '5px',
								}}>
								<div className={styles.amountDetailsLabel}>SGST(9%)</div>
								<div className={styles.amountDetailsValue}>{sgst}</div>
							</Box>

							<Box
								sx={{
									display: 'flex',
									justifyContent: 'space-between',
									gap: '5px',
								}}>
								<div className={styles.amountDetailsLabel}>CGST(9%)</div>
								<div className={styles.amountDetailsValue}>{cgst}</div>
							</Box>

							<Box
								sx={{
									display: 'flex',
									justifyContent: 'space-between',
									gap: '5px',
								}}>
								<div className={styles.amountDetailsLabel}>Adjustment</div>
								<div className={styles.amountDetailsValue}>{adjustment}</div>
							</Box>

							<Box
								sx={{
									display: 'flex',
									justifyContent: 'space-between',
									gap: '5px',
								}}>
								<div className={styles.totalLabel}>Total</div>
								<div className={styles.totalValue}>{enteredAmount}</div>
							</Box>
						</Box>
						<Box className={styles.amountDetails}>
							<Box
								sx={{
									display: 'flex',
									justifyContent: 'space-between',
									gap: '5px',
								}}>
								<div
									className={styles.balanceDueLabel}
									style={{ fontWeight: '500' }}>
									Balance Due
								</div>
								<div
									className={styles.balanceDuevalue}
									style={{ fontWeight: '500' }}>
									0.00
								</div>
							</Box>
						</Box>
					</Box>
				</Box>
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'flex-end',
						gap: '5px',
						marginTop: '12px',
					}}>
					<div className={styles.totalInWordsLabel}>Total In Words :</div>
					<div className={styles.totalInWordsValue}>{grandTotalInWords}</div>
				</Box>
			</Box>
		</Dialog>
	);
}

export default ViewInvoice;
