import React, { useEffect, useRef, useState } from "react";
import styles from "./WebFormBuilding.module.css";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  InputAdornment,
  TextField,
} from "@mui/material";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import text from "../WebFormBuilding/Images/text.svg";
import phone from "../WebFormBuilding/Images/phone.svg";
import dropDown from "../WebFormBuilding/Images/double-alt-arrow-down.svg";
import date from "../WebFormBuilding/Images/date.svg";
import radioButton from "../WebFormBuilding/Images/radio-button.svg";
import checkBox from "../WebFormBuilding/Images/checkbox.svg";
import CustomizedCourseField from "../CustomizedField/CustomizedCourseField";
import CustomizedDropdownField from "../CustomizedField/CustomizedDropdownField";
import CustomizedPhoneField from "../CustomizedField/CustomizedPhoneField";
import CustomizedRadioField from "../CustomizedField/CustomizedRadioField";
import CustomizedCheckboxField from "../CustomizedField/CustomizedCheckboxField";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { useAuthUser } from "react-auth-kit";
import CustomizedEmailField from "../CustomizedField/CustomizedEmailField";
import PreviewFormPopup from "../PreviewFormPopup/PreviewFormPopup";
import CustomizedTextField from "../CustomizedField/CustomizedTextField";
import CancelFormPopup from "../CancelFormPopup/CancelFormPopup";
import CustomizedDateField from "../CustomizedField/CustomizedDateField";
import { useNavigate } from "react-router";
import EditIcon from "@mui/icons-material/Edit";
import ButtonRenamePopup from "../ButtonRenamePopup/ButtonRenamePopup";
import {
  resetCustomWebformFieldSuccess,
  resetUpdateWebformSuccess,
} from "../../service/webformSlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { GetNurtureLeadBoard } from "../../../../Nurture/service/nurture.request";
import { setToastNotification } from "../../../../../../components/ToastNotification/service/toastNotificationSlice";
import ListPopover from "../../../../../../components/ListPopover/ListPopover";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { pureFinalPropsSelectorFactory } from "react-redux/es/connect/selectorFactory";
import { getSubDomainAccessStatus } from "../../../../../../components/RolePermissionAccess/RolePermissionAccess";
import { GetPrimaryFieldsByInstituteForBranch } from "../../service/webform.request";
import { GetListStaff } from "../../../../Manage/ManageStaff/service/Staff.request";
import { getCombinedAssignees } from "../../../../../../components/CommonFunctions/CommonFunctions";

const customFields = [
  {
    fieldType: "text",
    fieldName: "Text Field",
    fieldImage: text,
    fieldPlaceholder: "Enter",
    options: [],
    default: "",
    required: false,
    index: 1,
    loadDatafromDB: false,
    DBModel: "",
    isCustom: true,
    isFromCustom: true,
  },

  {
    fieldType: "phone",
    fieldName: "Phone Number",
    fieldImage: phone,
    fieldPlaceholder: "Enter Phone",
    options: [],
    default: "",
    required: false,
    index: 1,
    loadDatafromDB: false,
    DBModel: "",
    isCustom: true,
    isFromCustom: true,
  },
  {
    fieldType: "select",
    fieldName: "Dropdown",
    fieldImage: dropDown,
    fieldPlaceholder: "Select",
    options: [],
    default: "",
    required: false,
    index: 1,
    loadDatafromDB: false,
    DBModel: "",
    isCustom: true,
    isFromCustom: true,
  },

  {
    fieldType: "date",
    fieldName: "Date",
    fieldImage: date,
    fieldPlaceholder: "Select Date",
    options: [],
    default: "",
    required: false,
    index: 1,
    loadDatafromDB: false,
    DBModel: "",
    isCustom: true,
    isFromCustom: true,
  },

  {
    fieldType: "radio",
    fieldName: "Radio Button",
    fieldImage: radioButton,
    fieldPlaceholder: "",
    options: [],
    default: "",
    required: false,
    index: 1,
    loadDatafromDB: false,
    DBModel: "",
    isCustom: true,
    isFromCustom: true,
  },

  {
    fieldType: "checkbox",
    fieldName: "Checkbox",
    fieldImage: checkBox,
    fieldPlaceholder: "",
    options: [],
    default: "",
    required: false,
    index: 1,
    loadDatafromDB: false,
    DBModel: "",
    isCustom: true,
    isFromCustom: true,
  },
];

function WebFormBuilding({
  GetWebformForInstitute,
  getFormByInstituteData,
  GetPrimaryFiledsByInstitute,
  getPrimaryFieldsData,
  UpdateWebform,
  UpdateWebformSuccess,
  CreateWebformCustomField,
  CreateCustomFieldWebformSuccess,
  CreateCustomFieldWebformError,
  UpdateWebformLoading,
  DeleteWebform,
  GetPrimaryFiledsByInstituteForBranch,
}: any) {
  const auth = useAuthUser();
  const authUser = auth();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [draggedField, setDraggedField] = useState<any>(null);
  const [rightSectionFields, setRightSectionFields] = useState<any[]>([]);
  const [showPreviewPopup, setShowPreviewPopup] = useState(false);
  const [showCancelPopup, setShowCancelPopup] = useState(false);

  const [duplicateField, setDuplicateField] = useState<any>();
  const [isComingFromNextButton, setIscomingFromNextButton] = useState(false);
  const [showButtonRenamePopup, setShowButtonRenamePopup] = useState(false);
  const [updatedButtonName, setUpdatedButtonName] = useState("");
  const [draggedFieldIndex, setDraggedFieldIndex] = useState<number | null>(
    null
  );
  const [formFieldExist, setFormFieldExist] = useState(false);
  const [formSubmission, setFormSubmission] = useState(false);
  const [isTextFieldFocused, setIsTextFieldFocused] = useState(false);
  const [isDropdownFieldFocused, setIsDropdownFieldFocused] = useState(false);
  const [isCheckboxFieldFocused, setIsCheckboxFieldFocused] = useState(false);
  const [isRadioFieldFocused, setIsRadioFieldFocused] = useState(false);
  const [isDateFieldFocused, setIsDateFieldFocused] = useState(false);
  const [isPhoneFieldFocused, setIsPhoneFieldFocused] = useState(false);
  const [leadsFromResponseChecked, setLeadsFromResponseChecked] =
    useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [selectedAssinees, setSelectedAssinees] = useState<any>();
  const [emp, setEmp] = useState<any>();
  const [dragActive, setDragActive] = useState(false);
  const open = Boolean(anchorEl);
  const containerRef = useRef<any>(null);

  const [formType, setFormType] = useState<string>("");
  const [branchAnchorEl, setBranchAnchorEl] = useState(null);
  const openBranch = Boolean(branchAnchorEl);
  const [searchBranch, setSearchBranch] = useState<string>("");
  const [selectedBranch, setSelectedBranch] = useState<any>();
  const branchFieldRef = useRef<HTMLInputElement | null>(null);
  const formTypeFieldRef = useRef<HTMLInputElement | null>(null);
  const [formTypeAnchorEl, setFormTypeAnchorEl] = useState(null);
  const openFormType = Boolean(formTypeAnchorEl);

  const { userRoleById, userById } = useSelector(
    (state: any) => state.userDetails
  );
  const branchList = useSelector((state: any) => state.branch.branchList);
  const assignees = useSelector((state: any) => state.staff.staffList);

  React.useEffect(() => {
    if (getFormByInstituteData) {
      setUpdatedButtonName(getFormByInstituteData?.buttonName);
      setLeadsFromResponseChecked(getFormByInstituteData?.isCreateLead);
      const userFromLocalStorage = authUser?.institute[0];
      if (userFromLocalStorage && getFormByInstituteData?.assignedToId !== "") {
        GetNurtureLeadBoard(userFromLocalStorage?.instituteId)
          .then((res: any) => {
            setEmp(res?.data?.data);
            const selectedEmployee = res?.data?.data?.employees?.filter(
              (item: any) => item?.id === getFormByInstituteData?.assignedToId
            );
            setSelectedAssinees(selectedEmployee && selectedEmployee[0]);
          })
          .catch((err: any) => {});
      }
      const selectedEmployee = emp?.employees?.filter(
        (item: any) => item?.id === getFormByInstituteData?.assignedToId
      );
      setSelectedAssinees(selectedEmployee && selectedEmployee[0]);

      const branchListForOtherUser =
        branchList &&
        branchList.filter((item: any) => {
          if (item?.id === getFormByInstituteData?.branchId) {
            return item;
          }
        });
      setSelectedBranch(branchListForOtherUser && branchListForOtherUser[0]);
    }
  }, [getFormByInstituteData]);

  React.useEffect(() => {
    setFormType(
      getFormByInstituteData?.type === "UNIVERSAL" ||
        getFormByInstituteData?.type === ""
        ? "Universal"
        : getFormByInstituteData?.type === "BRANCH_SPECIFIC"
        ? "Branch Specific"
        : ""
    );
  }, [getFormByInstituteData]);

  React.useEffect(() => {
    const pathname = window.location.pathname;
    const formId = pathname.substring(pathname.lastIndexOf("/") + 1);
    const userFromLocalStorage = authUser?.institute[0];
    if (userFromLocalStorage && formId) {
      GetWebformForInstitute(userFromLocalStorage?.instituteId, formId);
    }
  }, [GetWebformForInstitute]);

  React.useEffect(() => {
    const userFromLocalStorage = authUser?.institute[0];
    if (userFromLocalStorage) {
      GetNurtureLeadBoard(userFromLocalStorage?.instituteId)
        .then((res: any) => {
          setEmp(res?.data?.data);
        })
        .catch((err: any) => {});
    }
  }, []);

  React.useEffect(() => {
    if (getFormByInstituteData && getFormByInstituteData.fields) {
      setRightSectionFields(getFormByInstituteData.fields);
    }
  }, [getFormByInstituteData]);

  React.useEffect(() => {
    if (UpdateWebformSuccess !== false) {
      // navigate("/app/Inbound/Webforms", { state: 2 });
      dispatch(resetUpdateWebformSuccess());
      navigate(
        `/app/Inbound/Webforms?webformTab=forms&webformSharePopup=${getFormByInstituteData?.id}`
      );
    }
  }, [UpdateWebformSuccess]);

  React.useEffect(() => {
    const userFromLocalStorage = authUser?.institute[0];
    const lastEvaluatedKey = "";
    const limit = 30;
    const lastEvaluatedSortKey = "";
    if (userFromLocalStorage && formType === "Universal") {
      GetPrimaryFiledsByInstitute(
        userFromLocalStorage?.instituteId,
        lastEvaluatedKey,
        limit,
        lastEvaluatedSortKey
      );
    }
  }, [GetPrimaryFiledsByInstitute, formType]);

  React.useEffect(() => {
    const userFromLocalStorage = authUser?.institute[0];
    const lastEvaluatedKey = "";
    const limit = 30;
    const lastEvaluatedSortKey = "";
    if (
      userFromLocalStorage &&
      formType === "Branch Specific" &&
      selectedBranch
    ) {
      GetPrimaryFiledsByInstituteForBranch(
        userFromLocalStorage?.instituteId,
        lastEvaluatedKey,
        limit,
        lastEvaluatedSortKey,
        selectedBranch?.id
      );
    }
  }, [GetPrimaryFiledsByInstitute, formType, selectedBranch]);

  React.useEffect(() => {
    const userFromLocalStorage = authUser?.institute[0];
    const lastEvaluatedKey = "";
    const limit = 30;
    const lastEvaluatedSortKey = "";
    if (CreateCustomFieldWebformSuccess) {
      GetPrimaryFiledsByInstitute(
        userFromLocalStorage?.instituteId,
        lastEvaluatedKey,
        limit,
        lastEvaluatedSortKey
      );
    }
  }, [CreateCustomFieldWebformSuccess]);

  function handleOnDrag(e: any, field: any) {
    setDraggedField(field);
  }

  function handleOnDrop(e: any) {
    e.preventDefault();
    if (draggedField) {
      let fieldExist: any;

      if (
        draggedField?.DBModel === "COURSE" ||
        draggedField?.DBModel === "BRANCH" ||
        draggedField?.DBModel === "BATCH"
      ) {
        fieldExist = rightSectionFields?.filter(
          (item: any) => item?.DBModel === draggedField?.DBModel
        );
      } else {
        fieldExist = rightSectionFields?.filter(
          (item: any) => item?.fieldName === draggedField?.fieldName
        );
      }

      if (
        formType === "Branch Specific" &&
        draggedField?.DBModel === "BRANCH"
      ) {
        leadsFromResponseChecked
          ? dispatch(
              setToastNotification({
                message: String(
                  selectedBranch
                    ? "Branch is already selected for automatic lead generation"
                    : "Please Select a Branch on the form"
                ),
                type: "error",
                snackOpen: true,
              })
            )
          : dispatch(
              setToastNotification({
                message: String(
                  selectedBranch
                    ? "Branch is already selected for this webform"
                    : "Please Select a Branch on the form"
                ),
                type: "error",
                snackOpen: true,
              })
            );
      } else {
        if (fieldExist?.length > 0) {
          setFormFieldExist(true);
          setDraggedField(null);
        } else {
          setRightSectionFields([...rightSectionFields, draggedField]);
          setDraggedField(null);
        }
      }
    }
  }

  function handleOnDragOver(e: any) {
    e.preventDefault();
    if (dragActive) {
      const y = e.clientY;
      const containerRect = containerRef.current.getBoundingClientRect();
      const scrollSpeed = 10;
      let scrollDirection = 0;

      if (y < containerRect.top + 100) {
        scrollDirection = -1;
      } else if (y > containerRect.bottom - 100) {
        scrollDirection = 1;
      }

      if (scrollDirection !== 0) {
        containerRef.current.scrollTop += scrollDirection * scrollSpeed;
      }
    }
  }

  function handleUpdateForm() {
    const updatedFormData = {
      ...getFormByInstituteData,
      status: "PUBLISHED",
      fields:
        rightSectionFields &&
        rightSectionFields?.map((field: any) => {
          if (field.DBModel === "COURSE" || field.DBModel === "BRANCH") {
            return {
              ...field,
              required: leadsFromResponseChecked ? true : false,
            };
          }
          return field;
        }),
      buttonName: updatedButtonName,
      isCreateLead: leadsFromResponseChecked,
      assignedToName:
        selectedAssinees &&
        `${selectedAssinees?.firstName} ${selectedAssinees?.lastName}`,
      assignedToId: selectedAssinees && selectedAssinees?.id,
      formCreationComplete: true,
      type:
        formType === "Universal"
          ? "UNIVERSAL"
          : formType === "Branch Specific"
          ? "BRANCH_SPECIFIC"
          : "",
      branchId: formType === "Branch Specific" ? selectedBranch?.id : "",
    };

    let isEmptyField = false;

    const expectedCourse = ["COURSE"];
    const expectedBranch = ["BRANCH"];

    let isCourseSelected = false;
    let isBranchSelected = false;

    rightSectionFields.forEach((field: any) => {
      if (
        leadsFromResponseChecked === true &&
        expectedCourse.every((fieldName) =>
          rightSectionFields.some((field) => field.DBModel === fieldName)
        )
      ) {
        isCourseSelected = true;
      }
    });

    rightSectionFields.forEach((field: any) => {
      if (
        leadsFromResponseChecked === true &&
        expectedBranch.every((fieldName) =>
          rightSectionFields.some((field) => field.DBModel === fieldName)
        )
      ) {
        isBranchSelected = true;
      }
    });

    rightSectionFields.forEach((field: any) => {
      if (
        (field.fieldType === "select" ||
          field.fieldType === "checkbox" ||
          field.fieldType === "radio") &&
        field.options.length === 0
      ) {
        isEmptyField = true;
      }

      if (
        (field.fieldType === "select" ||
          field.fieldType === "checkbox" ||
          field.fieldType === "radio") &&
        field.options &&
        field.options.length > 0
      ) {
        const answer1Option = field.options.some(
          (option: any) => option.name === "" && option.id === ""
        );
        if (answer1Option) {
          isEmptyField = true;
        }
      }
    });

    if (isCourseSelected === false && leadsFromResponseChecked) {
      dispatch(
        setToastNotification({
          message: String("Please add Course"),
          type: "error",
          snackOpen: true,
        })
      );
    } else if (
      isBranchSelected === false &&
      leadsFromResponseChecked &&
      formType === "Universal"
    ) {
      dispatch(
        setToastNotification({
          message: String("Please add Branch"),
          type: "error",
          snackOpen: true,
        })
      );
    } else if (isEmptyField) {
      dispatch(
        setToastNotification({
          message: String("Please add answers to the respected fields"),
          type: "error",
          snackOpen: true,
        })
      );
    } else {
      leadsFromResponseChecked === true &&
      (selectedAssinees === undefined || selectedAssinees === null)
        ? dispatch(
            setToastNotification({
              message: String("Please select Assigne"),
              type: "error",
              snackOpen: true,
            })
          )
        : UpdateWebform(updatedFormData);
    }
  }

  const handleFieldChange = (updatedField: any, index: any) => {
    const newFields = [...rightSectionFields];
    newFields[index] = updatedField;
    setRightSectionFields(newFields);
  };

  const handleDeleteField = (fieldToDelete: any) => {
    const updatedFields = rightSectionFields.filter(
      (field) => field !== fieldToDelete
    );

    setRightSectionFields(updatedFields);
  };

  React.useEffect(() => {
    if (CreateCustomFieldWebformSuccess) {
      dispatch(resetCustomWebformFieldSuccess());
    }
  }, [CreateCustomFieldWebformSuccess]);

  function handleOnDragFields(e: any, field: any, index: number) {
    setDraggedField(field);
    setDraggedFieldIndex(index);
    setDragActive(true);
  }

  function handleOnDragEnter(e: any, field: any, index: number) {
    if (draggedField !== null && draggedFieldIndex !== null) {
      const updatedFields = [...rightSectionFields];
      updatedFields.splice(draggedFieldIndex, 1);

      const dropPosition = e.clientY;
      const middleOfList = window.innerHeight / 2;
      updatedFields.splice(index, 0, draggedField);
      setRightSectionFields(updatedFields);
      setDraggedField(null);
      setDraggedFieldIndex(null);
      setFormFieldExist(false);
    }
  }

  const customFieldsCounts =
    getPrimaryFieldsData &&
    getPrimaryFieldsData?.returnData?.filter(
      (d: any) => d?.field?.isDeletable === true
    )?.length;

  const handleOpenPopover = (event: any) => {
    setAnchorEl(event.currentTarget);
    const tempElement = document.createElement("input");
    document.body.appendChild(tempElement);
    tempElement.focus();
    document.body.removeChild(tempElement);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const handleSearch = (event: any) => {
    setSearchText(event.target.value);
  };

  const handleCheckboxChange = (emp: any) => {
    setSelectedAssinees(emp);
    handleClosePopover();
  };

  const assigneeFieldRef = useRef<HTMLInputElement | null>(null);

  const handleOpenBranchPopover = (event: any) => {
    setBranchAnchorEl(event.currentTarget);
    const tempElement = document.createElement("input");
    document.body.appendChild(tempElement);
    tempElement.focus();
    document.body.removeChild(tempElement);
  };

  const handleOpenFormTypePopover = (event: any) => {
    setFormTypeAnchorEl(event.currentTarget);
    const tempElement = document.createElement("input");
    document.body.appendChild(tempElement);
    tempElement.focus();
    document.body.removeChild(tempElement);
  };

  const handleCloseBranchPopover = () => {
    setBranchAnchorEl(null);
  };

  const handleCloseFormTypePopover = () => {
    setFormTypeAnchorEl(null);
  };

  const handleBranchSearch = (event: any) => {
    setSearchBranch(event.target.value);
  };

  const handleBranchSelect = (emp: any) => {
    setSelectedBranch(emp);
    handleCloseBranchPopover();
    setSelectedAssinees(null);
  };

  const handleFormTypeSelect = (emp: any) => {
    setFormType(emp.name);
    handleCloseFormTypePopover();
    setSelectedBranch(null);
    setSelectedAssinees(null);
  };

  const hasAllBranchAccess = getSubDomainAccessStatus(
    "NURTURE_BOARD",
    "BOARD",
    "ALL_BRANCHES",
    // userRoleById?.permissions
    userById?.rolePermission
  );

  const filteredBranchList = hasAllBranchAccess
    ? branchList
    : branchList?.filter(
        (branch: any) => branch.id === authUser?.institute?.[0]?.branchId
      );

  const filteredEmployeeList =
    leadsFromResponseChecked && (formType === "Universal" || formType === "")
      ? assignees &&
        assignees?.filter((branch: any) => branch.allBranchAccess === true)
      : formType === "Branch Specific" &&
        selectedBranch &&
        leadsFromResponseChecked
      ? getCombinedAssignees(assignees, selectedBranch?.id)
      : [];

  useEffect(() => {
    const expectedBranch = ["BRANCH"];

    let isBranchSelected = false;

    rightSectionFields.forEach((field: any) => {
      if (
        expectedBranch.every((fieldName) =>
          rightSectionFields.some((field) => field.DBModel === fieldName)
        )
      ) {
        isBranchSelected = true;
      }
    });
    if (formType === "Branch Specific" && isBranchSelected) {
      const filteredFields = rightSectionFields.filter(
        (field: any) => field.fieldName !== "branch"
      );
      setRightSectionFields(filteredFields);
    }
  }, [formType]);

  return (
    <Box className={styles.formBuildingWrapper}>
      <Box
        className={styles.leftSection}
        style={{ maxHeight: "calc(100vh - 94px)", overflowY: "auto" }}
      >
        <Box
          sx={{
            color: "#142C44",
            fontFamily: "Poppins",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "500",
            lineHeight: "24px",
            marginBottom: "8px",
          }}
        >
          Form Fields
        </Box>
        <Box
          sx={{
            color: "#667085",
            fontFamily: "Poppins",
            fontSize: "13px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "20px",
            marginBottom: "6px",
          }}
        >
          Primary
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column-reverse" }}>
          {getPrimaryFieldsData &&
            getPrimaryFieldsData?.returnData
              ?.filter((d: any) => d?.field?.isDeletable === false)
              ?.map((item: any, index: any) => (
                <div
                  key={index}
                  style={{
                    borderRadius: "4px",
                    border: "1px solid #E9E9E9",
                    padding: "12px",
                    marginBottom: "6px",
                    display: "flex",
                    alignItems: "center",
                  }}
                  draggable
                  onDragStart={(e) => handleOnDrag(e, item?.field)}
                >
                  <div style={{ display: "flex", color: "#667085" }}>
                    <DragIndicatorIcon />
                  </div>
                  <div className={styles.primaryFields}>
                    {item?.field?.fieldName}
                  </div>
                </div>
              ))}
        </Box>
        {getPrimaryFieldsData &&
          getPrimaryFieldsData?.returnData?.filter(
            (d: any) => d?.field?.isDeletable === true
          )?.length > 0 && (
            <>
              <Box
                sx={{
                  color: "#667085",
                  fontFamily: "Poppins",
                  fontSize: "13px",
                  fontStyle: "normal",
                  fontWeight: "400",
                  lineHeight: "20px",
                  marginBottom: "6px",
                  marginTop: "20px",
                }}
              >
                Saved
              </Box>
              <Box sx={{ display: "flex", flexDirection: "column-reverse" }}>
                {getPrimaryFieldsData &&
                  getPrimaryFieldsData?.returnData
                    ?.filter((d: any) => d?.field?.isDeletable === true)
                    ?.map((item: any, index: any) => (
                      <div
                        key={index}
                        style={{
                          borderRadius: "4px",
                          border: "1px solid #E9E9E9",
                          padding: "12px",
                          marginBottom: "6px",
                          display: "flex",
                          alignItems: "center",
                        }}
                        draggable
                        onDragStart={(e) => handleOnDrag(e, item?.field)}
                      >
                        <div style={{ display: "flex", color: "#667085" }}>
                          <DragIndicatorIcon />
                        </div>
                        <div className={styles.primaryFields}>
                          {item?.field?.fieldName}
                        </div>
                      </div>
                    ))}
              </Box>
            </>
          )}
        <Box
          sx={{
            color: "#667085",
            fontFamily: "Poppins",
            fontSize: "13px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "20px",
            marginBottom: "6px",
            marginTop: "12px",
          }}
        >
          Custom
        </Box>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
          }}
        >
          {customFields.map((field, index) => (
            <div
              key={index}
              style={{
                borderRadius: "4px",
                border: "1px solid #E9E9E9",
                padding: "12px",
                marginBottom: "6px",
                minWidth: "140px",
                textAlign: "center",
              }}
              draggable
              onDragStart={(e) => handleOnDrag(e, field)}
            >
              <div>
                <img src={field.fieldImage} alt="" />
              </div>
              <div className={styles.customFields}>{field.fieldName}</div>
            </div>
          ))}
        </Box>
      </Box>

      <Box
        sx={{
          width: "100%",
          maxHeight: "calc(100vh - 94px)",
          // overflowY: "auto",
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
        }}
      >
        <div
          className={styles.formName}
          style={{
            padding: "20px 10px 10px 24px",
            textTransform: "capitalize",
          }}
        >
          {getFormByInstituteData?.formName}
        </div>
        <Box
          className={styles.rightSection}
          onDrop={handleOnDrop}
          onDragOver={handleOnDragOver}
          style={{
            height: "300%",
            padding: "24px",
            maxHeight: "calc(100vh - 94px)",
            overflowY: "auto",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: "44px",
                }}
              >
                <div
                  style={{
                    color: "rgba(102, 112, 133, 1)",
                    marginRight: "10px",
                  }}
                >
                  Type{" "}
                </div>

                <TextField
                  onClick={handleOpenFormTypePopover}
                  value={formType ? formType : ""}
                  variant="outlined"
                  size="small"
                  placeholder="Select"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <ArrowDropDownIcon />
                      </InputAdornment>
                    ),
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root input": {
                      textTransform: "capitalize",
                      color: "#667085",
                      fontSize: "14px",
                      fontWeight: "500",
                    },
                    width: "180px",
                  }}
                  inputRef={formTypeFieldRef}
                />
                <ListPopover
                  openState={openFormType}
                  anchorState={formTypeAnchorEl}
                  handleClosePopover={handleCloseFormTypePopover}
                  handleListSearch={handleBranchSearch}
                  popupList={[
                    { id: 0, name: "Universal" },
                    { id: 1, name: "Branch Specific" },
                  ]}
                  handleItemSelect={handleFormTypeSelect}
                  listType={"FORMTYPE"}
                />
              </div>
              {formType === "Branch Specific" && (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div
                    style={{
                      color: "rgba(102, 112, 133, 1)",
                      marginRight: "10px",
                    }}
                  >
                    Branch{" "}
                  </div>

                  <TextField
                    onClick={handleOpenBranchPopover}
                    value={selectedBranch ? selectedBranch?.branchName : ""}
                    variant="outlined"
                    size="small"
                    placeholder="Branch"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <ArrowDropDownIcon />
                        </InputAdornment>
                      ),
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root input": {
                        textTransform: "capitalize",
                        color: "#667085",
                        fontSize: "14px",
                        fontWeight: "500",
                      },
                      width: "250px",
                    }}
                    inputRef={branchFieldRef}
                  />
                  <ListPopover
                    openState={openBranch}
                    anchorState={branchAnchorEl}
                    handleClosePopover={handleCloseBranchPopover}
                    handleListSearch={handleBranchSearch}
                    popupList={filteredBranchList?.filter((item: any) =>
                      item?.branchName
                        .toLowerCase()
                        .includes(searchBranch?.toLowerCase())
                    )}
                    handleItemSelect={handleBranchSelect}
                    listType={"BRANCH"}
                  />
                </div>
              )}
            </div>

            <div
              style={{
                display: "flex",
                marginTop: "20px",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  fontSize: "12px",
                  alignItems: "center",
                  margin: "10px 10px 10px 0px",
                }}
              >
                <Checkbox
                  sx={{
                    strokeWidth: 1,
                    color: "rgba(102, 112, 133, 0.30)",
                  }}
                  checked={leadsFromResponseChecked}
                  onChange={(event: any) => {
                    setLeadsFromResponseChecked(event.target.checked);
                    setSelectedAssinees(null);
                  }}
                  inputProps={{ "aria-label": "controlled" }}
                />
                Directly create leads from the responses
              </div>

              {leadsFromResponseChecked && (
                <FormControl
                  variant="standard"
                  className="formControl"
                  sx={{
                    width: "200px",
                  }}
                >
                  <TextField
                    onClick={handleOpenPopover}
                    value={
                      selectedAssinees
                        ? `${selectedAssinees?.firstName} ${selectedAssinees?.lastName}`
                        : ""
                    }
                    variant="outlined"
                    size="small"
                    placeholder="Select Assignee"
                    inputRef={assigneeFieldRef}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <ArrowDropDownIcon />
                        </InputAdornment>
                      ),
                    }}
                    sx={{
                      minWidth: "220px",
                      "& .MuiOutlinedInput-root input": {
                        textTransform: "capitalize",
                      },
                      "& .MuiOutlinedInput-root ": {
                        textTransform: "capitalize",
                      },
                      "& .MuiOutlinedInput-root fieldset": {
                        padding: "8px 14px",
                      },
                    }}
                  />
                  <ListPopover
                    openState={open}
                    anchorState={anchorEl}
                    handleClosePopover={handleClosePopover}
                    handleListSearch={handleSearch}
                    popupList={
                      filteredEmployeeList &&
                      filteredEmployeeList
                        ?.filter(
                          (emp: any) =>
                            emp.status === "ACTIVE" &&
                            emp.firstName
                              .toLowerCase()
                              .includes(searchText.toLowerCase())
                        )
                        ?.filter((user: any) => {
                          return (
                            user?.leadCardAccess === true
                            // &&
                            // user?.branchId === userById?.branchId
                          );
                        })
                    }
                    handleItemSelect={handleCheckboxChange}
                    listType={"ASSIGNEE"}
                  />
                </FormControl>
              )}
            </div>
          </div>
          {formType !== "" ? (
            <div>
              {rightSectionFields?.map((field, index) => (
                <div
                  key={index}
                  className={styles.primaryFields}
                  draggable={
                    isTextFieldFocused === false &&
                    isDropdownFieldFocused === false &&
                    isDateFieldFocused === false &&
                    isRadioFieldFocused === false &&
                    isCheckboxFieldFocused === false &&
                    isPhoneFieldFocused === false
                      ? true
                      : false
                  }
                  onDragStart={(e) => handleOnDragFields(e, field, index)}
                  // onDrop={(e) => handleOnDrop(e)}
                  onDragOver={(e) => handleOnDragOver(e)}
                  onDropCapture={(e) => handleOnDragEnter(e, field, index)}
                >
                  {field.fieldType === "text" && (
                    <CustomizedTextField
                      onFieldChange={(updatedField: any) =>
                        handleFieldChange(updatedField, index)
                      }
                      field={field}
                      onDelete={handleDeleteField}
                      CreateWebformCustomField={CreateWebformCustomField}
                      isTextFieldFocused={isTextFieldFocused}
                      setIsTextFieldFocused={setIsTextFieldFocused}
                      leadsFromResponseChecked={leadsFromResponseChecked}
                    />
                  )}
                  {field.fieldType === "phone" && (
                    <CustomizedPhoneField
                      onFieldChange={(updatedField: any) =>
                        handleFieldChange(updatedField, index)
                      }
                      field={field}
                      onDelete={handleDeleteField}
                      CreateWebformCustomField={CreateWebformCustomField}
                      isPhoneFieldFocused={isPhoneFieldFocused}
                      setIsPhoneFieldFocused={setIsPhoneFieldFocused}
                      leadsFromResponseChecked={leadsFromResponseChecked}
                    />
                  )}
                  {field.fieldType === "email" && (
                    <CustomizedEmailField
                      onFieldChange={(updatedField: any) =>
                        handleFieldChange(updatedField, index)
                      }
                      field={field}
                      onDelete={handleDeleteField}
                      CreateWebformCustomField={CreateWebformCustomField}
                      leadsFromResponseChecked={leadsFromResponseChecked}
                    />
                  )}
                  {field.fieldType === "course" && (
                    <CustomizedCourseField
                      field={field}
                      onFieldChange={(updatedField: any) =>
                        handleFieldChange(updatedField, index)
                      }
                    />
                  )}
                  {field.fieldType === "checkbox" && (
                    <CustomizedCheckboxField
                      field={field}
                      onFieldChange={(updatedField: any) =>
                        handleFieldChange(updatedField, index)
                      }
                      onDelete={handleDeleteField}
                      CreateWebformCustomField={CreateWebformCustomField}
                      isCheckboxFieldFocused={isCheckboxFieldFocused}
                      setIsCheckboxFieldFocused={setIsCheckboxFieldFocused}
                      leadsFromResponseChecked={leadsFromResponseChecked}
                    />
                  )}
                  {field.fieldType === "select" && (
                    <CustomizedDropdownField
                      onFieldChange={(updatedField: any) =>
                        handleFieldChange(updatedField, index)
                      }
                      field={field}
                      onDelete={handleDeleteField}
                      CreateWebformCustomField={CreateWebformCustomField}
                      isDropdownFieldFocused={isDropdownFieldFocused}
                      setIsDropdownFieldFocused={setIsDropdownFieldFocused}
                      leadsFromResponseChecked={leadsFromResponseChecked}
                    />
                  )}
                  {field.fieldType === "radio" && (
                    <CustomizedRadioField
                      field={field}
                      onFieldChange={(updatedField: any) =>
                        handleFieldChange(updatedField, index)
                      }
                      onDelete={handleDeleteField}
                      CreateWebformCustomField={CreateWebformCustomField}
                      isRadioFieldFocused={isRadioFieldFocused}
                      setIsRadioFieldFocused={setIsRadioFieldFocused}
                      leadsFromResponseChecked={leadsFromResponseChecked}
                    />
                  )}
                  {field.fieldType === "date" && (
                    <CustomizedDateField
                      field={field}
                      onDelete={handleDeleteField}
                      CreateWebformCustomField={CreateWebformCustomField}
                      onFieldChange={(updatedField: any) =>
                        handleFieldChange(updatedField, index)
                      }
                      isDateFieldFocused={isDateFieldFocused}
                      setIsDateFieldFocused={setIsDateFieldFocused}
                      leadsFromResponseChecked={leadsFromResponseChecked}
                    />
                  )}
                </div>
              ))}
              <Button
                variant="outlined"
                endIcon={<EditIcon style={{ fontSize: "20px" }} />}
                onClick={() => setShowButtonRenamePopup(true)}
              >
                {`${
                  updatedButtonName &&
                  updatedButtonName.charAt(0).toUpperCase() +
                    updatedButtonName.slice(1)?.toLowerCase()
                }`}
              </Button>
            </div>
          ) : (
            <div
              style={{
                color: "#3F3E43",
                fontSize: "15px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "10px",
                border: "1px solid #E9E9E9",
                marginTop: "50px",
                fontWeight: 500,
              }}
            >
              Please select Type from above dropdown
            </div>
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            padding: "15px",
            justifyContent: "space-between",
          }}
        >
          <Button
            component="label"
            sx={{
              textTransform: "capitalize",
              fontWeight: "500",
              display: "flex",
            }}
            startIcon={<VisibilityOutlinedIcon />}
            onClick={() => {
              setShowPreviewPopup(!showPreviewPopup);
              setIscomingFromNextButton(false);
            }}
          >
            Preview Form
          </Button>

          {showPreviewPopup && (
            <PreviewFormPopup
              rightSectionFields={rightSectionFields}
              showPreviewPopup={showPreviewPopup}
              onClose={() => setShowPreviewPopup(false)}
              handleUpdateForm={handleUpdateForm}
              isComingFromNextButton={isComingFromNextButton}
              setIscomingFromNextButton={setIscomingFromNextButton}
              updatedButtonName={updatedButtonName}
              UpdateWebformLoading={UpdateWebformLoading}
            />
          )}

          {showButtonRenamePopup && (
            <ButtonRenamePopup
              rightSectionFields={rightSectionFields}
              onClose={() => setShowButtonRenamePopup(false)}
              UpdateWebform={UpdateWebform}
              showButtonRenamePopup={showButtonRenamePopup}
              setUpdatedButtonName={setUpdatedButtonName}
              updatedButtonName={updatedButtonName}
            />
          )}
          <Box sx={{ display: "flex", gap: "12px" }}>
            <Button
              variant="outlined"
              sx={{
                textTransform: "capitalize",
                borderRadius: "4px",
                border: "1px solid #BFBFBF",
                background: "#FFF",
                color: "#BFBFBF",
                "&:hover": {
                  border: "1px solid #BFBFBF",
                  background: "#FFF",
                },
              }}
              onClick={() => setShowCancelPopup(!showCancelPopup)}
            >
              Cancel
            </Button>
            {showCancelPopup && (
              <CancelFormPopup
                onClose={() => setShowCancelPopup(false)}
                showCancelPopup={showCancelPopup}
                getFormByInstituteData={getFormByInstituteData}
                UpdateWebform={UpdateWebform}
                rightSectionFields={rightSectionFields}
                DeleteWebform={DeleteWebform}
              />
            )}
            <Button
              onClick={() => {
                setShowPreviewPopup(!showPreviewPopup);
                setIscomingFromNextButton(true);
              }}
              variant="contained"
              sx={{ textTransform: "capitalize" }}
              disabled={
                getFormByInstituteData?.formName === undefined ||
                formType === ""
              }
            >
              Next
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
export default WebFormBuilding;
