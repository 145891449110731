import { styled } from "@mui/material/styles";
import {
  Button,
  Container,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Modal,
  RadioGroup,
  Switch,
  SwitchProps,
  Typography,
  CircularProgress,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import RichTextEditor from "../../../../../components/RichTextEditor/RichTextEditor";
import CategoryManager from "../components/Manager/CategoryManager";
import styles from "../components/Manegecourses.module.css";
import TagManager from "../components/Manager/TagManager";
import { CreateCourseDTO } from "../service/types";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { reset as resetFileUploadState } from "../../../Main/components/FileUpload/service/fileUploadSlice";
import NewFormTextInput from "../../../../../components/FormTextInput/NewFormTextInput";
import { ErrorType } from "../../../../../components/FormTextInput/types";
import DropZoneCom from "../../../../../components/DragCrop/DropZoneCom";
import CancelModal from "../../../../../components/CancelModal/CancelModal";
import { removeNewTag, resetSuccessCreateTag } from "../service/tagSlice";
import { useAuthUser } from "react-auth-kit";
import DeactivationModal from "../../../../../components/DeactivationModal/DeactivationModal";
import { GetInstituteByIdRequest } from "../../InstituteProfile/service/institute.request";
import { resetSuccessCreateCategory } from "../service/categorySlice";
import Radio from "@mui/material/Radio";
import courseDefault from "../../../../../assets/images/courseDefault.png";
import { setToastNotification } from "../../../../../components/ToastNotification/service/toastNotificationSlice";
import EditPopup from "../../ManageBatch/components/EditPopup/EditPopup";
import SwitchInput from "../../../../../components/Switch/SwitchInput";
import courseThumbnail from "../../../../../assets/images/course-upload-thumbnail.svg";
import CancelBtn from "../../../../../components/Buttons/CancelBtn";
import SaveBtn from "../../../../../components/Buttons/SaveBtn";
import CancelPopup from "../../../../../components/CommonPopups/CancelPopup";
import { resetCourseStatus } from "../service/courseSlice";
import CourseTypeManager from "../components/Manager/CourseTypeManager";
import CourseLevelManager from "../components/Manager/CourseLevelManager";

// const IOSSwitch = styled((props: SwitchProps) => (
//   <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
// ))(({ theme }) => ({
//   width: 42,
//   height: 26,
//   padding: 0,
//   "& .MuiSwitch-switchBase": {
//     padding: 0,
//     margin: 2,
//     transitionDuration: "300ms",
//     "&.Mui-checked": {
//       transform: "translateX(16px)",
//       color: "#fff",
//       "& + .MuiSwitch-track": {
//         backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#23A67B",
//         opacity: 1,
//         border: 0,
//       },
//       "&.Mui-disabled + .MuiSwitch-track": {
//         opacity: 0.5,
//       },
//     },
//     "&.Mui-focusVisible .MuiSwitch-thumb": {
//       color: "#33cf4d",
//       border: "6px solid #fff",
//     },
//     "&.Mui-disabled .MuiSwitch-thumb": {
//       color:
//         theme.palette.mode === "light"
//           ? theme.palette.grey[100]
//           : theme.palette.grey[600],
//     },
//     "&.Mui-disabled + .MuiSwitch-track": {
//       opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
//     },
//   },
//   "& .MuiSwitch-thumb": {
//     boxSizing: "border-box",
//     width: 22,
//     height: 22,
//   },
//   "& .MuiSwitch-track": {
//     borderRadius: 26 / 2,
//     backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
//     opacity: 1,
//     transition: theme.transitions.create(["background-color"], {
//       duration: 500,
//     }),
//   },
// }));

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: {
    xs: "95%",
    sm: "50%",
    md: 490,
    lg: 490,
    xl: 490,
  },
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  borderRadius: "5px",
  p: 3,
};
export interface ChapterDTO {
  chapterName: string;
  chapterDetails: string;
}

let notifyText = "";

export default function CourseEditor(props: any) {
  const {
    error_updateCourse,
    success_createCategory,
    error_createCategory,
    success_createTag,
    error_createTag,
    CreateCourse,
    UpdateCourse,
    CourseConfig,
    CreateCategory,
    CreateTag,
    courseCategories,
    courseTagList,
    courseType,
    courseLevel,
    edit,
    add,
    GetCourseById,
    courseById,
    UpdateInstituteInLocal,
    setIsActive,
    CourseImageUpload,
    fileUploadSuccess,
    success,
    error,
    add_category_id,
    newAddedSelectedTag,
    loading_createCourse,
    loading_updateCourse,
    success_updateCourse,
  } = props;
  const auth = useAuthUser();
  const authUser = auth();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const imageUrl = useSelector(
    (state: any) => state.fileUpload.imageUrl && state.fileUpload.imageUrl
  );
  const activeInstituteData = authUser?.institute[0]?.instituteDetails;

  const activeInstituteForCategory = JSON.parse(
    localStorage.getItem("ACTIVE_INSTITUTE")!
  );

  const activeUserForCategory = authUser;

  const [courseDetails, setCourseDetails] = React.useState<CreateCourseDTO>({
    courseName: "",
    courseDescription: "",
    courseTags: [],
    instituteId: "",
    instituteName: "",
    coursePrice: "",
    courseCategory: {
      courseCategoryName: "",
      courseCategoryId: "",
    },
    courseType: [],
    courseLevel: {
      courseLevelName: "",
      id: "",
    },
    courseSyllabus: [],
    isFirstCourse: false,
    status: "ACTIVE",
    isCertificateProvided: false,
    courseImage: "",
    createdById: add ? authUser?.institute?.[0]?.id : "",
    createdByName: add ? authUser?.firstName + " " + authUser?.lastName : "",
    updatedById: !add ? authUser?.institute?.[0]?.id : "",
    updatedByName: !add ? authUser?.firstName + " " + authUser?.lastName : "",
    paymentType: "",
  });

  const [imageDetails, setImageDetails] = useState<any>({
    fileBase64String: "",
    fileName: "",
    fileType: "COURSE_IMAGE",
    instituteId: "",
    courseId: "",
  });

  const [oldData, setOldData] = useState<any>({
    courseName: "",
    courseDescription: "",
    courseTags: [],
    instituteId: "",
    instituteName: "",
    coursePrice: "",
    courseCategory: {
      courseCategoryName: "",
      courseCategoryId: "",
    },
    courseType: [],
    courseLevel: {
      courseLevelName: "",
      id: "",
    },
    courseSyllabus: [],
    isFirstCourse: false,
    status: "ACTIVE",
    isCertificateProvided: false,
    courseImage: "",
    paymentType: "",
  });

  const [showDeactivationModal, setShowDeactivationModal] =
    useState<boolean>(false);
  const [showEditPopup, setShowEditPopup] = useState<boolean>(false);
  const [validationSuccessData, setValidationSuccessData] = useState({});
  const [isCheckedNotification, setIsCheckedNotification] = useState(false);

  const [showErrMsz, setShowErrMsz] = React.useState<boolean>(true);
  const [errorType, setErrorType] = React.useState<ErrorType>("client");

  const [image, setImage] = React.useState<any>(courseThumbnail);

  const [errorMsg, setErrorMsg] = useState<string>("");
  const [errorMsgLevel, setErrorMsgLevel] = useState<string>("");

  const [displayCategoryManager, setDisplayCategoryManager] =
    useState<boolean>(false);
  const [validStateCategory, setValidStateCategory] =
    React.useState<boolean>(true);

  const [validStateTag, setValidStateTag] = React.useState<boolean>(true);

  const [validStateCourseName, setValidStateCourseName] =
    React.useState<boolean>(true);

  const setValidateStatusCourseName = React.useCallback((val: boolean) => {
    setValidStateCourseName(val);
  }, []);

  const [validStateCoursePrice, setValidStateCoursePrice] =
    React.useState<boolean>(true);

  const setValidateStatusCoursePrice = React.useCallback((val: boolean) => {
    setValidStateCoursePrice(val);
  }, []);
  const [chapterNameError, setChapterNameError] = useState<boolean>(false);
  const [
    validatingFirstTimeBranchCreation,
    setValidatingFirstTimeBranchCreation,
  ] = useState<any>();
  const [validPaymentType, setValidPaymentType] =
    React.useState<boolean>(false);

  const [buttonStatus, setButtonStatus] = useState(
    courseDetails.status === "ACTIVE" ? true : false
  );

  const [open, setOpen] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const handleClose = () => setOpen(false);
  const handleModalClose = () => setOpenModal(false);

  const handleChange = React.useCallback(
    (event: any) => {
      setShowErrMsz(true);
      setErrorType("client");
      setCourseDetails({
        ...courseDetails,
        [event.target.id]: event.target.value.trimStart(),
      });
    },
    [courseDetails]
  );

  const handleImageUpload = React.useCallback(
    async (cropImage: any, image: any) => {
      let image_Details = {
        fileBase64String: cropImage,
        fileName: image.name,
        fileType: "COURSE_IMAGE",
        instituteId: imageDetails?.instituteId,
        courseId: imageDetails?.courseId,
      };
      if (cropImage && image) {
        CourseImageUpload(image_Details);
      }
    },
    [setImage, imageDetails, CourseImageUpload]
  );

  //Category
  const handleSetCategory = React.useCallback(
    (courseCategory: any) => {
      setValidStateCategory(true);
      if (typeof courseCategory === "string") {
        setCourseDetails({
          ...courseDetails,
          courseCategory: {
            ...courseDetails.courseCategory,
            courseCategoryName: courseCategory,
          },
        });
      } else {
        setCourseDetails({
          ...courseDetails,
          courseCategory: {
            ...courseDetails.courseCategory,
            courseCategoryId: courseCategory?.id,
            courseCategoryName: courseCategory?.courseCategoryName,
          },
        });
      }
    },
    [courseDetails]
  );

  const handleRemoveCategory = React.useCallback(
    (courseCategory: any) => {
      setCourseDetails({
        ...courseDetails,
        courseCategory: {
          courseCategoryName: "",
          courseCategoryId: "",
        },
      });
    },
    [courseDetails]
  );

  const handleCreateCategory = React.useCallback(
    (courseCategory: string) => {
      CreateCategory({
        courseCategoryName: courseCategory,
        parentCategoryId: "",
        instituteId:
          activeInstituteForCategory.id && activeInstituteForCategory.id,
        instituteName:
          activeInstituteForCategory.instituteName &&
          activeInstituteForCategory.instituteName,
        approverId: "",
        approverName: "",
        addedById:
          activeUserForCategory?.userId && activeUserForCategory?.userId,
        addedByName:
          activeUserForCategory?.firstName &&
          activeUserForCategory?.firstName +
            " " +
            activeUserForCategory?.lastName,
      });
    },
    [CreateCategory, activeInstituteForCategory, activeUserForCategory]
  );

  //Course type
  const handleTypeChange = React.useCallback(
    (courseType: any) => {
      setErrorMsg("");
      setCourseDetails({
        ...courseDetails,
        courseType: courseType,
      });
    },
    [courseDetails, setCourseDetails]
  );

  //Course Level
  const handleLevelChange = React.useCallback(
    (courseLevelName: any, courseId: string) => {
      setErrorMsgLevel("");
      setCourseDetails({
        ...courseDetails,
        courseLevel: {
          ...courseDetails.courseLevel,
          id: courseId,
          courseLevelName: courseLevelName,
        },
      });
    },
    [courseDetails, setCourseDetails]
  );

  //Status and certification

  const handleStatusChange = (event: any) => {
    if (event.target.checked === false) {
      setShowDeactivationModal(true);
    } else if (event.target.checked === true) {
      setButtonStatus(event.target.checked);
      setCourseDetails({
        ...courseDetails,
        status: "ACTIVE",
      });
    }
  };

  const handlePopupYesButton = (event: any) => {
    setButtonStatus(false);
    setCourseDetails({
      ...courseDetails,
      status: "INACTIVE",
    });
  };

  const handlePopupNoButton = (event: any) => {
    setShowDeactivationModal(false);
  };

  const handleCertificateChange = React.useCallback(
    (event: any) => {
      setCourseDetails({
        ...courseDetails,
        [event.target.id]: event.target.checked,
      });
    },
    [courseDetails]
  );

  //Tags
  const handleTagsChange = React.useCallback(
    (courseTags: any) => {
      const updatedArray = newAddedSelectedTag?.filter((obj2: any) => {
        return courseTags
          .filter((obj: any) => typeof obj === "object")
          ?.some((obj1: any) => obj1.id === obj2.id);
      });
      dispatch(removeNewTag(updatedArray));

      setValidStateTag(true);
      setCourseDetails({
        ...courseDetails,
        courseTags: courseTags.filter((obj: any) => typeof obj === "object"),
      });
    },
    [courseDetails]
  );

  const handleCreateTag = React.useCallback(
    (courseTag: string) => {
      const array = courseTag.split(",");
      array.map(async (obj: any) => {
        await CreateTag({
          tagName: obj,
          instituteId:
            activeInstituteForCategory.id && activeInstituteForCategory.id,
          instituteName:
            activeInstituteForCategory.instituteName &&
            activeInstituteForCategory.instituteName,
          approverId: "",
          approverName: "",
          addedById:
            activeUserForCategory?.userId && activeUserForCategory?.userId,
          addedByName:
            activeUserForCategory?.firstName &&
            activeUserForCategory?.firstName +
              " " +
              activeUserForCategory?.lastName,
        });
      });
    },
    [CreateTag, activeInstituteForCategory, activeUserForCategory]
  );

  //Course description
  const handleCourseDescriptionChange = React.useCallback(
    (event: string, editorRef: any) => {
      const updatedDescription = event;
      setCourseDetails({
        ...courseDetails,
        courseDescription: updatedDescription,
      });
    },
    [courseDetails]
  );

  //Syllabus
  const handleUpdateSyllabus = React.useCallback(
    (courseSyllabus: []) => {
      setCourseDetails({
        ...courseDetails,
        courseSyllabus: courseSyllabus,
      });
    },
    [courseDetails]
  );

  const handleAddChapter = React.useCallback(() => {
    const newelement = {
      chapterName: "Chapter " + (courseDetails.courseSyllabus.length + 1),
      chapterDetails: "",
    };
    setCourseDetails({
      ...courseDetails,
      courseSyllabus: [...courseDetails.courseSyllabus, newelement],
    });
  }, [courseDetails]);

  const handlePaymentType = React.useCallback(
    (event: any) => {
      setCourseDetails({
        ...courseDetails,
        paymentType: event.target.value,
      });
    },
    [courseDetails]
  );

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    try {
      !courseDetails.courseName && setValidStateCourseName(false);
      courseDetails.courseType.length === 0
        ? setErrorMsg("select atleast one")
        : setErrorMsg("");
      courseDetails.courseLevel.id === ""
        ? setErrorMsgLevel("select atleast one")
        : setErrorMsgLevel("");
      (courseDetails?.courseCategory?.courseCategoryId
        ? courseDetails?.courseCategory?.courseCategoryId
        : courseDetails?.courseCategory?.id) === undefined ||
      (courseDetails?.courseCategory?.courseCategoryId
        ? courseDetails?.courseCategory?.courseCategoryId
        : courseDetails?.courseCategory?.id) === ""
        ? setValidStateCategory(false)
        : setValidStateCategory(true);

      const userFromLocalStorage = authUser?.institute[0]?.instituteDetails;

      const newArray: any = courseDetails;
      newArray.instituteId =
        userFromLocalStorage.id && userFromLocalStorage?.id;
      newArray.instituteName =
        userFromLocalStorage.id && userFromLocalStorage?.instituteName;
      newArray.isFirstCourse =
        validatingFirstTimeBranchCreation.courseCreated === false
          ? true
          : false;

      newArray.courseCategory = {
        courseCategoryName: courseDetails?.courseCategory.courseCategoryName,
        courseCategoryId:
          courseDetails?.courseCategory?.courseCategoryId ||
          courseDetails?.courseCategory?.id,
      };

      validStateCourseName &&
        courseDetails.courseName !== "" &&
        courseDetails.courseType.length > 0 &&
        courseDetails.courseLevel.id !== "" &&
        ((courseDetails.courseCategory.courseCategoryId
          ? courseDetails.courseCategory.courseCategoryId
          : courseDetails.courseCategory.id) !== undefined ||
          (courseDetails?.courseCategory?.courseCategoryId
            ? courseDetails?.courseCategory?.courseCategoryId
            : courseDetails?.courseCategory?.id === "")) &&
        (add
          ? CreateCourse(newArray)
          : handleUpdatePopupOpen({
              ...newArray,
              updatedById: authUser?.institute[0]?.id,
              updatedByName: authUser?.firstName + " " + authUser?.lastName,
            }));
    } catch (error) {}
  };

  const handleEditPopupSaveButton = React.useCallback(() => {
    try {
      !courseDetails.courseName && setValidStateCourseName(false);
      // !courseDetails.coursePrice && setValidStateCoursePrice(false);
      courseDetails.courseType.length === 0
        ? setErrorMsg("select atleast one")
        : setErrorMsg("");
      courseDetails.courseLevel.id === ""
        ? setErrorMsgLevel("select atleast one")
        : setErrorMsgLevel("");
      (courseDetails?.courseCategory?.courseCategoryId
        ? courseDetails?.courseCategory?.courseCategoryId
        : courseDetails?.courseCategory?.id) === undefined ||
      (courseDetails?.courseCategory?.courseCategoryId
        ? courseDetails?.courseCategory?.courseCategoryId
        : courseDetails?.courseCategory?.id) === ""
        ? setValidStateCategory(false)
        : setValidStateCategory(true);
      // courseDetails.courseTags.length === 0
      //   ? setValidStateTag(false)
      //   : setValidStateTag(true);
      // courseDetails.paymentType === ""
      //   ? setValidPaymentType(true)
      //   : setValidPaymentType(false);
      const userFromLocalStorage = authUser?.institute[0]?.instituteDetails;

      const newArray: any = courseDetails;
      newArray.coursePrice = parseInt(courseDetails.coursePrice);
      newArray.instituteId =
        userFromLocalStorage.id && userFromLocalStorage?.id;
      newArray.instituteName =
        userFromLocalStorage.id && userFromLocalStorage?.instituteName;
      newArray.isFirstCourse =
        validatingFirstTimeBranchCreation.courseCreated === false
          ? true
          : false;

      newArray.courseCategory = {
        courseCategoryName: courseDetails?.courseCategory.courseCategoryName,
        courseCategoryId:
          courseDetails?.courseCategory?.courseCategoryId ||
          courseDetails?.courseCategory?.id,
      };

      let courseNames = newArray?.courseSyllabus?.map((d: any) => {
        return d?.chapterName?.length > 0;
      });

      if (newArray?.courseSyllabus?.length === 0) {
        validStateCourseName &&
          courseDetails.courseName !== "" &&
          // validStateCoursePrice &&
          // courseDetails.coursePrice !== "" &&
          courseDetails.courseType.length > 0 &&
          courseDetails.courseLevel.id !== "" &&
          ((courseDetails.courseCategory.courseCategoryId
            ? courseDetails.courseCategory.courseCategoryId
            : courseDetails.courseCategory.id) !== undefined ||
            (courseDetails?.courseCategory?.courseCategoryId
              ? courseDetails?.courseCategory?.courseCategoryId
              : courseDetails?.courseCategory?.id === "")) &&
          // courseDetails.courseTags.length > 0 &&
          // courseDetails.paymentType !== "" &&
          (add
            ? CreateCourse(newArray)
            : handleUpdatePopupOpen({
                ...newArray,
                updatedById: authUser?.institute[0]?.id,
                updatedByName: authUser?.firstName + " " + authUser?.lastName,
              }));
      } else if (newArray?.courseSyllabus && !courseNames?.includes(false)) {
        setChapterNameError(false);
        validStateCourseName &&
          courseDetails.courseName !== "" &&
          // validStateCoursePrice &&
          // courseDetails.coursePrice !== "" &&
          courseDetails.courseType.length > 0 &&
          courseDetails.courseLevel.id !== "" &&
          ((courseDetails.courseCategory.courseCategoryId
            ? courseDetails.courseCategory.courseCategoryId
            : courseDetails.courseCategory.id) !== undefined ||
            (courseDetails?.courseCategory?.courseCategoryId
              ? courseDetails?.courseCategory?.courseCategoryId
              : courseDetails?.courseCategory?.id === "")) &&
          // courseDetails.courseTags.length > 0 &&
          // courseDetails.paymentType !== "" &&
          (add
            ? CreateCourse(newArray)
            : handleUpdatePopupOpen({
                ...newArray,
                updatedById: authUser?.institute[0]?.id,
                updatedByName: authUser?.firstName + " " + authUser?.lastName,
              }));
      } else {
        setChapterNameError(true);
        handleCourseNameError();
      }
      // Compare old data with new data
      // let changes = [];
      let changes: string[] = [];

      if (
        !areObjectsEqual(
          courseById.courseSyllabus,
          courseDetails.courseSyllabus
        )
      ) {
        changes.push("Syllabus");
      } else {
        // Remove "Syllabus" from the changes array
        changes = changes.filter((change) => change !== "Syllabus");
      }

      if (changes.length === 1) {
        notifyText = `There is a change in ${changes[0]}.`;
      } else {
        notifyText = "";
      }
    } catch (error) {}
  }, [courseDetails, CreateCourse, add, UpdateCourse]);

  const handleUpdateCourse = () => {
    UpdateCourse({
      ...validationSuccessData,
      courseSyllabusChanged: isCheckedNotification,
    });
  };

  const handleNotificationCheckboxChange = (event: any) => {
    setIsCheckedNotification(event.target.checked);
  };

  const handleUpdatePopupOpen = (event: any) => {
    setShowEditPopup(true);
    setValidationSuccessData(event);
  };

  const handleEditPopupCancelButton = (event: any) => {
    setShowEditPopup(false);
  };

  const [courseOptionList, setCourseOptionList] =
    React.useState<any>(courseCategories);

  const handleCourseNameError = () => {
    dispatch(
      setToastNotification({
        message: String("Please add the Course Name"),
        type: "error",
        snackOpen: true,
      })
    );
  };

  const handleConfirmation = () => {
    setCourseDetails({
      ...courseDetails,
      status: "INACTIVE",
    });
  };

  const reset = () => {
    if (!add) {
      setCourseDetails(courseById);
    } else {
      setCourseDetails({
        courseName: "",
        courseDescription: "",
        courseTags: [],
        instituteId: "",
        instituteName: "",
        coursePrice: "",
        courseCategory: {
          courseCategoryName: "",
          courseCategoryId: "",
        },
        courseType: [],
        courseLevel: {
          courseLevelName: "",
          id: "",
        },
        courseSyllabus: [],
        isFirstCourse: false,
        status: "ACTIVE",
        isCertificateProvided: false,
        courseImage: "",
        createdById: "",
        createdByName: "",
        updatedById: "",
        updatedByName: "",
        paymentType: "",
      });
    }
  };

  const noButtonClick = () => {
    setOpenModal(false);
  };

  const yesButtonClick = () => {
    reset();
    setOpenModal(false);
    const pathname = window.location.pathname;
    const courseId = pathname.substring(pathname.lastIndexOf("/") + 1);
    add
      ? navigate(`/app/manage/Courses`)
      : navigate(`/app/manage/Courses/details/${courseId}`);
  };

  const areObjectsEqual = (obj1: any, obj2: any) => {
    const str1 = JSON.stringify(obj1);
    const str2 = JSON.stringify(obj2);

    return str1 === str2;
  };

  const cancelButtonClick = () => {
    const isSame = areObjectsEqual(courseDetails, oldData);
    if (isSame) {
      const pathname = window.location.pathname;
      const courseId = pathname.substring(pathname.lastIndexOf("/") + 1);
      add
        ? navigate(`/app/manage/Courses`)
        : navigate(`/app/manage/Courses/details/${courseId}`);
    } else {
      setOpenModal(true);
    }
  };

  React.useEffect(() => {
    const userFromLocalStorage = authUser?.institute[0]?.instituteDetails;

    GetInstituteByIdRequest(userFromLocalStorage?.id)
      ?.then((res: any) => {
        setValidatingFirstTimeBranchCreation(res?.data?.data);
      })
      .catch((err: any) => {});
  }, []);

  useEffect(() => {
    CourseConfig();
  }, [CourseConfig]);

  useEffect(() => {
    if (edit) {
      const pathname = window.location.pathname;
      const courseId = pathname.substring(pathname.lastIndexOf("/") + 1);
      GetCourseById(courseId);
    }
  }, [edit, GetCourseById]);
  useEffect(() => {
    if (courseCategories) {
      const data = courseCategories;
      if (
        !courseCategories?.find(
          (category: any) =>
            category.courseCategoryName ===
            courseDetails.courseCategory.courseCategoryName
        )
      ) {
        setCourseOptionList([...data, courseDetails.courseCategory]);
      }
      setTimeout(() => {
        setDisplayCategoryManager(true);
      }, 500);
    }
  }, [edit, courseCategories]);

  useEffect(() => {
    if (courseCategories) {
      const data = courseCategories;
      if (
        !courseCategories?.find(
          (category: any) =>
            category.courseCategoryName ===
            courseDetails.courseCategory.courseCategoryName
        )
      ) {
        setCourseOptionList([...data, courseDetails.courseCategory]);
      }
      setTimeout(() => {
        setDisplayCategoryManager(true);
      }, 500);
    }
  }, [edit, courseCategories]);

  useEffect(() => {
    const userFromLocalStorage = authUser?.institute[0]?.instituteDetails;

    if (userFromLocalStorage) {
      setCourseDetails({
        ...courseDetails,
        instituteId: userFromLocalStorage.id && userFromLocalStorage.id,
        instituteName:
          userFromLocalStorage.instituteName &&
          userFromLocalStorage.instituteName,
        isFirstCourse:
          validatingFirstTimeBranchCreation &&
          validatingFirstTimeBranchCreation?.courseCreated === false
            ? true
            : false,
      });
      setImageDetails({
        ...imageDetails,
        instituteId: userFromLocalStorage.id && userFromLocalStorage.id,
      });
    }
  }, [setCourseDetails, setImageDetails, validatingFirstTimeBranchCreation]);

  useEffect(() => {
    if (edit && courseById) {
      setCourseDetails(courseById);
      setOldData(courseById);
      setButtonStatus(courseById.status === "ACTIVE" ? true : false);
    }
  }, [edit, courseById]);

  useEffect(() => {
    if (success !== true && success !== false) {
      const userFromLocalStorage = JSON.parse(
        localStorage.getItem("ACTIVE_INSTITUTE")!
      );
      if (userFromLocalStorage) {
        UpdateInstituteInLocal(userFromLocalStorage && userFromLocalStorage.id);
      }
      dispatch(resetFileUploadState());

      if (
        validatingFirstTimeBranchCreation.courseCreated === false &&
        validatingFirstTimeBranchCreation.branchCreated === false
      ) {
        navigate("/app/manage/CreateBranch");
      } else if (
        validatingFirstTimeBranchCreation.courseCreated === false &&
        validatingFirstTimeBranchCreation.branchCreated === true
      ) {
        navigate(`/app/manage/createBatch`, {
          state: {
            menuItemNumber: 4,
          },
        });
        localStorage.setItem("ShowFullSection", JSON.stringify(true));
        // navigate("/app/manage/CreateBatch", {
        //   state: {
        //     menuItemNumber: 4,
        //   },
        // });
      } else {
        navigate("/app/manage/Courses");
      }
    }
  }, [success, courseDetails, navigate]);

  useEffect(() => {
    if (error_createCategory) {
      setCourseDetails({
        ...courseDetails,
        courseCategory: {
          ...courseDetails.courseCategory,
          courseCategoryName: "",
          courseCategoryId: "",
        },
      });

      dispatch(resetSuccessCreateCategory());
    }
  }, [error_createCategory]);
  useEffect(() => {
    if (success_createCategory !== true && success_createCategory !== false) {
      CourseConfig();
      setCourseDetails({
        ...courseDetails,
        courseCategory: {
          ...courseDetails.courseCategory,
          courseCategoryId: add_category_id,
        },
      });

      dispatch(resetSuccessCreateCategory());
    }
  }, [success_createCategory, CourseConfig]);

  useEffect(() => {
    if (success_createTag) {
      CourseConfig();

      dispatch(resetSuccessCreateTag());
    }
  }, [success_createTag, CourseConfig]);
  useEffect(() => {
    setCourseDetails({
      ...courseDetails,
      courseTags: courseDetails.courseTags.concat(
        newAddedSelectedTag.filter(
          (item: any) => courseDetails.courseTags.indexOf(item) < 0
        )
      ),
    });
  }, [success_createTag, newAddedSelectedTag]);

  useEffect(() => {
    setCourseDetails({
      ...courseDetails,
      courseTags: courseDetails.courseTags.concat(
        newAddedSelectedTag?.filter(
          (item: any) => courseDetails.courseTags.indexOf(item) < 0
        )
      ),
    });
  }, [success_createTag, newAddedSelectedTag]);

  useEffect(() => {
    if (fileUploadSuccess) {
      if (imageUrl.fileType === "COURSE_IMAGE") {
        setCourseDetails({
          ...courseDetails,
          courseImage: imageUrl.location,
        });
      }
    }
  }, [fileUploadSuccess]);

  React.useEffect(() => {
    if (success_updateCourse !== true && success_updateCourse !== false) {
      navigate(`/app/manage/Courses/details/${courseById?.id}`);
      dispatch(resetCourseStatus());
    }
  }, [success_updateCourse, courseById]);

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (!add && courseDetails !== courseById) {
        event.preventDefault();
        event.returnValue = null;
        setOpen(true);
      }
    };

    const element = document.getElementById("Courses");
    if (element) {
      element.scrollIntoView({ behavior: "auto" });
    }

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    if (window.location.href.includes("CreateCourse")) {
      setIsActive(2);
    }
  }, [setIsActive]);

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Container
      // sx={{
      //   overflowY: "auto",
      //   maxHeight: "100%",
      // }}
      className={styles.pageWrapper}
    >
      <div className={styles.pageHead} id="Courses">
        <div className={styles.pageHeadTitle}>
          {add ? "Add " : "Edit "}
          Course Basic Details
        </div>
      </div>
      <div className={styles.pageChildWrap}>
        <div>
          <Grid
            container
            spacing={{ lg: 2, md: 2, xs: 1, sm: 1 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
            marginTop={"unset !important"}
          >
            <Grid item xs={7}>
              <Grid
                item
                xs={10}
                sx={{
                  py: {
                    lg: "12px",
                    md: "12px",
                    sm: "6px",
                  },
                }}
              >
                <NewFormTextInput
                  value={edit && courseDetails.courseName}
                  validate={true}
                  setValidStatus={setValidateStatusCourseName}
                  error={!validStateCourseName}
                  showErrorMessage={showErrMsz}
                  errorType={errorType}
                  id="courseName"
                  label="Course Name"
                  name="Course"
                  variant="standard"
                  fullWidth
                  onChange={handleChange}
                  inputProps={{
                    maxLength: 100,
                  }}
                  require={true}
                />
              </Grid>

              <Grid
                xs={10}
                sx={{
                  py: {
                    lg: "12px",
                    md: "12px",
                    sm: "6px",
                  },
                }}
              >
                <CategoryManager
                  createCategory={handleCreateCategory}
                  categoriesList={courseCategories}
                  handleSetCategory={handleSetCategory}
                  courseCategory={
                    courseDetails.courseCategory.courseCategoryName
                  }
                  handleRemoveCategory={handleRemoveCategory}
                  validStateCategory={validStateCategory}
                  courseDetail={courseDetails.courseCategory}
                />
              </Grid>

              {courseType && (
                <Grid
                  sx={{
                    py: {
                      lg: "12px",
                      md: "12px",
                      sm: "6px",
                    },
                  }}
                >
                  {/* <CourseTypeManager
                    handleTypeChange={handleTypeChange}
                    errorMsg={errorMsg}
                    courseType={courseType?.filter(
                      (item: any) =>
                        item.courseTypeName?.toLowerCase() === "classroom"
                    )}
                    selectedType={edit && courseDetails.courseType}
                  /> */}
                </Grid>
              )}

              {courseLevel && (
                <Grid
                  sx={{
                    py: {
                      lg: "12px",
                      md: "12px",
                      sm: "6px",
                    },
                  }}
                >
                  <CourseLevelManager
                    handleLevelChange={handleLevelChange}
                    courseLevel={courseLevel}
                    errorMsg={errorMsgLevel}
                    selectedLevel={edit && courseDetails.courseLevel}
                  />
                </Grid>
              )}
            </Grid>

            <Grid
              item
              xs={5}
              sx={{
                my: {
                  lg: "12px",
                  md: "12px",
                  sm: "6px",
                },
              }}
            >
              <Box>
                <div className={styles.courseThumbnailLabel}>
                  Course Thumbnail
                </div>
                <DropZoneCom
                  image={
                    <img
                      src={(edit ? courseDetails.courseImage : image) || image}
                      alt="Course Image"
                      style={{
                        width: "301px",
                        height: "197px",
                        cursor: "pointer",
                        borderRadius: "2PX",
                      }}
                    />
                  }
                  setImage={setImage}
                  handleImageUpload={handleImageUpload}
                  editImage={courseDetails.courseImage}
                />
              </Box>
            </Grid>
          </Grid>
        </div>
        {courseDetails && (
          <Grid
            xs={10}
            sx={{
              py: {
                lg: "12px",
                md: "12px",
                sm: "6px",
              },
            }}
          >
            <TagManager
              CreateTag={handleCreateTag}
              handleTagsChange={handleTagsChange}
              selectedTags={courseDetails.courseTags}
              tagsList={courseTagList}
              validStateTag={validStateTag}
            />
          </Grid>
        )}
        <div className={styles.courseSwitchWrap}>
          <div className={styles.courseSwitchLabel}>Certificate Provided</div>
          <SwitchInput
            switchStatus={courseDetails?.isCertificateProvided}
            handleSwitch={handleCertificateChange}
            id={"isCertificateProvided"}
          />
        </div>
        {activeInstituteData?.isProfileCompleted &&
          !add &&
          courseById?.status !== "DRAFT" && (
            <div className={styles.courseSwitchWrap}>
              <div className={styles.courseSwitchLabel}>Active </div>
              <SwitchInput
                handleSwitch={handleStatusChange}
                id={"status"}
                switchStatus={buttonStatus}
              />
              {showDeactivationModal && (
                <DeactivationModal
                  type={"course"}
                  handlePopupYesButton={handlePopupYesButton}
                  handlePopupNoButton={handlePopupNoButton}
                />
              )}
            </div>
          )}
        {openModal && (
          <CancelPopup
            open={openModal}
            onClose={handleModalClose}
            noBtnClick={noButtonClick}
            yesBtnClick={yesButtonClick}
          />
        )}
        {showEditPopup && (
          <EditPopup
            type={"course"}
            onClose={handleEditPopupCancelButton}
            onConfirm={handleUpdateCourse}
            showEditPopup={showEditPopup}
            notifyText={notifyText}
            handleNotificationCheckboxChange={handleNotificationCheckboxChange}
            isCheckedNotification={isCheckedNotification}
            loader={loading_updateCourse}
          />
        )}

        <Box
          sx={{
            marginBottom: "30px",
            marginTop: "30px",
            display: "flex",
            gap: "8px",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          {validatingFirstTimeBranchCreation?.isProfileCompleted && (
            <CancelBtn onClick={() => cancelButtonClick()} />
          )}

          <SaveBtn
            onClick={handleSubmit}
            disabled={loading_createCourse || loading_updateCourse}
            loader={
              loading_createCourse || (!showEditPopup && loading_updateCourse)
            }
          />
        </Box>
      </div>
      {/* 
      <Grid
        item
        xs={7}
        sx={{
          py: {
            lg: "12px",
            md: "12px",
            sm: "6px",
          },
        }}
      >
        <NewFormTextInput
          value={courseDetails?.coursePrice ? courseDetails?.coursePrice : ""}
          require={true}
          type="text"
          id="coursePrice"
          label="Price"
          name="Price"
          variant="standard"
          fullWidth
          validate={true}
          onChange={(event: any) => {
            setCourseDetails({
              ...courseDetails,
              [event.target.id]: event.target.value.replace(/\D/, ""),
            });
          }}
          inputProps={{
            maxLength: 10,
          }}
          setValidStatus={setValidateStatusCoursePrice}
          error={!validStateCoursePrice}
          showErrorMessage={showErrMsz}
          errorType={errorType}
        />
      </Grid>

      <FormControl>
        <RadioGroup
          row
          aria-label="payment-type"
          name="payment-type"
          value={courseDetails.paymentType}
          onChange={handlePaymentType}
        >
          <FormControlLabel
            value="ONE_TIME"
            control={<Radio size="small" />}
            label={<span className={styles.radioLabel}>Regular</span>}
          />
          <FormControlLabel
            value="INSTALLMENT"
            control={<Radio size="small" />}
            label={
              <span className={styles.radioLabel}>Installments (EMI)</span>
            }
          />
          <FormControlLabel
            value="RECURRING"
            control={<Radio size="small" />}
            label={
              <span className={styles.radioLabel}>
                Recurring{" "}
                <span className={styles.radioLabelBracket}>
                  (Monthly/Quarterly/Half Yearly/Annually)
                </span>
              </span>
            }
          />
        </RadioGroup>
      </FormControl>
      {validPaymentType === true && (
        <FormHelperText error={true}>
          Select atleast one payment type.
        </FormHelperText>
      )} */}

      {/* <FormControlLabel
              style={{
                justifyContent: "flex-end",
                margin: "16px 0px 0px 0px",
                color: "#3F3E43",
                fontSize: "14px",
                fontWeight: "500",
                width: "100%",
                gap: 20,
              }}
              control={
                <IOSSwitch
                  sx={{ m: 1 }}
                  checked={courseDetails?.isCertificateProvided}
                  id="isCertificateProvided"
                  onChange={handleCertificateChange}
                />
              }
              label={
                <Typography variant="body2" color="inherit">
                  Certificate Provided
                </Typography>
              }
              labelPlacement="start"
            /> */}
      {/* 
            {activeInstituteData?.isProfileCompleted && !add && (
              <Box>
                <FormControl
                  fullWidth
                  variant="standard"
                  className="formControl"
                  sx={{ width: "auto" }}
                >
                  <FormControlLabel
                    style={{
                      justifyContent: "flex-end",
                      marginLeft: 0,
                      color: "#3F3E43",
                      marginTop: "0px",
                      gap: 20,
                    }}
                    control={
                      <IOSSwitch
                        sx={{ m: 1 }}
                        checked={buttonStatus}
                        id="status"
                        onChange={handleStatusChange}
                      />
                    }
                    label="Active"
                    labelPlacement="start"
                  />
                </FormControl>
                {showDeactivationModal && (
                  <DeactivationModal
                    type={"course"}
                    handlePopupYesButton={handlePopupYesButton}
                    handlePopupNoButton={handlePopupNoButton}
                  />
                )}
              </Box>
            )} */}

      {/* <Grid
        xs={10}
        sx={{
          py: {
            lg: "12px",
            md: "12px",
            sm: "6px",
          },
        }}
      >
        <div className={""}>
          <div className={""}>
            <p
              style={{
                fontSize: "12px",
                color: "#142C44",
                fontWeight: "500",
                fontFamily: "Poppins",
                display: "flex",
                margin: "0px 0px 5px",
              }}
            >
              Course Description
            </p>
          </div>

          <RichTextEditor
            defaultText={courseDetails.courseDescription}
            handleTextChange={(event: any, editorRef: any) =>
              handleCourseDescriptionChange(event, editorRef)
            }
            inputProps={{
              maxLength: 50,
            }}
          />
        </div>
      </Grid> */}

      {/* {courseDetails && (
        <Grid
          xs={10}
          sx={{
            py: {
              lg: "12px",
              md: "12px",
              sm: "6px",
            },
          }}
        >
          <CourseChapterManager
            courseSyllabus={courseDetails.courseSyllabus}
            handleAddChapter={handleAddChapter}
            handleUpdateSyllabus={handleUpdateSyllabus}
          />
        </Grid>
      )} */}
      {/* {validatingFirstTimeBranchCreation?.isProfileCompleted && (
          <Button
            variant="outlined"
            type="reset"
            sx={{
              marginRight: "8px",
              color: "#BFBFBF",
              borderColor: "#BFBFBF",
              textTransform: "capitalize",
            }}
            onClick={() => cancelButtonClick()}
          >
            Cancel
          </Button>
        )}

        {add && (
          <Button
            sx={{ textTransform: "capitalize" }}
            variant="contained"
            onClick={handleEditPopupSaveButton}
            disabled={loading_createCourse}
          >
            {loading_createCourse ? (
              <CircularProgress size={25} color={"inherit"} />
            ) : (
              "Save"
            )}
          </Button>
        )}

        {!add && (
          <Button
            sx={{ textTransform: "capitalize" }}
            variant="contained"
            onClick={handleEditPopupSaveButton}
            disabled={loading_updateCourse}
          >
            {loading_updateCourse ? (
              <CircularProgress size={25} color={"inherit"} />
            ) : (
              "Save changes"
            )}
          </Button>
        )} */}

      {/* {openModal && (
        <CancelModal
          open={openModal}
          onClose={handleModalClose}
          noButtonClick={noButtonClick}
          yesButtonClick={yesButtonClick}
        />
      )} */}

      {/* <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div>
            <div className={styles.editFormPopupCloseWrap}>
              <button
                className={styles.editFormPopupCloseBtn}
                onClick={handleClose}
              >
                <CancelIcon />
              </button>
            </div>

            <div className={styles.editFormPopupTitle}>Leave this page ?</div>
            <div className={styles.editFormPopupDesc}>
              Changes you made may not be saved.
            </div>
            <div className={styles.editFormPopupBtnsSplit}>
              <button
                className={styles.editFormPopupSaveBtn}
                onClick={() => {
                  handleEditPopupSaveButton();
                  setOpen(false);
                }}
              >
                save
              </button>
              <button
                className={styles.editFormPopupDiscardBtn}
                onClick={() => {
                  setOpen(false);
                }}
              >
                discard
              </button>
            </div>
          </div>
        </Box>
      </Modal> */}
    </Container>
  );
}
