import React from "react";
import CourseSearchPopup from "./CourseSearch/CourseSearchPopup";
import styles from "./CourseSearch/CourseSearch.module.css";
import Skeleton from "@mui/material/Skeleton";
import { useSelector } from "react-redux";

const SearchableCourseList = (props: any) => {
  const {
    courseList,
    handleSelectedCourse,
    selectedCourse,
    setSelectedCourse,
  } = props;
  const { onLeadsLoading } = useSelector((state: any) => state.nurture);

  const handleCourseSelect = React.useCallback(
    (event: any, course: any) => {
      handleSelectedCourse(course);
      const queryParams = new URLSearchParams(window.location.search);
      if (course) {
        queryParams.set("selectedCourse", course.courseId);
        const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
        window.history.pushState({}, "", newUrl);
      }
    },
    [handleSelectedCourse]
  );

  return (
    <div
      style={{
        height: "100%",
      }}
    >
      {!courseList ? (
        <>
          <div
            style={{
              padding: "10px 5px 10px 5px",
            }}
          >
            <Skeleton
              animation="wave"
              variant="text"
              width="100%"
              height={25}
              style={{
                marginBottom: "10px",
              }}
            />
            <Skeleton
              animation="wave"
              variant="rectangular"
              width="100%"
              height={38}
            />
          </div>
        </>
      ) : (
        <>
          <div
            style={{
              padding: "19px 12px 16px 12px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "10px",
                fontWeight: "600",
              }}
            >
              Courses
            </div>
            <CourseSearchPopup
              courseList={courseList}
              selectedCourse={selectedCourse}
              handleSelectedCourse={handleSelectedCourse}
              setSelectedCourse={setSelectedCourse}
            />
          </div>
        </>
      )}

      <div className={styles.searchListWrap}>
        {!courseList
          ? Array.from({ length: 5 }, (_, index) => (
              <Skeleton
                key={index}
                animation="wave"
                variant="rectangular"
                height={30}
                width="100%"
                style={{ marginBottom: "10px" }}
              />
            ))
          : courseList
              ?.filter(
                (category: any) =>
                  category.courseDetails && category.courseDetails.length > 0
              )
              ?.map((category: any) => (
                <div key={category.id}>
                  <h2 className={styles.searchListCategoryName}>
                    {category?.courseCategoryName}
                  </h2>
                  {category?.courseDetails?.map((course: any) => (
                    <button
                      key={course.courseId}
                      className={
                        selectedCourse === course
                          ? styles.searchListCourseNameSelected
                          : styles.searchListCourseName
                      }
                      onClick={(event) => handleCourseSelect(event, course)}
                      disabled={onLeadsLoading}
                      style={{ cursor: onLeadsLoading ? "auto" : "pointer" }}
                    >
                      <div>{course?.courseName}</div>
                      <div
                        className={styles.searchListCourseLeadCount}
                        style={{
                          color:
                            selectedCourse === course ? "#ffffff" : "inherit",
                        }}
                      >
                        {course?.leadcount}
                      </div>
                    </button>
                  ))}
                </div>
              ))}
      </div>
    </div>
  );
};
export default SearchableCourseList;
