import {
  Autocomplete,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  Switch,
  Modal,
  SwitchProps,
  TextField,
  FormControlLabel,
  Container,
} from "@mui/material";
import { Popover, List, ListItem, ListItemText, Checkbox } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { Box } from "@mui/system";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider/LocalizationProvider";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "../components/Managebatches.module.css";
import { styled } from "@mui/material/styles";
import { useSelector } from "react-redux";
import moment from "moment";
import NewFormTextInput from "../../../../../components/FormTextInput/NewFormTextInput";
import { ErrorType } from "../../../../../components/FormTextInput/types";
import cross from "./cross.svg";
import dayjs from "dayjs";
import { useAuthUser } from "react-auth-kit";
import DeactivationModal from "../../../../../components/DeactivationModal/DeactivationModal";
import Tooltip from "@mui/material/Tooltip";
import { GetInstituteByIdRequest } from "../../InstituteProfile/service/institute.request";
import HolidaysModel from "./HolidyasModel";
import { CircularProgress } from "@mui/material";

import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import CalenderPage from "./Calender/CalenderPage";
import {
  convertToAdjustedTimestamp,
  timeSecondsConverter,
  timeZoneConverter,
} from "../../../../../components/Formatter/Formatter";
import BatchSkeleton from "../../../../../components/Skeletons/BatchSkeleton";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import RemoveSchedulerModal from "./RemoveSchedulerModal";
import EditPopup from "../components/EditPopup/EditPopup";
import { useDispatch } from "react-redux";
import { getInstituteById } from "../../InstituteProfile/service/instituteSlice";
import { getStaffList } from "../../ManageStaff/service/Staffslice";
import { checkSubDomainAccess } from "../../../../../components/RolePermissionAccess/RolePermissionAccess";
import { getCombinedAssignees } from "../../../../../components/CommonFunctions/CommonFunctions";
import WeekDuration from "../components/Timing/components/WeekDuration";
import BatchTypeManager from "../components/Manager/BatchTypeManager";

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#23A67B",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

function capitalize(str: any) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

interface Session {
  sessionName: string;
  startTime: number;
  endTime: number;
}

interface BatchSchedule {
  day: string;
  batchSessions: Session[];
}

interface ExemptedDates {
  exemptedDate: number;
  status: boolean;
  description: string;
}

interface CustomDays {
  day: string;
  batchSessions: Session[];
  date: number;
}

interface BatchDetailsType {
  instituteName: string;
  instituteId: string;
  batchName: string;
  courseName: string;
  courseId: string;
  branchName: string;
  branchId: string;
  startDate: any;
  endDate: any;
  startTime: number;
  endTime: number;
  minimumSeats: string;
  maximumSeats: string;
  batchType: any[];
  batchSchedule: BatchSchedule[];
  status: string;
  isFirstBatch: boolean;
  batchId: string;
  batchPrice: number;
  coursePrice: number;
  discountMetric: string;
  discount: number;
  studentCount: number;
  createdById: string;
  createdByName: string;
  updatedById: string;
  updatedByName: string;
  assignees: Assignee[];
  exemptedDates: ExemptedDates[];
  customDays: CustomDays[];
  isFixed: boolean;
  occurencessSessionDeletion: any;
}
interface Assignee {
  assignedToId: string;
  assignedToName: string;
}

let notifyText = "";

const areObjectsEqual = (oldData: any, newData: any) => {
  const str1 = JSON.stringify(oldData);
  const str2 = JSON.stringify(newData);
  return str1 === str2;
};

const areArraysOfObjectsEqual = (arr1: any, arr2: any) => {
  if (arr1.length !== arr2.length) {
    return false;
  }

  for (let i = 0; i < arr1.length; i++) {
    const obj1 = JSON.stringify(arr1[i]);
    const obj2 = JSON.stringify(arr2[i]);

    if (obj1 !== obj2) {
      return false;
    }
  }

  return true;
};

export default function BatchEditor(props: any) {
  const {
    GetBranchList,
    GetCourseList,
    add,
    CreateBatch,
    UpdateBatch,
    GetBatchById,
    batchById,
    UpdateInstituteInLocal,
    setIsActive,
    BatchConfig,
    batchCreateSuccess,
    batchCreateLoading,
    batchUpdateLoading,
    batchUpdateSuccess,
    batchByIdLoading,
    duplicate,
  } = props;
  const dispatch = useDispatch();
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  const location = useLocation();
  const auth = useAuthUser();
  const authUser = auth();
  const assignees = useSelector((state: any) => state.staff.staffList);
  const [showDeactivationModal, setShowDeactivationModal] = useState(false);
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [validationSuccessData, setValidationSuccessData] = useState({});
  const [isCheckedNotification, setIsCheckedNotification] = useState(false);

  const branches = useSelector((state: any) => state.branch.branchList);
  const activeCourses = useSelector((state: any) => state.courses.courses);
  const courses =
    activeCourses &&
    activeCourses?.filter((item: any) => item?.status === "ACTIVE");
  const batchTypeList = useSelector(
    (state: any) => state.courseConfig.courseConfigs.courseType
  );
  const userFromLocalStorage = authUser?.institute[0]?.instituteDetails;
  const [batchDetails, setBatchDetails] = React.useState<BatchDetailsType>({
    instituteName: "",
    instituteId: "",
    batchName: "",
    courseName: "",
    courseId: "",
    branchName: "",
    branchId: "",
    startDate: `${moment().unix()}`,
    endDate: `${moment().unix()}`,
    startTime: 0,
    endTime: 0,
    minimumSeats: "",
    maximumSeats: "",
    batchType: [],
    batchSchedule: [
      {
        day: "SUN",
        batchSessions: [{ sessionName: "", startTime: 0, endTime: 0 }],
      },
      {
        day: "MON",
        batchSessions: [{ sessionName: "", startTime: 0, endTime: 0 }],
      },
      {
        day: "TUE",
        batchSessions: [{ sessionName: "", startTime: 0, endTime: 0 }],
      },
      {
        day: "WED",
        batchSessions: [{ sessionName: "", startTime: 0, endTime: 0 }],
      },
      {
        day: "THU",
        batchSessions: [{ sessionName: "", startTime: 0, endTime: 0 }],
      },
      {
        day: "FRI",
        batchSessions: [{ sessionName: "", startTime: 0, endTime: 0 }],
      },
      {
        day: "SAT",
        batchSessions: [{ sessionName: "", startTime: 0, endTime: 0 }],
      },
    ],
    status: "ACTIVE",
    isFirstBatch: false,
    batchId: "",
    batchPrice: 0,
    coursePrice: 0,
    discountMetric: "",
    discount: 0,
    // batchStatus: "",
    studentCount: 0,
    createdById: "",
    createdByName: "",
    updatedById: "",
    updatedByName: "",
    assignees: [],
    exemptedDates: [],
    customDays: [],
    isFixed: true,
    occurencessSessionDeletion: [],
  });
  const [formDisabled, setFormDisabled] = React.useState<boolean>(false);
  const [openLogoutModal, setOpenLogoutModal] = React.useState(false);
  const handleLogoutModalOpen = () => setOpenLogoutModal(true);
  const handleLogoutModalClose = () => setOpenLogoutModal(false);

  const navigate = useNavigate();
  const [batchStatus, setBatchStatus] = React.useState<boolean>(
    batchDetails.status === "ACTIVE" ? true : false
  );
  const [validStateCourse, setValidStateCourse] = React.useState<boolean>(true);
  const [validStateBranch, setValidStateBranch] = React.useState<boolean>(true);
  const [validAssignee, setValidAssignee] = React.useState<boolean>(false);

  const [percentDiscount, setPercentDiscount] = useState(
    batchDetails.discountMetric === "PERCENTAGE" ? batchDetails.discount : 0
  );
  const [amountDiscount, setAmountDiscount] = useState(
    batchDetails.discountMetric === "AMOUNT" ? batchDetails.discount : 0
  );
  const [discountMetric, setDiscountMetric] = useState("PERCENTAGE");

  const [showErrMsz, setShowErrMsz] = React.useState<boolean>(true);
  const [errorType, setErrorType] = React.useState<ErrorType>("client");
  const [errorMszs, setErrorMszs] = useState<string>("");
  const [errorStartDateMszs, setErrorStartDateMszs] = useState<boolean>(false);
  const [errorEndDateMsg, setErrorEndDateMsg] = useState<boolean>(false);
  const [errorMaxSeatMsg, setErrorMaxSeatMsg] = useState<boolean>(false);
  const [errorMinSeatMsg, setErrorMinSeatMsg] = useState<boolean>(false);
  const [errorStartDayMsg, setErrorStartDayMsg] = useState<boolean>(false);
  const [errorTimeMsg, setErrorTimeMsg] = useState<boolean>(false);
  const [errorEndDatePastMsg, setErrorEndDatePastMsg] =
    useState<boolean>(false);
  const [isDateChanged, setIsDateChanged] = useState(false);

  const [scheduleType, setScheduleType] = useState<string>("week");
  const [validStateMinimumSeats, setValidStateMinimumSeats] =
    React.useState<boolean>(true);
  const [validStateMaximumSeats, setValidStateMaximumSeats] =
    React.useState<boolean>(true);
  const [validStateBatchPrice, setValidStateBatchPrice] =
    React.useState<boolean>(true);
  const [validStateCoursePrice, setValidStateCoursePrice] =
    React.useState<boolean>(true);
  const [validStateBatchName, setValidStateBatchName] =
    React.useState<boolean>(true);
  const [validStatePercentDiscount, setValidStatePercentDiscount] =
    React.useState<boolean>(true);
  const [validStateAmountDiscount, setValidStateAmountDiscount] =
    React.useState<boolean>(true);
  const [openHolidaysModel, setOpenHolidaysModel] = useState(false);
  const [batchView, SetBatchView] = useState("List View");
  const [selecteRemoveSchedulerType, setSelectedRemoveSchedulerType] =
    useState("");
  const [branchAnchorEl, setBranchAnchorEl] = useState(null);
  const openBranch = Boolean(branchAnchorEl);

  const [errorEndDayMsg, setErrorEndDayMsg] = useState<boolean>(false);

  const setValidateStatusMinimumSeats = React.useCallback((val: boolean) => {
    setValidStateMinimumSeats(val);
  }, []);
  const setValidateStatusMaximumSeats = React.useCallback((val: boolean) => {
    setValidStateMaximumSeats(val);
  }, []);
  const setValidateStatusBatchPrice = React.useCallback((val: boolean) => {
    setValidStateBatchPrice(val);
  }, []);
  const setValidateStatusCoursePrice = React.useCallback((val: boolean) => {
    setValidStateCoursePrice(val);
  }, []);
  const setValidateStatusBatchName = React.useCallback((val: boolean) => {
    setValidStateBatchName(val);
  }, []);
  const setValidateStatusPercentDiscount = React.useCallback((val: boolean) => {
    setValidStatePercentDiscount(val);
  }, []);
  const setValidateStatusAmountDiscount = React.useCallback((val: boolean) => {
    setValidStateAmountDiscount(val);
  }, []);

  const handleScheduleType = React.useCallback((event: any) => {
    setScheduleType(event.target.value);
  }, []);

  const handleScheduleChange = React.useCallback(
    (batchSchedule: []) => {
      setBatchDetails({
        ...batchDetails,
        batchSchedule: batchSchedule,
      });
    },
    [batchDetails]
  );

  const [
    validatingFirstTimeBranchCreation,
    setValidatingFirstTimeBranchCreation,
  ] = useState<any>();

  React.useEffect(() => {
    const userFromLocalStorage = authUser?.institute[0]?.instituteDetails;
    dispatch<any>(getStaffList(userFromLocalStorage?.id));
    GetInstituteByIdRequest(userFromLocalStorage?.id)
      ?.then((res: any) => {
        setValidatingFirstTimeBranchCreation(res?.data?.data);
      })
      .catch((err) => {});
  }, []);

  const handleChange = React.useCallback(
    (event: any) => {
      setShowErrMsz(true);
      if (errorType === "server") {
        setValidStateBatchName(true);
      }
      setErrorType("client");
      setBatchDetails({
        ...batchDetails,
        [event.target.id]: event.target.value.trimStart(),
      });
    },
    [batchDetails]
  );

  const updateBatchPrice = (discount: number) => {
    const newBatchPrice = batchDetails.coursePrice - discount;
    setBatchDetails({
      ...batchDetails,
      discount,
      batchPrice: newBatchPrice,
    });
  };

  const handlePercentDiscountChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const inputDiscount = Number(event.target.value);
      if (inputDiscount <= 100) {
        setPercentDiscount(inputDiscount);
        setAmountDiscount(0);
        setDiscountMetric("PERCENTAGE");
        setBatchDetails({
          ...batchDetails,
          discount: inputDiscount,
          discountMetric: "PERCENTAGE",
          batchPrice: (batchDetails.coursePrice * (100 - inputDiscount)) / 100,
        });
      } else {
        setPercentDiscount(100);
        setAmountDiscount(0);
        setDiscountMetric("PERCENTAGE");
        setBatchDetails({
          ...batchDetails,
          discount: 100,
          discountMetric: "PERCENTAGE",
          batchPrice: 0,
        });
      }
    },
    [batchDetails]
  );

  const handleAmountDiscountChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const inputDiscount = Number(event.target.value);
      const maxDiscount = batchDetails.coursePrice;
      if (inputDiscount <= maxDiscount) {
        setAmountDiscount(inputDiscount);
        setPercentDiscount(0);
        setDiscountMetric("AMOUNT");
        setBatchDetails({
          ...batchDetails,
          discount: inputDiscount,
          discountMetric: "AMOUNT",
          batchPrice: batchDetails.coursePrice - inputDiscount,
        });
      } else {
        setAmountDiscount(maxDiscount);
        setPercentDiscount(0);
        setDiscountMetric("AMOUNT");
        setBatchDetails({
          ...batchDetails,
          discount: maxDiscount,
          discountMetric: "AMOUNT",
          batchPrice: 0,
        });
      }
    },
    [batchDetails]
  );

  const handleChangeSeats = React.useCallback(
    (event: any) => {
      const seat: any = parseInt(event.target.value);
      if (event.target.id === "maximumSeats") {
        batchDetails.minimumSeats >= seat
          ? setErrorMaxSeatMsg(true)
          : setErrorMaxSeatMsg(false);
      }
      if (event.target.id === "minimumSeats") {
        seat >= batchDetails.maximumSeats
          ? setErrorMaxSeatMsg(true)
          : setErrorMaxSeatMsg(false);
      }
      if (seat > 0 && seat <= 10000) {
        setBatchDetails({
          ...batchDetails,
          [event.target.id]: seat,
        });
      } else {
        setBatchDetails({
          ...batchDetails,
          [event.target.id]: "",
        });
      }
    },
    [batchDetails]
  );

  const [selectedDate, setSelectedDate] = useState<number | null>(null);

  const [startDate, setStartDate] = React.useState<number | null>(
    moment().unix()
  );

  const [endDate, setEndDate] = React.useState<number | null>(moment().unix());

  const getNumberOfDays = () => {
    const start = moment.unix(parseFloat(batchDetails.startDate));
    const end = moment.unix(parseFloat(batchDetails.endDate));
    const diff = moment.duration(end.diff(start));
    const days = Math.floor(diff.asDays());
    return days;
  };

  const handleDateChange = (date: any, id: string) => {
    if (date !== null) {
      const dateUnix = date.unix();
      setSelectedDate(dateUnix);
      if (id === "startDate") {
        const newStartDate: any = new Date(date).setHours(0, 0, 0, 0);
        setStartDate(date.unix());
        setBatchDetails({
          ...batchDetails,
          startDate: timeZoneConverter(newStartDate),
        });
        setIsDateChanged(true);
      } else if (id === "endDate") {
        const newEndDate: any = new Date(date).setHours(23, 59, 59, 0);
        setEndDate(date.unix());

        setBatchDetails({
          ...batchDetails,
          endDate: timeZoneConverter(newEndDate),
        });
        setIsDateChanged(true);
      }
    }
  };

  const handleTypeChange = React.useCallback(
    (batchType: any) => {
      setBatchDetails({
        ...batchDetails,
        batchType: batchType,
      });
    },
    [setBatchDetails, batchDetails]
  );

  const selectedStartDate =
    batchDetails.startDate && new Date(parseInt(batchDetails.startDate) * 1000); // Convert Unix timestamp to Date object
  const selectedEndDate =
    batchDetails.endDate && new Date(parseInt(batchDetails.endDate) * 1000);

  function transformBatchSessions(batchSchedule: any) {
    const transformedSessions = batchSchedule.map((day: any) => ({
      day: day.day,
      batchSessions: day.batchSessions
        .filter((session: any) => session.startTime > 0 && session.endTime > 0)
        .map((session: any) => ({
          startTime: timeSecondsConverter(session.startTime, "startTime"),
          endTime: timeSecondsConverter(session.endTime, "endTime"),
          sessionName: "",
        })),
    }));

    return transformedSessions;
  }

  const onBranchChange = React.useCallback(
    (event: object, value: any | null) => {
      if (value !== null) {
        setBatchDetails({
          ...batchDetails,
          branchId: value.id,
          branchName: value.branchName,
          assignees: [],
        });
        setValidStateBranch(true);
        setSelectedAssinees([]);
      } else {
        setBatchDetails({
          ...batchDetails,
          branchId: "",
          branchName: "",
          assignees: [],
        });
        setValidStateBranch(false);
        setSelectedAssinees([]);
      }
    },
    [batchDetails]
  );

  const onCourseChange = React.useCallback(
    (event: object, value: any | null) => {
      if (value) {
        const batchType = value.courseType.map((type: any) => ({
          batchType: {
            courseTypeName: type?.courseType?.courseTypeName,
            id: type.courseType.id,
          },
        }));
        setBatchDetails({
          ...batchDetails,
          courseId: value.id,
          courseName: value.courseName,
          coursePrice: value.coursePrice,
          batchPrice: value.coursePrice,
          batchType: batchType,
        });
      }
      setValidStateCourse(true);
      setValidStateBatchPrice(true);
      setValidStateCoursePrice(true);
    },
    [batchDetails]
  );

  //Status
  const handleCheckChange = (event: any) => {
    if (event.target.checked === false) {
      setShowDeactivationModal(true);
    } else if (event.target.checked === true) {
      setBatchStatus(event.target.checked);
      setBatchDetails({
        ...batchDetails,
        status: "ACTIVE",
      });
    }
  };

  const handlePopupYesButton = (event: any) => {
    setBatchStatus(false);
    setBatchDetails({
      ...batchDetails,
      status: "INACTIVE",
    });
  };

  const handlePopupNoButton = (event: any) => {
    setShowDeactivationModal(false);
  };

  const handleEditPopupSaveButton = (event: any) => {
    try {
      // selectedStartDate > selectedEndDate
      //   ? setErrorEndDatePastMsg(true)
      //   : setErrorEndDatePastMsg(false);
      batchDetails.startDate > batchDetails.endDate
        ? setErrorEndDatePastMsg(true)
        : setErrorEndDatePastMsg(false);

      batchDetails.batchType.length === 0
        ? setErrorMszs("select atleast one")
        : setErrorMszs("");

      batchDetails.startDate === ""
        ? setErrorStartDateMszs(true)
        : setErrorStartDateMszs(false);
      !batchDetails.courseId && setValidStateCourse(false);
      !batchDetails.branchId && setValidStateBranch(false);
      batchDetails.endDate === ""
        ? setErrorEndDateMsg(true)
        : setErrorEndDateMsg(false);
      batchDetails.minimumSeats === ""
        ? setErrorMinSeatMsg(true)
        : setErrorMinSeatMsg(false);

      batchDetails.minimumSeats >= batchDetails.maximumSeats
        ? setErrorMaxSeatMsg(true)
        : setErrorMaxSeatMsg(false);

      !batchDetails.batchName && setValidStateBatchName(false);
      !batchDetails.minimumSeats && setValidStateMinimumSeats(false);
      !batchDetails.maximumSeats && setValidStateMaximumSeats(false);
      !batchDetails.batchPrice && setValidStateBatchPrice(false);
      !batchDetails.coursePrice && setValidStateCoursePrice(false);
      !batchDetails.coursePrice && setValidStatePercentDiscount(false);
      !batchDetails.coursePrice && setValidStateAmountDiscount(false);
      let daysName: any =
        batchDetails.startDate?.toString()?.length === 13
          ? convertToAdjustedTimestamp(batchDetails.startDate) / 1000
          : batchDetails?.startDate;

      const startDay = moment
        .unix(parseInt(daysName))
        .format("ddd")
        .toUpperCase();

      const matchingDay = batchDetails.batchSchedule.find(
        (day) => day.day === startDay
      );

      let errorStartDate: boolean = false;
      let errorTime: boolean = false;
      if (
        matchingDay &&
        (matchingDay.batchSessions[0].startTime === 0 ||
          matchingDay.batchSessions[0].endTime === 0)
      ) {
        setErrorStartDayMsg(true);
        errorStartDate = true;
      } else {
        setErrorStartDayMsg(false);
        errorStartDate = false;
      }

      let endDaysName: any =
        batchDetails.endDate?.toString()?.length === 13
          ? convertToAdjustedTimestamp(batchDetails.endDate) / 1000
          : batchDetails?.endDate;

      const endDay = moment
        .unix(parseInt(endDaysName))
        .format("ddd")
        .toUpperCase();

      const matchingEndDay = batchDetails.batchSchedule.find(
        (day) => day.day === endDay
      );

      let errorEndDate: boolean = false;
      if (
        matchingEndDay &&
        (matchingEndDay.batchSessions[0].startTime === 0 ||
          matchingEndDay.batchSessions[0].endTime === 0)
      ) {
        setErrorEndDayMsg(true);
        errorEndDate = true;
      } else {
        setErrorEndDayMsg(false);

        errorEndDate = false;
      }

      const array = batchDetails.batchSchedule.filter((obj: any) => {
        return obj.batchSessions[0].startTime !== 0;
      });

      const containsSameStartAndEndTime = (array: any) => {
        for (const obj of array) {
          if (obj.batchSessions && Array.isArray(obj.batchSessions)) {
            for (const session of obj.batchSessions) {
              if (session.startTime === session.endTime) {
                return true;
              }
            }
          }
        }
        return false;
      };

      const hasMatchingTime = array && containsSameStartAndEndTime(array);

      if (hasMatchingTime === true) {
        setErrorTimeMsg(true);
        errorTime = true;
      } else {
        setErrorTimeMsg(false);
        errorTime = false;
      }

      const updatedBatchSchedule = batchDetails.batchSchedule.map(
        (schedule) => {
          if (schedule.batchSessions[0].startTime === 0) {
            return {
              ...schedule,
              batchSessions: [],
            };
          }
          return schedule;
        }
      );
      if (batchDetails?.assignees?.length > 0) {
        setValidAssignee(false);
      } else {
        setValidAssignee(true);
      }
      const newArray: any = batchDetails;
      newArray.batchPrice =
        typeof batchDetails.batchPrice === "string"
          ? parseInt(batchDetails.batchPrice)
          : batchDetails.batchPrice;
      newArray.endTime =
        typeof batchDetails.endTime === "string"
          ? parseInt(batchDetails.endTime)
          : batchDetails.endTime;
      newArray.startTime =
        typeof batchDetails.startTime === "string"
          ? parseInt(batchDetails.startTime)
          : batchDetails.startTime;
      newArray.endDate = parseInt(batchDetails.endDate);
      newArray.startDate = parseInt(batchDetails.startDate);
      newArray.coursePrice =
        typeof batchDetails.coursePrice === "string"
          ? parseInt(batchDetails.coursePrice)
          : batchDetails.coursePrice;
      let newstartDate: any = new Date(batchDetails?.startDate * 1000).setHours(
        0,
        0,
        0,
        0
      );
      let newEndDate: any = new Date(batchDetails?.endDate * 1000).setHours(
        23,
        59,
        59,
        0
      );

      delete newArray.batchStatus;
      validStateBatchName &&
        batchDetails.batchName &&
        batchDetails.batchType.length > 0 &&
        batchDetails.startDate <= batchDetails.endDate &&
        batchDetails.startDate !== "" &&
        batchDetails.endDate !== "" &&
        batchDetails.minimumSeats < batchDetails.maximumSeats &&
        batchDetails.courseName !== "" &&
        batchDetails.branchName !== "" &&
        batchDetails.batchPrice !== 0 &&
        !errorStartDate &&
        !errorEndDate &&
        !errorTime &&
        batchDetails?.assignees?.length > 0 &&
        (add
          ? CreateBatch({
              ...newArray,
              batchConductType: "REGULAR_BATCH",
              batchSchedule: updatedBatchSchedule,
              createDemo: false,
              demoRequest: {},
              startDate:
                batchDetails?.startDate?.toString()?.length === 10
                  ? timeZoneConverter(newstartDate)
                  : batchDetails?.startDate,
              endDate:
                batchDetails?.endDate?.toString()?.length === 10
                  ? timeZoneConverter(newEndDate)
                  : batchDetails?.endDate,
              batchScheduleChanged: false,
              isFixed:
                batchDetails?.customDays?.length > 0 ||
                batchDetails?.exemptedDates?.length > 0
                  ? false
                  : true,
            })
          : handleUpdatePopupOpen({
              ...newArray,
              batchConductType: "REGULAR_BATCH",
              batchSchedule: updatedBatchSchedule,
              createDemo: false,
              demoRequest: {},
              batchScheduleChanged: isDateChanged ? true : false,
              updatedById: authUser?.institute[0]?.id,
              updatedByName: authUser?.firstName + " " + authUser?.lastName,
              isFixed:
                batchDetails?.customDays?.length > 0 ||
                batchDetails?.exemptedDates?.length > 0
                  ? false
                  : true,
            }));

      let changes: string[] = [];

      if (!add) {
        if (!areObjectsEqual(batchById.batchType, batchDetails.batchType)) {
          changes.push("Type");
        } else {
          changes = changes.filter((change) => change !== "Type");
        }

        const isScheduleEqual = areObjectsEqual(
          batchById.batchSchedule,
          updatedBatchSchedule
        );
        const isExemptedEqual = areArraysOfObjectsEqual(
          batchById.exemptedDates,
          batchDetails.exemptedDates
        );
        const isCustomDaysEqual = areArraysOfObjectsEqual(
          batchById.customDays,
          batchDetails.customDays
        );

        if (
          !isScheduleEqual ||
          batchById.startDate !== batchDetails.startDate ||
          batchById.endDate !== batchDetails.endDate ||
          !isExemptedEqual ||
          !isCustomDaysEqual
        ) {
          changes.push("Schedule");
        } else {
          changes = changes.filter((change) => change !== "Schedule");
        }

        if (batchById.branchName !== batchDetails.branchName) {
          changes.push("Branch");
        } else {
          changes = changes.filter((change) => change !== "Branch");
        }

        if (changes.length === 1) {
          notifyText = `There is a change in ${changes[0]}.`;
        } else if (changes.length === 2) {
          notifyText = `There is a change in ${changes[0]} and ${changes[1]}.`;
        } else if (changes.length === 3) {
          notifyText = `There is a change in ${changes[0]}, ${changes[1]} and ${changes[2]}.`;
        } else {
          notifyText = "";
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdateBatch = () => {
    UpdateBatch({
      ...validationSuccessData,
      notifyStudent: isCheckedNotification,
    });
  };

  const handleNotificationCheckboxChange = (event: any) => {
    setIsCheckedNotification(event.target.checked);
  };

  const handleUpdatePopupOpen = (event: any) => {
    setValidationSuccessData(event);
    setShowEditPopup(true);
  };

  const handleEditPopupCancelButton = (event: any) => {
    setShowEditPopup(false);
  };

  useEffect(() => {
    const userFromLocalStorage = JSON.parse(
      localStorage.getItem("ACTIVE_INSTITUTE")!
    );
    const instituteId = authUser?.institute[0]?.instituteId;
    if (userFromLocalStorage) {
      GetBranchList(instituteId && instituteId);
      GetCourseList(instituteId && instituteId);
    }
  }, [GetBranchList, setBatchDetails]);

  useEffect(() => {
    const userFromLocalStorage = JSON.parse(
      localStorage.getItem("ACTIVE_INSTITUTE")!
    );
    const instituteId = authUser?.institute[0]?.instituteId;
    if (userFromLocalStorage) {
      setBatchDetails({
        ...batchDetails,
        instituteId: instituteId && instituteId,
        instituteName:
          userFromLocalStorage.instituteName &&
          userFromLocalStorage.instituteName,
        isFirstBatch:
          validatingFirstTimeBranchCreation &&
          validatingFirstTimeBranchCreation?.batchCreated === true
            ? false
            : true,
      });
    }
  }, [validatingFirstTimeBranchCreation]);

  useEffect(() => {
    if (authUser) {
      setBatchDetails({
        ...batchDetails,
        createdByName: add
          ? authUser.institute[0].firstName +
            " " +
            authUser.institute[0].lastName
          : "",
        createdById: add ? authUser.institute[0].id : "",
      });
    }
  }, []);

  const defaultBranches = branches
    ? branches.find(
        (branch: any) => branch.branchName === batchDetails.branchName
      )
    : "";

  const defaultCourses = courses
    ? courses.find(
        (course: any) => course.courseName === batchDetails.courseName
      )
    : "";

  React.useEffect(() => {
    if (!add) {
      const pathname = window.location.pathname;
      const batchId = pathname.substring(pathname.lastIndexOf("/") + 1);

      GetBatchById(batchId);
      setFormDisabled(true);
    }
  }, [add, GetBatchById]);

  React.useEffect(() => {
    if (add && duplicate) {
      const pathname = window.location.pathname;
      const batchId = pathname.substring(pathname.lastIndexOf("/") + 1);

      GetBatchById(batchId);
      setFormDisabled(true);
    }
  }, [add, duplicate, GetBatchById]);

  const checkAssigneeConditions = (assigneeIds: any, branchId: any) => {
    const combinedAssignees = getCombinedAssignees(assignees, branchId);

    const matchingAssignees = combinedAssignees?.filter((assignee: any) =>
      assigneeIds?.includes(assignee.id)
    );
    const filteredAssignees = matchingAssignees.filter(
      (assignee: any) =>
        checkSubDomainAccess("MANAGE", "BATCHES", assignee.rolePermission) ===
          true &&
        assignee.status === "ACTIVE" &&
        checkSubDomainAccess(
          "NURTURE_BOARD",
          "LEAD_CARD",
          assignee.rolePermission
        ) === true
    );
    const mappedAssignees = filteredAssignees?.map((assignee: any) => ({
      assignedToId: assignee.id,
      assignedToName: `${assignee.firstName} ${assignee.lastName}`,
    }));

    return mappedAssignees;
  };

  React.useEffect(() => {
    if (!add && batchById) {
      const { students, courseImage, ...filteredBatchById } = batchById;
      const updatedBatchSchedule = batchById.batchSchedule.map(
        (schedule: any) => {
          if (schedule.batchSessions.length === 0) {
            return {
              ...schedule,
              batchSessions: [{ sessionName: "", startTime: 0, endTime: 0 }],
            };
          } else {
            const updatedBatchSessions = schedule.batchSessions.map(
              (session: any) => ({
                ...session,
                startTime: session.startTime,
                endTime: session.endTime,
              })
            );

            return {
              ...schedule,
              batchSessions: updatedBatchSessions,
            };
          }
          // return schedule;
        }
      );

      setSelectedAssinees(
        batchById?.assignees
          ? checkAssigneeConditions(
              batchById?.assignees?.map((a: any) => a.assignedToId),
              batchById?.branchId
            )
          : []
      );

      setBatchDetails({
        ...filteredBatchById,
        batchSchedule: updatedBatchSchedule,
        assignees: batchById?.assignees
          ? checkAssigneeConditions(
              batchById?.assignees?.map((a: any) => a.assignedToId),
              batchById?.branchId
            )
          : [],
      });
      (batchById?.customDays && batchById?.customDays?.length > 0) ||
      (batchById?.exemptedDate && batchById?.exemptedDate?.length > 0)
        ? SetBatchView("Calender View")
        : SetBatchView("List View");

      setEndDate(convertToAdjustedTimestamp(batchById?.endDate) / 1000);
      setStartDate(convertToAdjustedTimestamp(batchById?.startDate) / 1000);
      setBatchStatus(batchById.status === "ACTIVE" ? true : false);
    }
  }, [add, batchById, setBatchStatus]);

  React.useEffect(() => {
    if (add && duplicate && batchById) {
      const { students, courseImage, ...filteredBatchById } = batchById;
      const updatedBatchSchedule = batchById.batchSchedule.map(
        (schedule: any) => {
          if (schedule.batchSessions.length === 0) {
            return {
              ...schedule,
              batchSessions: [{ sessionName: "", startTime: 0, endTime: 0 }],
            };
          } else {
            const updatedBatchSessions = schedule?.batchSessions?.map(
              (session: any) => ({
                ...session,
                startTime: session.startTime,
                endTime: session.endTime,
              })
            );

            return {
              ...schedule,
              batchSessions: updatedBatchSessions,
            };
          }
          // return schedule;
        }
      );

      setSelectedAssinees(
        batchById?.assignees
          ? checkAssigneeConditions(
              batchById?.assignees?.map((a: any) => a.assignedToId),
              batchById?.branchId
            )
          : []
      );
      setBatchDetails({
        ...batchDetails,
        instituteName: batchById.instituteName,
        instituteId: batchById.instituteId,
        batchName: `${batchById.batchName} (copy)`,
        courseName: batchById.courseName,
        courseId: batchById.courseId,
        branchName: batchById.branchName,
        branchId: batchById.branchId,
        startDate: batchById.startDate,
        endDate: batchById.endDate,
        startTime: batchById.startTime,
        endTime: batchById.endTime,
        minimumSeats: batchById.minimumSeats,
        maximumSeats: batchById.maximumSeats,
        batchType: batchById.batchType,
        batchSchedule: updatedBatchSchedule,
        isFirstBatch: batchById.isFirstBatch,
        batchPrice: batchById.batchPrice,
        coursePrice: batchById.coursePrice,
        discountMetric: batchById.discountMetric,
        discount: batchById.discount,
        studentCount: batchById.studentCount,
        createdById: batchById.createdById,
        createdByName: batchById.createdByName,
        updatedById: batchById.updatedById,
        updatedByName: batchById.updatedByName,
        assignees: checkAssigneeConditions(
          batchById?.assignees?.map((a: any) => a.assignedToId),
          batchById?.branchId
        ),
      });

      setEndDate(convertToAdjustedTimestamp(batchById?.endDate) / 1000);
      setStartDate(convertToAdjustedTimestamp(batchById?.startDate) / 1000);
      setBatchStatus(batchById.status === "ACTIVE" ? true : false);
    }
  }, [add, batchById, setBatchStatus, duplicate]);

  React.useEffect(() => {
    if (batchById) {
      if (batchDetails.discountMetric === "PERCENTAGE") {
        setPercentDiscount(batchDetails.discount);
        setAmountDiscount(0);
      } else if (batchDetails.discountMetric === "AMOUNT") {
        setAmountDiscount(batchDetails.discount);
        setPercentDiscount(0);
      }
    }
  }, [batchDetails]);

  useEffect(() => {
    BatchConfig();
  }, [BatchConfig]);

  React.useEffect(() => {
    if (batchUpdateSuccess !== true && batchUpdateSuccess !== false) {
      const userFromLocalStorage = authUser?.institute[0]?.instituteDetails;
      if (userFromLocalStorage) {
        UpdateInstituteInLocal(userFromLocalStorage && userFromLocalStorage.id);
      }
      if (batchDetails.isFirstBatch === true) {
        navigate("/app/home");
      } else {
        if (!add && batchById) {
          if (batchDetails.status === "ACTIVE") {
            navigate(`/app/manage/Batches?batchId=${batchById.id}`);
          } else {
            navigate(`/app/manage/Batches`);
          }
        } else {
          navigate(`/app/manage/Batches`);
        }
      }
      localStorage.setItem("ShowFullSection", JSON.stringify(false));
    }
  }, [batchUpdateSuccess, navigate, batchById]);

  React.useEffect(() => {
    if (batchCreateSuccess !== true && batchCreateSuccess !== false) {
      const userFromLocalStorage = authUser?.institute[0]?.instituteDetails;
      if (userFromLocalStorage) {
        UpdateInstituteInLocal(userFromLocalStorage && userFromLocalStorage.id);
      }

      if (batchDetails.isFirstBatch === true) {
        navigate("/app/home");
        dispatch<any>(getInstituteById(userFromLocalStorage.id));
      } else {
        if (!add && batchById) {
          navigate(`/app/manage/Batches?batchId=${batchById.id}`);
        } else {
          navigate(`/app/manage/Batches`);
        }
      }
      localStorage.setItem("ShowFullSection", JSON.stringify(false));
    }
  }, [batchCreateSuccess, navigate, batchById]);

  useEffect(() => {
    if (window.location.href.includes("CreateBatch")) {
      setIsActive(4);
    }
  }, [setIsActive, setFormDisabled, setBatchDetails]);

  useEffect(() => {
    const element = document.getElementById("Batch");
    if (element) {
      element.scrollIntoView({ behavior: "auto" });
    }
  }, []);

  function handleGoBack() {
    // window.history.back();
    navigate("/app/manage/Batches");
  }

  function handleCancel() {
    if (!add) {
      navigate(`/app/manage/Batches?batchId=${batchById.id}`);
    } else {
      navigate(`/app/manage/Batches`);
    }
  }

  const finalBatchPrice =
    (batchDetails.coursePrice * (100 - percentDiscount)) / 100 - amountDiscount;

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedAssinees, setSelectedAssinees] = useState<any>(
    batchDetails ? batchDetails?.assignees : []
  );
  const [searchText, setSearchText] = useState<string>("");

  const open = Boolean(anchorEl);

  const handleOpenPopover = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
    setSearchText("");
  };

  const handleSearch = (event: any) => {
    setSearchText(event.target.value);
  };

  const handleCheckboxChange = (employee: any) => {
    const currentAssignees = batchDetails.assignees;

    const isEmployeeAlreadyAssignee = currentAssignees?.some(
      (assignee: any) => assignee.assignedToId === employee.id
    );

    let newAssignees;

    if (isEmployeeAlreadyAssignee) {
      newAssignees = currentAssignees.filter(
        (assignee: any) => assignee.assignedToId !== employee.id
      );
    } else {
      newAssignees = [
        ...currentAssignees,
        {
          assignedToId: employee.id,
          assignedToName: `${employee.firstName} ${employee.lastName}`,
        },
      ];
    }

    if (newAssignees?.length > 0) {
      setValidAssignee(false);
    } else {
      setValidAssignee(true);
    }

    setBatchDetails({
      ...batchDetails,
      assignees: newAssignees,
    });
  };

  const handleDoneClick = () => {
    handleClosePopover();
  };

  const combinedAssignees = getCombinedAssignees(
    assignees,
    batchDetails?.branchId
  );

  const renderCourseList = () => {
    return combinedAssignees
      ?.filter((item: any) => {
        const fullName = `${item?.firstName ?? ""} ${
          item?.lastName ?? ""
        }`.toLowerCase();
        return fullName?.includes(searchText?.toLowerCase());
      })
      ?.filter(
        (emp: any) =>
          checkSubDomainAccess("MANAGE", "BATCHES", emp?.rolePermission) ===
          true
      )
      ?.map((employee: any) => (
        <ListItem
          key={employee.id}
          button
          onClick={() => handleCheckboxChange(employee)}
        >
          <ListItemText
            primary={
              <div style={{ display: "flex", alignItems: "center" }}>
                <div
                  style={{
                    width: 24,
                    height: 24,
                    backgroundColor: "#EEEEF4",
                    borderRadius: "50%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginRight: 8,
                    textTransform: "uppercase",
                  }}
                >
                  {employee?.firstName[0]}
                </div>
                <div style={{ textTransform: "capitalize" }}>{`${
                  employee?.firstName + " " + employee?.lastName
                }`}</div>
              </div>
            }
          />

          <Checkbox
            edge="start"
            checked={batchDetails?.assignees?.some(
              (assignee: any) => assignee.assignedToId === employee.id
            )}
            sx={{
              strokeWidth: 1,
              color: "#D0D5DD",
            }}
          />
        </ListItem>
      ));
  };

  const saveHolidays = () => {};

  const cancelHolidays = () => {};
  const showFullSectionFromOnboarding =
    localStorage.getItem("ShowFullSection")!;

  const handleRemoveScheudler = () => {
    if (selecteRemoveSchedulerType === "Remove only custom dates") {
      setBatchDetails({
        ...batchDetails,
        customDays: [],
        exemptedDates: [],
      });
      setSelectedRemoveSchedulerType("");
    } else if (selecteRemoveSchedulerType === "Remove whole calender") {
      const updatedBatchDetails = {
        ...batchDetails,
        batchSchedule: batchDetails.batchSchedule.map((schedule) => {
          return {
            ...schedule,
            batchSessions: schedule.batchSessions.map((session) => {
              return {
                ...session,
                startTime: 0,
                endTime: 0,
              };
            }),
          };
        }),
      };

      setBatchDetails({
        ...updatedBatchDetails,
        customDays: [],
        exemptedDates: [],
      });
      SetBatchView("List View");
      setSelectedRemoveSchedulerType("");
    }
  };

  const isWholeCalenderEmpty =
    batchDetails.batchSchedule.every((day) =>
      day.batchSessions.every(
        (session) => session.startTime === 0 && session.endTime === 0
      )
    ) &&
    batchDetails.customDays.length === 0 &&
    batchDetails.exemptedDates.length === 0;

  return (
    <>
      <Container
        sx={{
          overflowY: "auto",
          maxHeight: "100%",
        }}
      >
        <Box className={styles.batchEditorWrapper}>
          {!add && batchByIdLoading ? (
            <BatchSkeleton />
          ) : (
            <div>
              <div
                className={""}
                style={{
                  borderBottom: "1px solid #EDEDED",
                  display: "flex",
                  justifyContent: "space-between",
                  maxWidth: "800px",
                  marginRight: "20px",
                }}
                id="Batch"
              >
                {add && (
                  <p
                    style={{
                      color: "#142C44",
                      fontSize: "18px",
                      fontFamily: "Poppins",
                      fontWeight: "500",
                      margin: "10px 0px",
                    }}
                  >
                    Add Batch
                  </p>
                )}
                {!add && (
                  <p
                    style={{
                      color: "#142C44",
                      fontSize: "20px",
                      fontFamily: "Poppins",
                      fontWeight: "500",
                      margin: "10px 0px",
                    }}
                  >
                    Edit Batch
                  </p>
                )}
              </div>

              <Box sx={{ mt: 4 }}>
                <form>
                  {location &&
                    (location?.state?.showBasicInfo ||
                      location?.state?.showFullSection ||
                      JSON?.parse(showFullSectionFromOnboarding) === true) && (
                      <Grid container spacing={3} sx={{ p: "20px" }}>
                        <Grid
                          item
                          xs={6}
                          sx={{
                            border: "1px solid #E9E9E9",
                            borderRadius: "4px",
                            padding: "10px",
                          }}
                        >
                          <FormControl
                            fullWidth
                            variant="standard"
                            className="formControl"
                            sx={{
                              ".css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                                borderColor: !validStateCourse
                                  ? "rgb(244 67 54)"
                                  : "",
                              },
                              ".css-2wrs0q-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor: !validStateCourse
                                    ? "rgb(244 67 54)"
                                    : "",
                                  ":hover": {
                                    borderColor: !validStateCourse
                                      ? "rgb(244 67 54)"
                                      : "",
                                  },
                                },
                              ".MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.css-qm9mjt-MuiAutocomplete-root .MuiOutlinedInput-root":
                                {
                                  ":hover": {
                                    borderColor: !validStateCourse
                                      ? "rgb(244 67 54)"
                                      : "",
                                  },
                                },
                            }}
                            error={!validStateCourse}
                          >
                            <p
                              style={{
                                fontSize: "12px",
                                color: "#142C44",
                                fontWeight: "500",
                                fontFamily: "Poppins",
                                display: "flex",
                                margin: "0px 0px 5px",
                              }}
                            >
                              Select Course
                              <p
                                style={{ margin: "0px 4px", color: "#D32F2F" }}
                              >
                                *
                              </p>
                            </p>

                            {courses && (
                              <Autocomplete
                                disablePortal
                                id="courseId"
                                options={courses}
                                getOptionLabel={(option) =>
                                  option.courseName
                                    ? capitalize(option.courseName)
                                    : ""
                                }
                                fullWidth
                                onChange={onCourseChange}
                                value={defaultCourses || ""}
                                disableClearable={
                                  batchDetails.courseId === "" ? true : false
                                }
                                renderInput={(params) => (
                                  <TextField
                                    variant="outlined"
                                    {...params}
                                    size="small"
                                  />
                                )}
                              />
                            )}
                          </FormControl>
                          {validStateCourse === false && (
                            <FormHelperText error={true}>
                              Field required
                            </FormHelperText>
                          )}

                          <FormControl
                            fullWidth
                            variant="standard"
                            className="formControl"
                            sx={{
                              mt: "24px",
                              mb: "24px",
                              ".css-2wrs0q-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor: !validStateBranch
                                    ? "rgb(244 67 54)"
                                    : "",
                                  ":hover": {
                                    borderColor: !validStateBranch
                                      ? "rgb(244 67 54)"
                                      : "",
                                  },
                                },
                              ".MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.css-qm9mjt-MuiAutocomplete-root .MuiOutlinedInput-root":
                                {
                                  ":hover": {
                                    borderColor: !validStateBranch
                                      ? "rgb(244 67 54)"
                                      : "",
                                  },
                                },
                              ".css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                                borderColor: !validStateBranch
                                  ? "rgb(244 67 54)"
                                  : "",
                              },
                            }}
                            error={!validStateBranch}
                          >
                            <p
                              style={{
                                fontSize: "12px",
                                color: "#142C44",
                                fontWeight: "500",
                                fontFamily: "Poppins",
                                display: "flex",
                                margin: "0px 0px 5px",
                              }}
                            >
                              Branch
                              <p
                                style={{ margin: "0px 4px", color: "#D32F2F" }}
                              >
                                *
                              </p>
                            </p>

                            {branches && (
                              <Autocomplete
                                disablePortal
                                id="branchId"
                                options={branches}
                                getOptionLabel={(option: any) =>
                                  option.branchName || ""
                                }
                                fullWidth
                                onChange={onBranchChange}
                                value={defaultBranches || ""}
                                disableClearable={
                                  batchDetails.branchId === "" ? true : false
                                }
                                renderInput={(params) => (
                                  <TextField
                                    variant="outlined"
                                    {...params}
                                    size="small"
                                  />
                                )}
                              />
                            )}
                          </FormControl>
                          {validStateBranch === false && (
                            <FormHelperText error={true}>
                              Field required
                            </FormHelperText>
                          )}

                          <NewFormTextInput
                            validate={true}
                            setValidStatus={setValidateStatusBatchName}
                            label="Batch Name"
                            type="text"
                            id={"batchName"}
                            error={!validStateBatchName}
                            value={batchDetails.batchName}
                            onChange={handleChange}
                            showErrorMessage={showErrMsz}
                            errorType={errorType}
                            autoComplete="true"
                            require={true}
                            inputProps={{ maxLength: 50 }}
                          />

                          <Box sx={{ mt: "24px" }}>
                            <BatchTypeManager
                              add={add}
                              handleTypeChange={handleTypeChange}
                              errorMszs={errorMszs}
                              selectedType={batchDetails.batchType}
                              batchTypeList={batchTypeList}
                              key={batchDetails.courseId}
                            />
                          </Box>
                          {batchDetails?.assignees ? (
                            <Box sx={{ mt: "24px", mb: "24px" }}>
                              <FormControl
                                fullWidth
                                variant="standard"
                                className="formControl"
                              >
                                <p
                                  style={{
                                    fontSize: "12px",
                                    color: "#142C44",
                                    fontWeight: "500",
                                    fontFamily: "Poppins",
                                    display: "flex",
                                    margin: "0px 0px 5px",
                                  }}
                                >
                                  Assignee
                                  <p
                                    style={{
                                      margin: "0px 4px",
                                      color: "#D32F2F",
                                    }}
                                  >
                                    *
                                  </p>
                                </p>

                                <TextField
                                  onClick={(event: any) => {
                                    handleOpenPopover(event);
                                  }}
                                  value={
                                    batchDetails?.assignees &&
                                    batchDetails?.assignees?.length === 0
                                      ? ""
                                      : batchDetails?.assignees?.length > 1
                                      ? `${
                                          batchDetails?.assignees[0]
                                            ?.assignedToName
                                        }+${
                                          batchDetails?.assignees?.slice(1)
                                            ?.length
                                        }`
                                      : `${batchDetails?.assignees[0]?.assignedToName}`
                                  }
                                  variant="outlined"
                                  size="small"
                                  sx={{
                                    "& .MuiOutlinedInput-root input": {
                                      textTransform: "capitalize",
                                    },
                                  }}
                                />
                                {validAssignee === true && (
                                  <FormHelperText error={true}>
                                    Field required
                                  </FormHelperText>
                                )}
                                <Popover
                                  open={open}
                                  anchorEl={anchorEl}
                                  // onClose={handleClosePopover}
                                  anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "left",
                                  }}
                                  transformOrigin={{
                                    vertical: "top",
                                    horizontal: "left",
                                  }}
                                  sx={{ width: "100%" }}
                                  PaperProps={{
                                    sx: {
                                      boxShadow:
                                        "0px 4px 4px 0px rgba(0, 0, 0, 0.05)",
                                      borderRadius: "4PX",
                                      border: "1px solid #EEE",
                                    },
                                  }}
                                >
                                  <TextField
                                    placeholder="Search"
                                    sx={{
                                      padding: "20px 20px 0px 20px",
                                      width: "100%",
                                    }}
                                    fullWidth
                                    InputProps={{
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          <SearchIcon />
                                        </InputAdornment>
                                      ),
                                    }}
                                    onChange={handleSearch}
                                    size="small"
                                  />
                                  <div
                                    style={{
                                      height: "250px",
                                      overflowY: "scroll",
                                    }}
                                  >
                                    <List>
                                      {" "}
                                      {renderCourseList()?.length > 0 ? (
                                        renderCourseList()
                                      ) : (
                                        <div
                                          style={{
                                            fontSize: "12px",
                                            padding: "10px 16px",
                                          }}
                                        >
                                          No users found with required
                                          permissions
                                        </div>
                                      )}
                                    </List>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "flex-end",
                                      padding: "0px 20px 20px 20px",
                                      background: "white",
                                    }}
                                  >
                                    <Button
                                      variant="outlined"
                                      color="primary"
                                      onClick={() => {
                                        setAnchorEl(null);
                                        setSelectedAssinees([]);
                                        setSearchText("");
                                      }}
                                      style={{}}
                                    >
                                      Cancel
                                    </Button>
                                    <Button
                                      variant="contained"
                                      color="primary"
                                      onClick={handleDoneClick}
                                      style={{ marginLeft: "10px" }}
                                    >
                                      Done
                                    </Button>
                                  </div>
                                </Popover>
                              </FormControl>
                            </Box>
                          ) : (
                            <Box sx={{ mt: "24px", mb: "24px" }}>
                              <FormControl
                                fullWidth
                                variant="standard"
                                className="formControl"
                              >
                                <p
                                  style={{
                                    fontSize: "12px",
                                    color: "#142C44",
                                    fontWeight: "500",
                                    fontFamily: "Poppins",
                                    display: "flex",
                                    margin: "0px 0px 5px",
                                  }}
                                >
                                  Assignee
                                  <p
                                    style={{
                                      margin: "0px 4px",
                                      color: "#D32F2F",
                                    }}
                                  >
                                    *
                                  </p>
                                </p>

                                <TextField
                                  onClick={handleOpenPopover}
                                  value={
                                    batchDetails?.assignees &&
                                    batchDetails?.assignees?.length === 0
                                      ? ""
                                      : batchDetails?.assignees?.length > 1
                                      ? `${
                                          batchDetails?.assignees[0]
                                            ?.assignedToName
                                        }+${
                                          batchDetails?.assignees?.slice(1)
                                            ?.length
                                        }`
                                      : `${batchDetails?.assignees[0]?.assignedToName}`
                                  }
                                  variant="outlined"
                                  size="small"
                                  sx={{
                                    "& .MuiOutlinedInput-root input": {
                                      textTransform: "capitalize",
                                    },
                                  }}
                                />
                                {validAssignee === true && (
                                  <FormHelperText error={true}>
                                    Field required
                                  </FormHelperText>
                                )}
                                <Popover
                                  open={open}
                                  anchorEl={anchorEl}
                                  onClose={handleClosePopover}
                                  anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "left",
                                  }}
                                  transformOrigin={{
                                    vertical: "top",
                                    horizontal: "left",
                                  }}
                                  sx={{ width: "100%" }}
                                  PaperProps={{
                                    sx: {
                                      boxShadow:
                                        "0px 4px 4px 0px rgba(0, 0, 0, 0.05)",
                                      borderRadius: "4PX",
                                      border: "1px solid #EEE",
                                    },
                                  }}
                                >
                                  <TextField
                                    placeholder="Search"
                                    sx={{
                                      padding: "20px 20px 0px 20px",
                                      width: "100%",
                                    }}
                                    fullWidth
                                    InputProps={{
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          <SearchIcon />
                                        </InputAdornment>
                                      ),
                                    }}
                                    onChange={handleSearch}
                                    size="small"
                                  />
                                  <div
                                    style={{
                                      height: "250px",
                                      overflowY: "scroll",
                                    }}
                                  >
                                    <List>
                                      {renderCourseList()?.length > 0 ? (
                                        renderCourseList()
                                      ) : (
                                        <div
                                          style={{
                                            fontSize: "12px",
                                            padding: "10px 16px",
                                          }}
                                        >
                                          No users found with required
                                          permissions
                                        </div>
                                      )}
                                    </List>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "flex-end",
                                      padding: "0px 20px 20px 20px",
                                      background: "white",
                                    }}
                                  >
                                    <Button
                                      variant="outlined"
                                      color="primary"
                                      onClick={() => {
                                        setAnchorEl(null);
                                        setSelectedAssinees([]);
                                        setSearchText("");
                                      }}
                                      style={{}}
                                    >
                                      Cancel
                                    </Button>
                                    <Button
                                      variant="contained"
                                      color="primary"
                                      onClick={handleDoneClick}
                                      style={{ marginLeft: "10px" }}
                                    >
                                      Done
                                    </Button>
                                  </div>
                                </Popover>
                              </FormControl>
                            </Box>
                          )}
                        </Grid>

                        <Grid item xs={6}>
                          <Box className={styles.nameInputWrapper}>
                            <Grid
                              container
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginTop: "2px",
                              }}
                            >
                              <Grid item xs={12} lg={5.5}>
                                <Box>
                                  <NewFormTextInput
                                    validate={true}
                                    setValidStatus={
                                      setValidateStatusMinimumSeats
                                    }
                                    label="Minimum Seats"
                                    type="number"
                                    id={"minimumSeats"}
                                    error={!validStateMinimumSeats}
                                    value={batchDetails.minimumSeats}
                                    onChange={handleChangeSeats}
                                    showErrorMessage={showErrMsz}
                                    errorType={errorType}
                                    autoComplete="true"
                                    require={true}
                                    inputProps={{ min: 1, max: 10000 }}
                                    fullWidth
                                  />
                                </Box>
                              </Grid>

                              <Grid item xs={12} lg={5.5}>
                                <Box>
                                  <NewFormTextInput
                                    validate={true}
                                    setValidStatus={
                                      setValidateStatusMaximumSeats
                                    }
                                    label="Maximum Seats"
                                    type="number"
                                    id={"maximumSeats"}
                                    error={!validStateMaximumSeats}
                                    value={batchDetails.maximumSeats}
                                    onChange={handleChangeSeats}
                                    showErrorMessage={showErrMsz}
                                    errorType={errorType}
                                    autoComplete="true"
                                    require={true}
                                    inputProps={{ min: 1, max: 10000 }}
                                    fullWidth
                                  />
                                  {batchDetails.maximumSeats !== "" &&
                                  errorMaxSeatMsg ? (
                                    <div
                                      style={{
                                        color: "red",
                                        padding: "5px 0",
                                        fontSize: "14px",
                                      }}
                                    >
                                      Max Seat should be greater than Min seat
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                </Box>
                              </Grid>
                            </Grid>
                          </Box>
                          <Box
                            sx={{
                              border: "1px solid #E9E9E9",
                              borderRadius: "4px",
                              padding: "20px",
                              mt: "30px",
                            }}
                          >
                            <Box sx={{ marginBottom: "16px" }}>
                              <div
                                style={{
                                  fontSize: "12px",
                                  color: "#142C44",
                                  fontWeight: "500",
                                }}
                              >
                                Course Price:
                              </div>
                              <div>₹ {batchDetails.coursePrice}</div>
                            </Box>

                            <Grid item xs={12} lg={6.5}>
                              <div
                                style={{
                                  fontSize: "12px",
                                  color: "#142C44",
                                  fontWeight: "500",
                                  marginBottom: "5px",
                                }}
                              >
                                Discount:
                              </div>
                              <Box sx={{ display: "flex" }}>
                                <TextField
                                  type="text"
                                  placeholder="%"
                                  id={"percentDiscount"}
                                  error={!validStatePercentDiscount}
                                  value={percentDiscount}
                                  onChange={handlePercentDiscountChange}
                                  autoComplete="true"
                                  inputProps={{
                                    maxLength: 10,
                                    style: { padding: "6px 8px" },
                                  }}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        %
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    padding: "8px",
                                    color: "#A5A5A5",
                                    fontSize: "13px",
                                    fontWeight: "400",
                                  }}
                                >
                                  or
                                </div>
                                <TextField
                                  type="text"
                                  placeholder="₹"
                                  id="amountDiscount"
                                  error={!validStateAmountDiscount}
                                  value={amountDiscount}
                                  onChange={handleAmountDiscountChange}
                                  autoComplete="true"
                                  inputProps={{
                                    style: { padding: "6px 8px" },
                                  }}
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        ₹
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </Box>
                            </Grid>

                            <Box sx={{ marginTop: "16px" }}>
                              <div
                                style={{
                                  fontSize: "12px",
                                  color: "#142C44",
                                  fontWeight: "500",
                                }}
                              >
                                Final Price:
                              </div>
                              <div className={styles.batchPrice}>
                                <span className={styles.rupeesSign}>₹ </span>
                                {batchDetails.batchPrice}
                              </div>
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                    )}

                  {location &&
                    (location?.state?.showTimingInfo ||
                      location?.state?.showFullSection ||
                      JSON?.parse(showFullSectionFromOnboarding) === true) && (
                      <Grid sx={{ paddingRight: "20px" }}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <div className={styles.durationLabel}>
                            Schedule Batch
                          </div>
                          <div
                            style={{
                              fontSize: "12px",
                              color: "rgba(17, 200, 155, 1)",
                              border: "1px solid rgba(17, 200, 155, 1)",
                              padding: "5px",
                              borderRadius: "8px",
                            }}
                          >
                            {batchDetails?.isFixed === false
                              ? "Custom Schedule"
                              : "Fixed Schedule"}
                          </div>
                        </div>
                        <Box className={styles.durationWrapper}>
                          <Grid
                            container
                            sx={{
                              display: "flex",
                            }}
                            spacing={4}
                          >
                            <Grid
                              item
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                width: "100%",
                              }}
                            >
                              <div className={styles.batchDuration}>
                                Batch Duration
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {batchById?.batchStatus !== "ONGOING" && (
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      marginRight: "10px",
                                    }}
                                  >
                                    {isWholeCalenderEmpty === false &&
                                      batchDetails?.customDays &&
                                      batchDetails?.customDays?.length > 0 &&
                                      batchDetails?.exemptedDates &&
                                      batchDetails?.exemptedDates?.length >
                                        0 && (
                                        <>
                                          <div
                                            style={{
                                              marginRight: "10px",
                                              fontSize: "12px",
                                              color: "#142c44",
                                            }}
                                          >
                                            Remove Scheduler:
                                          </div>
                                          <>
                                            <TextField
                                              onClick={(e: any) => {
                                                setBranchAnchorEl(
                                                  e.currentTarget
                                                );
                                              }}
                                              value={selecteRemoveSchedulerType}
                                              variant="outlined"
                                              size="small"
                                              placeholder="Select"
                                              InputProps={{
                                                endAdornment: (
                                                  <InputAdornment position="end">
                                                    <ArrowDropDownIcon
                                                      style={{
                                                        color: "#d4d4d8",
                                                      }}
                                                    />
                                                  </InputAdornment>
                                                ),
                                              }}
                                              sx={{
                                                "& .MuiOutlinedInput-root fieldset":
                                                  {
                                                    "&:hover fieldset": {
                                                      height: "200px",
                                                      fontSize:
                                                        "12px !important",
                                                    },
                                                  },
                                                width: "200px",
                                              }}
                                            />

                                            <Popover
                                              open={openBranch}
                                              anchorEl={branchAnchorEl}
                                              onClose={() => {
                                                setBranchAnchorEl(null);
                                              }}
                                              anchorOrigin={{
                                                vertical: "bottom",
                                                horizontal: "left",
                                              }}
                                              transformOrigin={{
                                                vertical: "top",
                                                horizontal: "left",
                                              }}
                                              sx={{ width: "100%" }}
                                            >
                                              <div
                                                style={{
                                                  height: "100px",
                                                  overflowY: "scroll",
                                                }}
                                              >
                                                <List>
                                                  {[
                                                    "Remove only custom dates",
                                                    "Remove whole calender",
                                                  ]?.map((d: any) => {
                                                    return (
                                                      <ListItem
                                                        key={d}
                                                        button
                                                        onClick={() => {
                                                          setSelectedRemoveSchedulerType(
                                                            d
                                                          );
                                                          setBranchAnchorEl(
                                                            null
                                                          );
                                                        }}
                                                      >
                                                        <ListItemText
                                                          primary={
                                                            <div
                                                              style={{
                                                                display: "flex",
                                                                alignItems:
                                                                  "center",
                                                                fontSize:
                                                                  "12px",
                                                              }}
                                                            >
                                                              {`${d}`}
                                                            </div>
                                                          }
                                                        />
                                                      </ListItem>
                                                    );
                                                  })}
                                                </List>
                                              </div>
                                            </Popover>
                                          </>
                                        </>
                                      )}
                                  </div>
                                )}
                                <FormControl sx={{ minWidth: 140 }}>
                                  <Select
                                    value={batchView}
                                    onChange={(event: any) => {
                                      SetBatchView(event.target.value);
                                    }}
                                    displayEmpty
                                    inputProps={{
                                      "aria-label": "Without label",
                                    }}
                                    MenuProps={{
                                      style: { zIndex: 9999999999 },
                                      anchorOrigin: {
                                        vertical: "top",
                                        horizontal: "left",
                                      },
                                      transformOrigin: {
                                        vertical: "top",
                                        horizontal: "left",
                                      },
                                    }}
                                    sx={{
                                      color: "#3F3F46",
                                      fontSize: "12px",
                                      fontWeight: "500",
                                      padding: "8px",
                                      "& .MuiSvgIcon-root": {
                                        color: "#B1B1B1",
                                        fontWeight: "300",
                                        fontSize: "18px",
                                        marginRight: "11px",
                                      },
                                      "& .MuiSelect-select": {
                                        padding: "2px",
                                      },
                                      "& .MuiPaper-root": {
                                        top: "300px",
                                      },
                                    }}
                                  >
                                    {["List View", "Calender View"]?.map(
                                      (view: any) => (
                                        <MenuItem
                                          value={view}
                                          sx={{
                                            fontSize: "12px !important",
                                            textTransform: "capitalize",
                                          }}
                                        >
                                          {view}
                                        </MenuItem>
                                      )
                                    )}
                                  </Select>
                                </FormControl>
                              </div>
                            </Grid>
                          </Grid>
                          {batchView === "List View" ? (
                            <>
                              <Grid
                                marginTop={2}
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <div className={styles.batchDuration}>
                                  Select Date
                                </div>
                                <Grid
                                  marginTop={2}
                                  sx={{ display: "flex", alignItems: "center" }}
                                >
                                  <Grid item xs={12} lg={3} md={4}>
                                    <Box
                                      sx={{
                                        marginTop: "5px",
                                      }}
                                    >
                                      <LocalizationProvider
                                        dateAdapter={AdapterDayjs}
                                      >
                                        <DatePicker
                                          sx={{ zIndex: "99999999 !import" }}
                                          label="Start Date"
                                          format="DD MMM YYYY"
                                          // disablePast
                                          onChange={(date: any) => {
                                            handleDateChange(date, "startDate");
                                          }}
                                          value={
                                            startDate
                                              ? dayjs.unix(startDate)
                                              : dayjs()
                                          }
                                        />
                                      </LocalizationProvider>
                                    </Box>
                                    {errorStartDateMszs ? (
                                      <div
                                        style={{
                                          color: "red",
                                          padding: "5px 0",
                                          fontSize: "12px",
                                        }}
                                      >
                                        Select start date
                                      </div>
                                    ) : (
                                      <></>
                                    )}
                                  </Grid>

                                  <Grid
                                    item
                                    xs={12}
                                    lg={3}
                                    md={4}
                                    sx={{ marginLeft: "20px" }}
                                  >
                                    <Box
                                      sx={{
                                        marginTop: "5px",
                                      }}
                                    >
                                      <LocalizationProvider
                                        dateAdapter={AdapterDayjs}
                                      >
                                        <DatePicker
                                          label="End Date"
                                          format="DD MMM YYYY"
                                          value={
                                            endDate
                                              ? dayjs.unix(endDate)
                                              : dayjs()
                                          }
                                          onChange={(date: any) => {
                                            handleDateChange(date, "endDate");
                                          }}
                                        />
                                      </LocalizationProvider>
                                    </Box>

                                    {errorEndDateMsg ? (
                                      <div
                                        style={{
                                          color: "red",
                                          padding: "5px 0",
                                          fontSize: "14px",
                                        }}
                                      >
                                        Select end date
                                      </div>
                                    ) : (
                                      <></>
                                    )}
                                    {errorEndDatePastMsg ? (
                                      <div
                                        style={{
                                          color: "red",
                                          padding: "5px 0",
                                          fontSize: "14px",
                                        }}
                                      >
                                        End date should be after or same as
                                        start date
                                      </div>
                                    ) : (
                                      <></>
                                    )}
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid marginTop={2}>
                                <WeekDuration
                                  handleChange={handleChange}
                                  batchSchedule={batchDetails.batchSchedule}
                                  handleScheduleChange={handleScheduleChange}
                                  batchDetails={batchDetails}
                                  setBatchDetails={setBatchDetails}
                                  add={add}
                                  setIsDateChanged={setIsDateChanged}
                                />
                                {errorStartDayMsg && !errorTimeMsg && (
                                  <div
                                    style={{
                                      color: "red",
                                      padding: "5px 0",
                                      fontSize: "14px",
                                    }}
                                  >
                                    Oopps! Request can not be processed
                                    <br />
                                    Seems like you have not scheduled time for
                                    the selected start date.
                                  </div>
                                )}

                                {errorEndDayMsg && !errorTimeMsg && (
                                  <div
                                    style={{
                                      color: "red",
                                      padding: "5px 0",
                                      fontSize: "14px",
                                    }}
                                  >
                                    Oopps! Request can not be processed
                                    <br />
                                    Seems like you have not scheduled time for
                                    the selected end date.
                                  </div>
                                )}

                                {errorTimeMsg && (
                                  <div
                                    style={{
                                      color: "red",
                                      padding: "5px 0",
                                      fontSize: "14px",
                                    }}
                                  >
                                    Oopps! Request can not be processed
                                    <br />
                                    The start time and end time can not be the
                                    same and the end time should be greater than
                                    the start time.
                                  </div>
                                )}
                              </Grid>
                            </>
                          ) : (
                            <div>
                              <CalenderPage
                                batchDetails={batchDetails}
                                setBatchDetails={setBatchDetails}
                                add={add}
                                startingDate={startDate}
                                endingDate={endDate}
                                handleDateChange={handleDateChange}
                                errorStartDateMszs={errorStartDateMszs}
                                errorEndDatePastMsg={errorEndDatePastMsg}
                                errorEndDateMsg={errorEndDateMsg}
                                setIsDateChanged={setIsDateChanged}
                                errorStartDayMsg={errorStartDayMsg}
                                errorTimeMsg={errorTimeMsg}
                                errorEndDayMsg={errorEndDayMsg}
                                isWholeCalenderEmpty={isWholeCalenderEmpty}
                              />
                            </div>
                          )}
                        </Box>
                      </Grid>
                    )}
                  {userFromLocalStorage?.isProfileCompleted && !add && (
                    <Box>
                      {batchById?.batchStatus === "ONGOING" ||
                      batchDetails.studentCount !== 0 ? (
                        <Tooltip
                          title={
                            batchById?.batchStatus === "ONGOING"
                              ? "An Ongoing batch cannot be deactivated"
                              : "Batch cannot be deactivated with active Students in it."
                          }
                          placement="bottom"
                          arrow
                        >
                          <FormControlLabel
                            style={{
                              justifyContent: "flex-end",
                              marginLeft: 0,
                              color: "#3F3E43",
                              marginTop: "10px",
                            }}
                            control={
                              <IOSSwitch
                                sx={{ m: 1 }}
                                checked={batchStatus}
                                disabled={
                                  batchById?.batchStatus === "ONGOING" ||
                                  batchDetails.studentCount !== 0
                                }
                                id="status"
                                onChange={handleCheckChange}
                              />
                            }
                            label="Active"
                            labelPlacement="start"
                          />
                        </Tooltip>
                      ) : (
                        <FormControlLabel
                          style={{
                            justifyContent: "flex-end",
                            marginLeft: 0,
                            color: "#3F3E43",
                            marginTop: "10px",
                          }}
                          control={
                            <IOSSwitch
                              sx={{ m: 1 }}
                              checked={batchStatus}
                              disabled={false}
                              id="status"
                              onChange={handleCheckChange}
                            />
                          }
                          label="Active"
                          labelPlacement="start"
                        />
                      )}
                      {showDeactivationModal && (
                        <DeactivationModal
                          type={"batch"}
                          handlePopupYesButton={handlePopupYesButton}
                          handlePopupNoButton={handlePopupNoButton}
                        />
                      )}
                    </Box>
                  )}

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      gap: "6px",
                      marginTop: "24px",
                      marginBottom: add ? "70px" : "40px",
                      paddingRight: "20px",
                    }}
                  >
                    {validatingFirstTimeBranchCreation?.isProfileCompleted && (
                      <Button
                        sx={{ textTransform: "capitalize" }}
                        variant="outlined"
                        onClick={handleLogoutModalOpen}
                      >
                        Cancel
                      </Button>
                    )}

                    {add && (
                      <Button
                        onClick={handleEditPopupSaveButton}
                        variant="contained"
                      >
                        {batchCreateLoading ? (
                          <CircularProgress size={25} color={"inherit"} />
                        ) : (
                          "Save"
                        )}
                      </Button>
                    )}

                    {!add && isWholeCalenderEmpty === false && (
                      <Button
                        onClick={handleEditPopupSaveButton}
                        variant="contained"
                      >
                        {batchUpdateLoading ? (
                          <CircularProgress size={25} color={"inherit"} />
                        ) : (
                          "Save changes"
                        )}
                      </Button>
                    )}
                    {!add && isWholeCalenderEmpty === true && (
                      <Button
                        sx={{ textTransform: "capitalize" }}
                        variant="contained"
                        onClick={() => SetBatchView("List View")}
                      >
                        Save Changes
                      </Button>
                    )}
                  </Box>
                  {showEditPopup && (
                    <EditPopup
                      type={"batch"}
                      onClose={handleEditPopupCancelButton}
                      onConfirm={handleUpdateBatch}
                      showEditPopup={showEditPopup}
                      notifyText={notifyText}
                      handleNotificationCheckboxChange={
                        handleNotificationCheckboxChange
                      }
                      isCheckedNotification={isCheckedNotification}
                      loader={batchUpdateLoading}
                    />
                  )}
                </form>
              </Box>
            </div>
          )}

          <Modal
            open={openLogoutModal}
            onClose={handleLogoutModalClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              sx={{
                position: "absolute" as "absolute",
                top: "57%",
                left: "58%",
                transform: "translate(-50%, -50%)",
                width: 380,
                bgcolor: "white",
                border: "none",
                boxShadow:
                  "0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)",
                borderRadius: "12px",
                padding: "12px 24px 24px 24px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  marginRight: "-22px",
                  marginTop: "-4px",
                }}
              >
                <Button
                  sx={{ minWidth: "40px" }}
                  onClick={handleLogoutModalClose}
                >
                  <img src={cross} alt="cross" />
                </Button>
              </div>
              <div>
                <div className={styles.cancelFormPopupTitle}>
                  Are you sure you want to Cancel?
                </div>
                <div className={styles.cancelFormPopupContent}>
                  The changes you made will be discarded.
                </div>
                <div className={styles.cancelFormPopupBtnsSplit}>
                  <button
                    className={styles.cancelFormPopupNoBtn}
                    onClick={handleLogoutModalClose}
                  >
                    No
                  </button>
                  <button
                    className={styles.cancelFormPopupYesBtn}
                    onClick={handleCancel}
                  >
                    Yes
                  </button>
                </div>
              </div>
            </Box>
          </Modal>
          <HolidaysModel
            open={openHolidaysModel}
            noButtonClick={cancelHolidays}
            yesButtonClick={saveHolidays}
            setOpenHolidaysModel={setOpenHolidaysModel}
          />
          <RemoveSchedulerModal
            open={selecteRemoveSchedulerType}
            noButtonClick={() => {
              setSelectedRemoveSchedulerType("");
            }}
            setSelectedRemoveSchedulerType={setSelectedRemoveSchedulerType}
            yesButtonClick={handleRemoveScheudler}
            selecteRemoveSchedulerType={selecteRemoveSchedulerType}
          />
        </Box>
      </Container>
    </>
  );
}
