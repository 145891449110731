import React, { useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import "./CountryCodePhoneInput.css";
import { FormHelperText } from "@mui/material";

export default function CountryCodePhoneInput(props: any) {
  const { onChange, value, error, fieldName, disabled, maxLength } = props;

  return (
    <>
      <PhoneInput
        country={"in"}
        enableSearch={true}
        value={value}
        onChange={onChange}
        inputStyle={{
          padding: "9.75px 14px 9.75px 60px",
          width: "100%",
          borderColor: !error ? "#f44336" : "rgba(0, 0, 0, 0.23)",
        }}
        inputClass="custom-input-class"
        // disableDropdown
        // onlyCountries={["in"]}
        countryCodeEditable={false}
        disabled={disabled}
        inputProps={{
          maxLength, // Max length attribute for the input
        }}
      />

      <FormHelperText error={true}>
        {!error ? "Please enter valid number" : ""}
      </FormHelperText>
    </>
  );
}
