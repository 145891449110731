import React, { useState } from "react";
import styles from "./LeadMembership.module.css";
import moment from "moment";
import PaymentRecordPopup from "../../RecordPayment/PaymentRecordPopup";
import PaymentLinkPopup from "../../PaymentLinkPopup/PaymentLinkPopup";
import PaymentLinkSent from "../../PaymentLinkPopup/PaymentLinkSent";
import greenRadioProgress from "../../../../../../assets/images/green-radio-emi.svg";
import normalRadioProgress from "../../../../../../assets/images/grey-circle-emi.svg";
import paidCheck from "../../../../../../assets/images/paid-check.svg";
import AmountEdited from "../Bars/AmountEdited";
import MembershipPaused from "../Bars/MembershipPaused";
import PlanChanged from "../Bars/PlanChanged";
import InstallmentEdited from "../Bars/InstallmentEdited";
import buttonArrow from "../../../../../../assets/images/break-arrow.svg";

function MembershipDetails(props: any) {
  const {
		membershipData,
		leadData,
		finalBatchPrice,
		totalPaidAmount,
		discountMetric,
		discount,
		RecordPayment,
		EditRecordPayment,
		SendPaymentLink,
		isFirstPaymentRequestedOrFailed,
		lastTransaction,
		filteredPaymentRequests,
		remainingEmiAmount,
		activityData,
		setPauseOpen,
		hasLeadAccess,
		selectedCurrency,
	} = props;

	const emiPaymentRequest = filteredPaymentRequests?.[0];

	const paidItems = membershipData.filter(
		(item: any) =>
			item.paymentStatus === 'PAID' || item.paymentStatus === 'PASSED_ON'
	);

	const lastNonPaidItem = [...membershipData]
		.reverse()
		.find(
			(item: any) =>
				item.paymentStatus !== 'PAID' && item.paymentStatus !== 'PASSED_ON'
		);

	const filteredMembershipData = lastNonPaidItem
		? [lastNonPaidItem, ...paidItems]
		: [...paidItems];

	const upcomingInstallment = filteredMembershipData?.find(
		(item: any) =>
			item.paymentStatus === 'OVERDUE' || item.paymentStatus === 'UPCOMING'
	);

	const [emiId, setEmiId] = useState<string>('');

	const [visibleBreakdownId, setVisibleBreakdownId] = useState<string | null>(
		null
	);

	const handleToggleBreakdown = (id: string) => {
		setVisibleBreakdownId(visibleBreakdownId === id ? null : id);
	};

	React.useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search);
		const leadEmiId = urlParams.get('leadEmiId');
		if (leadEmiId) {
			setEmiId(leadEmiId);
		}
		const timeoutId = setTimeout(() => {
			setEmiId('');
			urlParams.delete('leadEmiId');

			const newUrl = `${window.location.pathname}?${urlParams.toString()}`;
			window.history.pushState({}, '', newUrl);
		}, 10000);

		return () => {
			clearTimeout(timeoutId);
		};
	}, []);

	return (
		<div>
			{filteredMembershipData?.map((item: any, index: number) => {
				const prevPaymentActivityData =
					index < filteredMembershipData.length - 1
						? filteredMembershipData[index + 1]?.paymentActivityData
						: null;

				return (
					<div className={styles.recurringDetails} key={item.id}>
						<div className={styles.membershipDetails}>
							<div
								style={{
									position: 'relative',
									width: '22px',
									display: 'flex',
									justifyContent: 'center',
									overflow: 'hidden',
								}}>
								{filteredMembershipData?.length - 1 !== index && (
									<div
										style={{
											height: '100%',
											width: '1px',
											margin: '0px auto',
											border:
												filteredMembershipData?.[index + 1]?.paymentStatus ===
													'PAID' ||
												filteredMembershipData?.[index + 1]?.paymentStatus ===
													'PASSED_ON' ||
												(filteredMembershipData?.[index + 1]?.paymentStatus ===
													'OVERDUE' &&
													filteredMembershipData?.[index + 1]?.isPastDated ===
														true)
													? '1px solid #11c89b'
													: '1px solid rgba(102, 112, 133, 0.14)',
										}}></div>
								)}
								<div
									style={{
										width: '21px',
										height: '21px',
										position: 'absolute',
										background: '#fff',
										borderRadius: '50%',
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
										overflow: 'hidden',
									}}>
									<img
										src={
											item?.paymentStatus === 'PAID' ||
											item?.paymentStatus === 'PASSED_ON' ||
											(item?.paymentStatus === 'OVERDUE' &&
												item?.isPastDated === true)
												? greenRadioProgress
												: normalRadioProgress
										}
										alt='greenRadioProgress'
									/>
								</div>
							</div>
							<div style={{ flex: 1 }}>
								<div
									className={styles.membershipDetailsChild}
									style={{
										background: emiId === item?.id ? '#e8faf6' : '#fff',
									}}>
									<div className={styles.membershipDetailsChildTop}>
										<div className={styles.membershipDetailsChildLeft}>
											<div className={styles.membershipDetailsChildLeftTop}>
												<div
													className={styles.recurringRowDetails}
													style={{
														color: '#142C44',
														fontWeight: '600',
														fontFamily: 'Roboto',
														fontSize: '14px',
													}}>
													{/*   {item?.emiAmount?.toLocaleString("en-IN", {
                            style: "currency",
                            currency: "INR",
                          })} */}
													{selectedCurrency + ' ' + item?.emiAmount}
												</div>
												<div
													style={{
														display: 'flex',
														padding: '2px 8px',
														justifyContent: 'center',
														alignItems: 'center',
														borderRadius: '16px',
														background:
															item.paymentStatus === 'PAID'
																? '#ECFDF3'
																: item.paymentStatus === 'OVERDUE'
																? '#FEF3F2'
																: item.paymentStatus === 'UPCOMING'
																? '#FFFAEB'
																: item?.paymentStatus === 'PASSED_ON'
																? 'rgba(102, 112, 133, 0.08)'
																: '#FFFAEB',
													}}>
													<div
														style={{
															color:
																item.paymentStatus === 'PAID'
																	? '#3DB985'
																	: item.paymentStatus === 'OVERDUE'
																	? '#E7453A'
																	: item.paymentStatus === 'UPCOMING'
																	? '#EBA418'
																	: item?.paymentStatus === 'PASSED_ON'
																	? '#667085'
																	: '#EBA418',
															textAlign: 'center',
															fontFamily: 'Poppins',
															fontSize: '12px',
															fontWeight: '500',
															lineHeight: '18px',
															textTransform: 'capitalize',
														}}>
														{item.paymentStatus
															?.toLowerCase()
															?.replace(/_/g, ' ')}
													</div>
												</div>
												{item?.isPastDated === true && (
													<div
														style={{
															color: '#E7453A',
															fontSize: '12px',
															fontWeight: '500',
															background: '#FEF3F2',
															borderRadius: '16px',
															padding: '2px 8px',
														}}>
														Past Dated
													</div>
												)}
												{item?.emiPaymentStatus === 'PARTIALLY_PAID' && (
													<div
														style={{
															color: '#0570DB',
															fontSize: '12px',
															fontWeight: '500',
															background: 'rgba(5, 112, 219, 0.08)',
															borderRadius: '16px',
															padding: '2px 8px',
														}}>
														Partly Paid
													</div>
												)}
											</div>
											{item?.emiPaymentStatus === 'PARTIALLY_PAID' && (
												<div className={styles.balanceTag}>Balance</div>
											)}
											<div className={styles.membershipDueDate}>
												<>
													Due on:{moment(item?.dueDate).format(' DD/MM/YYYY')}
												</>
											</div>
											{item?.paymentData?.length > 0 && (
												<div>
													<button
														className={styles.viewBreakDownBtn}
														onClick={() => handleToggleBreakdown(item.id)}>
														<img
															src={buttonArrow}
															alt='buttonArrow'
															style={{
																marginRight: '6PX',
																transform:
																	visibleBreakdownId === item.id
																		? 'rotate(360deg)'
																		: 'rotate(180deg)',
																transition: '0.1s ease-in-out',
															}}
														/>
														View Breakdown
													</button>
												</div>
											)}
										</div>

										{(item?.paymentStatus === 'OVERDUE' ||
											item.paymentStatus === 'UPCOMING') &&
										(leadData?.hasOwnProperty('pausedMembership')
											? leadData.pausedMembership === false
											: true) ? (
											<>
												<div
													style={{
														minWidth: '380px',
														display: 'flex',
														justifyContent: 'flex-end',
														gap: '14px',
													}}>
													{item?.id === upcomingInstallment?.id &&
														hasLeadAccess && (
															<>
																<PaymentRecordPopup
																	balanceAmountToBePaid={remainingEmiAmount}
																	basePrice={finalBatchPrice}
																	discountMetric={discountMetric}
																	discount={discount}
																	RecordPayment={RecordPayment}
																	amountToBePaid={item.emiAmount}
																	leadData={leadData}
																	emiId={item.id}
																	paymentType={'MEMBERSHIP'}
																	finalBatchPrice={finalBatchPrice}
																	totalPaidAmount={totalPaidAmount}
																	autoDebitRaised={item?.autoDebitRaised}
																	remainingEmiAmount={remainingEmiAmount}
																	selectedCurrency={selectedCurrency}
																/>
																<>
																	{emiPaymentRequest?.emiId !== item.id ||
																	lastTransaction?.paymentStatus ===
																		'CANCELLED' ? (
																		<PaymentLinkPopup
																			finalBatchPrice={finalBatchPrice}
																			totalPaidAmount={totalPaidAmount}
																			isOneTime={false}
																			amountToBePaid={parseFloat(
																				item.emiAmount
																			)}
																			isTable={true}
																			leadData={leadData}
																			emiId={item.id}
																			paymentType={'MEMBERSHIP'}
																			SendPaymentLink={SendPaymentLink}
																			autoDebitRaised={item?.autoDebitRaised}
																			remainingEmiAmount={remainingEmiAmount}
																			selectedCurrency={selectedCurrency}
																		/>
																	) : (
																		<PaymentLinkSent
																			filteredPaymentRequests={
																				filteredPaymentRequests
																			}
																			EditRecordPayment={EditRecordPayment}
																			SendPaymentLink={SendPaymentLink}
																			finalBatchPrice={finalBatchPrice}
																			totalPaidAmount={totalPaidAmount}
																			isOneTime={false}
																			amountToBePaid={parseFloat(
																				item.emiAmount
																			)}
																			leadData={leadData}
																			emiId={item.id}
																			paymentType={'MEMBERSHIP'}
																			isFirstPaymentRequestedOrFailed={
																				isFirstPaymentRequestedOrFailed
																			}
																			autoDebitRaised={item?.autoDebitRaised}
																		/>
																	)}
																</>
															</>
														)}
												</div>
											</>
										) : (
											<div
												style={{
													minWidth: '380px',
													display: 'flex',
													justifyContent: 'flex-end',
												}}>
												{item?.paymentStatus === 'PAID' && (
													<div
														style={{
															display: 'flex',
															alignItems: 'center',
															gap: '12px',
														}}>
														<div
															style={{
																background: ' rgba(17, 200, 155, 0.1)',
																borderRadius: '4px',
																padding: '4px 10PX',
																color: '#3DB985',
																fontSize: '12PX',
																fontWeight: '500',
															}}>
															Paid on:
															{moment(item.paymentDate).format(' DD/MM/YYYY')}
														</div>
														<img src={paidCheck} alt='paidCheck' />
													</div>
												)}
											</div>
										)}
									</div>

									{item?.paymentData?.length > 0 && (
										<div
											className={styles.emiSubaymentsWrapper}
											style={{
												display:
													visibleBreakdownId === item.id ? 'block' : 'none',
												opacity: visibleBreakdownId === item.id ? '1' : '0',
												transition: '0.3s ease-in-out',
											}}>
											{item?.paymentData?.map(
												(paymentItem: any, index: number) => (
													<div className={styles.emiSubaymentsWrap} key={index}>
														<div
															style={{
																position: 'relative',
																width: '13px',
																display: 'flex',
																justifyContent: 'center',
																overflow: 'hidden',
															}}>
															{item?.paymentData?.length - 1 !== index && (
																<div
																	style={{
																		height: '100%',
																		width: '1px',
																		margin: '0px auto',
																		border:
																			'1px solid rgba(102, 112, 133, 0.14)',
																	}}></div>
															)}
															<div
																style={{
																	width: '13px',
																	height: '21px',
																	borderBottom:
																		' 2px solid rgba(102, 112, 133, 0.14)',
																	borderLeft:
																		item?.paymentData?.length - 1 === index
																			? '2px solid rgba(102, 112, 133, 0.14)'
																			: 'none',

																	position: 'absolute',
																	right: '-5px',
																}}></div>
														</div>
														<div className={styles.emiSubaymentsRow}>
															<div className={styles.emiSubaymentsRowItem}>
																<div className={styles.emiSubaymentsLabel}>
																	Amount:
																</div>
																<div
																	className={styles.emiSubaymentsValue}
																	style={{ fontFamily: 'Roboto' }}>
																	{/* 			{paymentItem?.amountReceived?.toLocaleString(
																		'en-IN',
																		{
																			style: 'currency',
																			currency: 'INR',
																		}
																	)} */}
																	{selectedCurrency +
																		' ' +
																		paymentItem?.amountReceived}
																</div>
															</div>
															<div className={styles.emiSubaymentsRowItem}>
																<div className={styles.emiSubaymentsLabel}>
																	{paymentItem?.paymentStatus === 'PAID'
																		? 'Paid on:'
																		: 'Passed on:'}
																</div>
																<div className={styles.emiSubaymentsValue}>
																	{moment(paymentItem?.paymentDate).format(
																		'DD/MM/YYYY'
																	)}
																</div>
															</div>
															<div className={styles.emiSubaymentsRowItem}>
																<div className={styles.emiSubaymentsLabel}>
																	{paymentItem?.paymentStatus === 'PASSED_ON'
																		? '(Passed on to next EMI)'
																		: ''}
																</div>
															</div>
														</div>
													</div>
												)
											)}
										</div>
									)}
								</div>

								{prevPaymentActivityData?.map((activity: any, i: number) => (
									<div key={i}>
										{activity.activityType === 'INSTALLMENT_EDITED' && (
											<InstallmentEdited activity={activity} />
										)}
										{activity.activityType === 'AMOUNT_EDITED' && (
											<AmountEdited activity={activity} />
										)}
										{activity.activityType === 'MEMBERSHIP_PAUSED' && (
											<MembershipPaused
												activity={activity}
												setPauseOpen={setPauseOpen}
											/>
										)}
										{activity.activityType === 'PLAN_CHANGED' && (
											<PlanChanged activity={activity} />
										)}
									</div>
								))}
							</div>
						</div>
					</div>
				);
			})}
		</div>
	);
}

export default MembershipDetails;
