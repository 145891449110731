import React, { useState } from "react";
import { TextField, InputAdornment, Dialog } from "@mui/material";
import search from "../Assets/searchIcon.svg";
import styles from "./CourseSearch.module.css";
import searchClose from "../Assets/closeIcon.svg";
import { useSelector } from "react-redux";

function CourseSearchPopup(props: any) {
  const {
    courseList,
    selectedCourse,
    handleSelectedCourse,
    setSelectedCourse,
  } = props;
  const { onLeadsLoading } = useSelector((state: any) => state.nurture);

  const [isSearchModalOpen, setIsSearchModalOpen] = useState<boolean>(false);
  const handleOpenSearchModal = () => setIsSearchModalOpen(true);
  const handleCloseSearchModal = () => setIsSearchModalOpen(false);

  const [selectedCoursePopup, setSelectedCoursePopup] = React.useState(null);

  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = (event: any) => {
    setSearchTerm(event.target.value);
  };

  const filteredCourses =
    courseList &&
    courseList
      ?.map((course: any) => ({
        ...course,
        courseDetails: course.courseDetails.filter((courseDetail: any) =>
          courseDetail.courseName
            .toLowerCase()
            .includes(searchTerm.toLowerCase())
        ),
      }))
      ?.filter((course: any) => course.courseDetails.length > 0);

  const handleCourseSelectPopup = React.useCallback(
    (event: any, course: any) => {
      setSelectedCourse(course);
      setSelectedCoursePopup(course);
      handleSelectedCourse(course);
      handleCloseSearchModal();
      const queryParams = new URLSearchParams(window.location.search);
      if (course) {
        queryParams.set("selectedCourse", course.courseId);
        const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
        window.history.pushState({}, "", newUrl);
      }
    },
    [
      setSelectedCoursePopup,
      setSelectedCourse,
      handleSelectedCourse,
      setIsSearchModalOpen,
    ]
  );

  React.useEffect(() => {
    setSelectedCoursePopup(selectedCourse);
  }, [setSelectedCoursePopup]);

  return (
    <div>
      <TextField
        placeholder="Search Courses"
        style={{
          padding: "0px",
          borderRadius: "4px",
          border: "none",
          outline: "none",
        }}
        className={styles.searchPopupField}
        maxRows={4}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <img src={search} alt="" />
            </InputAdornment>
          ),
          readOnly: true,
        }}
        inputProps={{
          sx: {
            "&::placeholder": {
              fontWeight: "400",
              fontSize: "14px",
            },

            "& .MuiOutlinedInput-input": {
              padding: "10.5px 14px",
            },
          },
        }}
        fullWidth
        onClick={() => handleOpenSearchModal()}
        size="small"
      />
      <Dialog
        open={isSearchModalOpen}
        onClose={handleCloseSearchModal}
        sx={{
          zIndex: 99999,
          "& .MuiDialog-paper": {
            width: "100%",
            maxWidth: "900px",
            maxHeight: "586px",
            minHeight: "500px",
          },
        }}
      >
        <div className={styles.searchPopupWrap}>
          <div className={styles.searchPopupBtnWrap}>
            <button
              onClick={() => handleCloseSearchModal()}
              className={styles.searchPopupBtn}
            >
              <img src={searchClose} alt="" />
            </button>
          </div>
          <TextField
            type="search"
            value={searchTerm}
            onChange={handleSearch}
            placeholder="Search Courses"
            style={{
              padding: "0px",
              borderRadius: "4px",
              border: "none",
              outline: "none",
            }}
            className={styles.searchPopupField}
            size="small"
            inputProps={{
              sx: {
                width: "400px",
                "&::placeholder": {
                  fontWeight: "400",
                  fontSize: "14px",
                },

                "& .MuiOutlinedInput-input": {
                  padding: "10.5px 14px",
                },
              },
            }}
          />
          <div className={styles.searchPopupWrapChild}>
            {courseList &&
              filteredCourses?.map((category: any) => (
                <div
                  key={category.id}
                  className={styles.searchPopupCategoryWrap}
                >
                  <h2 className={styles.searchPopupCategoryName}>
                    {category?.courseCategoryName}
                  </h2>
                  {category?.courseDetails?.map((course: any) => (
                    <button
                      key={course.courseId}
                      className={
                        selectedCourse &&
                        selectedCourse.courseId === course.courseId
                          ? styles.searchPopupCourseNameSelected
                          : styles.searchPopupCourseName
                      }
                      onClick={(event) =>
                        handleCourseSelectPopup(event, course)
                      }
                      disabled={onLeadsLoading}
                      style={{ cursor: onLeadsLoading ? "auto" : "pointer" }}
                    >
                      {course.courseName}
                    </button>
                  ))}
                </div>
              ))}
            {filteredCourses && filteredCourses?.length === 0 && (
              <div
                style={{
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "20PX",
                  color: "#667085",
                  minHeight: "300px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                  width: "100%",
                }}
              >
                No results found. Try another search
              </div>
            )}
          </div>
        </div>
      </Dialog>
    </div>
  );
}

export default CourseSearchPopup;
