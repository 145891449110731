import { Route, Routes } from "react-router-dom";
import React from "react";
import {
  AddCustomRole,
  Notifications,
  RolesAndPermission,
  TaxInvoices,
  CustomRoleDetail,
  SystemRoleDetail,
  StaffEditor,
  StudentSettings,
} from "../pages";
import { checkSubDomainAccess } from "../components/RolePermissionAccess/RolePermissionAccess";
import { useSelector } from "react-redux";
import NoAccess from "../components/NoAccess/NoAccess";

export default function ManageSettingsRouter(props: any) {
  const { userRoleById, userById } = useSelector(
    (state: any) => state.userDetails
  );
  const rolesAccess = checkSubDomainAccess(
    "SETTINGS",
    "ROLES_&_PERMISSIONS",
    // userRoleById?.permissions
    userById?.rolePermission
  );
  const taxInvoiceAccess = checkSubDomainAccess(
    "SETTINGS",
    "INVOICES",
    // userRoleById?.permissions
    userById?.rolePermission
  );
  const notificationAccess = checkSubDomainAccess(
    "SETTINGS",
    "NOTIFICATIONS",
    // userRoleById?.permissions
    userById?.rolePermission
  );
  const teamAccess = checkSubDomainAccess(
    "MANAGE",
    "TEAMS",
    // userRoleById?.permissions
    userById?.rolePermission
  );
  const studentIdAccess = checkSubDomainAccess(
    "SETTINGS",
    "STUDENT_ID",
    // userRoleById?.permissions
    userById?.rolePermission
  );
  return (
    <Routes>
      <Route
        path={"/"}
        element={taxInvoiceAccess ? <TaxInvoices /> : <NoAccess />}
      />
      <Route
        path={"/Invoices"}
        element={taxInvoiceAccess ? <TaxInvoices /> : <NoAccess />}
      />
      <Route
        path={"/Notifications"}
        element={notificationAccess ? <Notifications /> : <NoAccess />}
      />
      <Route
        path={"/Rolesandpermission"}
        element={rolesAccess ? <RolesAndPermission /> : <NoAccess />}
      />
      <Route
        path={"/Rolesandpermission/SystemRoleDetails/:id"}
        element={rolesAccess ? <SystemRoleDetail /> : <NoAccess />}
      />
      <Route
        path={"/Rolesandpermission/CustomRoleDetails/:id"}
        element={rolesAccess ? <CustomRoleDetail /> : <NoAccess />}
      />
      <Route
        path={"/Rolesandpermission/AddCustomRole"}
        element={rolesAccess ? <AddCustomRole /> : <NoAccess />}
      />
      <Route
        path={"/Rolesandpermission/AddCustomRole"}
        element={rolesAccess ? <AddCustomRole /> : <NoAccess />}
      />
      <Route
        path={"/Rolesandpermission/Create-Team"}
        element={
          teamAccess ? <StaffEditor add={true} backBtn={true} /> : <NoAccess />
        }
      />
      <Route
        path={"/Student"}
        element={studentIdAccess ? <StudentSettings /> : <NoAccess />}
      />
    </Routes>
  );
}
