import React, { useState } from "react";
import closeIcon from "../../../../../assets/images/close-icon.svg";
import styles from "../../ManageBatch/components/EditPopup/EditPopup.module.css";
import CancelBtn from "../../../../../components/Buttons/CancelBtn";
import SaveBtn from "../../../../../components/Buttons/SaveBtn";
import NewFormTextInput from "../../../../../components/FormTextInput/NewFormTextInput";
import { useSelector } from "react-redux";
import { useAuthUser } from "react-auth-kit";
import { useDispatch } from "react-redux";
import { ErrorType } from "../../../../../components/FormTextInput/types";
import { CreateCourseDTO } from "../service/types";
import { createCourse, resetCourseStatus } from "../service/courseSlice";
import CourseLevelManager from "./Manager/CourseLevelManager";
import { useNavigate } from "react-router-dom";

function AddCoursePopup(props: any) {
  const { isOpen, onClose } = props;
  const auth = useAuthUser();
  const authUser = auth();
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const { createCourseSuccess, loading_createCourse, courseId } = useSelector(
    (state: any) => state.courses
  );

  const instituteData = JSON.parse(localStorage.getItem("ACTIVE_INSTITUTE")!);

  const { leadBoard } = useSelector((state: any) => state.nurture);
  const courseLevelFromStore = useSelector(
    (state: any) => state.courseConfig.courseConfigs.courseLevel
  );
  const courseLevel = instituteData?.courseCreated
    ? leadBoard?.courseLevel
    : courseLevelFromStore;
  console.log(
    "courseLevel",
    courseLevel,
    instituteData?.courseCreated,
    courseLevelFromStore,
    leadBoard?.courseLevel
  );
  const [courseDetails, setCourseDetails] = React.useState<CreateCourseDTO>({
    courseName: "",
    courseDescription: "",
    courseTags: [],
    instituteId: "",
    instituteName: "",
    coursePrice: "",
    courseCategory: {
      courseCategoryName: "",
      courseCategoryId: "",
    },
    courseType: [],
    courseLevel: {
      courseLevelName: "",
      id: "",
    },
    courseSyllabus: [],
    isFirstCourse: false,
    status: "ACTIVE",
    isCertificateProvided: false,
    courseImage: "",
    createdById: authUser?.institute?.[0]?.id,
    createdByName: authUser?.firstName + " " + authUser?.lastName,
    updatedById: "",
    updatedByName: "",
    paymentType: "",
  });
  const [errorMsgLevel, setErrorMsgLevel] = useState<string>("");
  const [validStateCourseName, setValidStateCourseName] =
    React.useState<boolean>(true);
  const [showErrMsz, setShowErrMsz] = React.useState<boolean>(true);
  const [errorType, setErrorType] = React.useState<ErrorType>("client");

  const cancelButtonClick = () => {
    setCourseDetails({
      ...courseDetails,
      courseName: "",
      courseLevel: {
        courseLevelName: "",
        id: "",
      },
    });
    setValidStateCourseName(true);
    setShowErrMsz(true);
    setErrorMsgLevel("");
    onClose();
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    try {
      !courseDetails.courseName && setValidStateCourseName(false);
      courseDetails.courseLevel.id === ""
        ? setErrorMsgLevel("select atleast one")
        : setErrorMsgLevel("");

      const userFromLocalStorage = authUser?.institute[0]?.instituteDetails;
      const newArray: any = courseDetails;
      newArray.instituteId =
        userFromLocalStorage.id && userFromLocalStorage?.id;
      newArray.instituteName =
        userFromLocalStorage.id && userFromLocalStorage?.instituteName;

      validStateCourseName &&
        courseDetails.courseName !== "" &&
        courseDetails.courseLevel.id !== "" &&
        dispatch<any>(createCourse(newArray));
    } catch (error) {}
  };

  const handleLevelChange = React.useCallback(
    (courseLevelName: any, courseId: string) => {
      setErrorMsgLevel("");
      setCourseDetails({
        ...courseDetails,
        courseLevel: {
          ...courseDetails.courseLevel,
          id: courseId,
          courseLevelName: courseLevelName,
        },
      });
    },
    [courseDetails, setCourseDetails]
  );

  const setValidateStatusCourseName = React.useCallback((val: boolean) => {
    setValidStateCourseName(val);
  }, []);

  const handleChange = React.useCallback(
    (event: any) => {
      setShowErrMsz(true);
      setErrorType("client");
      setCourseDetails({
        ...courseDetails,
        [event.target.id]: event.target.value.trimStart(),
      });
    },
    [courseDetails]
  );

  React.useEffect(() => {
    if (
      createCourseSuccess !== true &&
      createCourseSuccess !== false &&
      courseId
    ) {
      if (courseId) {
        navigation(`/app/manage/Courses/details/${courseId}`);
      } else {
        navigation(`/app/manage/Courses`);
      }
      onClose();
      dispatch(resetCourseStatus());
    }
  }, [createCourseSuccess, courseId]);

  return (
    <>
      <div
        className={styles.addBatchWrapper}
        style={{
          display: isOpen ? "flex" : "none",
          transition: "0.1s ease-in-out",
        }}
      >
        <div
          className={styles.addBatchWrapperChild}
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            className={styles.popupHead}
            style={{ padding: "0px", marginBottom: "14PX" }}
          >
            <div className={styles.popupHeadTitle}>Add New Course</div>
            <button
              className={styles.popupHeadClsBtn}
              onClick={cancelButtonClick}
            >
              <img src={closeIcon} alt="closeIcon" />
            </button>
          </div>
          <div>
            <div style={{ marginBottom: "18PX" }}>
              <NewFormTextInput
                value={courseDetails.courseName}
                validate={true}
                setValidStatus={setValidateStatusCourseName}
                error={!validStateCourseName}
                showErrorMessage={showErrMsz}
                errorType={errorType}
                id="courseName"
                label="Course Name"
                name="Course"
                variant="standard"
                fullWidth
                onChange={handleChange}
                inputProps={{
                  maxLength: 100,
                }}
                require={true}
              />
            </div>

            <div className={styles.courseLevelSection}>
              {courseLevel && (
                <CourseLevelManager
                  handleLevelChange={handleLevelChange}
                  courseLevel={courseLevel}
                  errorMsg={errorMsgLevel}
                  selectedLevel={courseDetails.courseLevel}
                  isFromCourseSection={true}
                />
              )}
            </div>
          </div>

          <div className={styles.popupBtnSplit}>
            <CancelBtn onClick={() => cancelButtonClick()} />
            <SaveBtn
              onClick={handleSubmit}
              disabled={loading_createCourse}
              loader={loading_createCourse}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default AddCoursePopup;
